import { faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext, useState } from "react"
import { Button, FormGroup, FormLabel, FormSelect } from "react-bootstrap"
import { generalMessage } from "../../dataTypes/notificationMessages.types"
import { UserData } from "../../dataTypes/user.types"
import { userContext } from "../../providers/user.provider"
import CustomSpinner from "../utility/CustomSpinner.component"
import { updateUserData } from "./methods"
import { StatesAndLGAs, STATES_AND_LOCAL_GOVERNMENTS } from "../../constants/STATES.LOCALGOVERNMENT.constant"
import { updateFormData } from "../../screens/methods/registration"
import { StaffsAndTrustees } from "../../constants/AdminDefinitions.constant"

export const ContactForm = ({user}:{user: UserData})=>{
    const {currentUser} = useContext(userContext)
    const canUpdate: boolean = (StaffsAndTrustees.includes(currentUser?.adminRole!))
    const [formData, setFormData] = useState<UserData>(user)
    const [validationError, setValidationError] = useState<generalMessage | null>(null)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    const defaultState = STATES_AND_LOCAL_GOVERNMENTS.find((stateLGA)=>stateLGA.state===formData.contactAddress.state)
    const [lgasOfResidence, setLgasOfResidence] = useState<string[]|null>(null)
    const lgas = lgasOfResidence ?? defaultState?.lgas
    
    return (
        <div>
                {
                    validationError &&
                    <div className="mb-2">
                        <small className={validationError.type}>{validationError.message}</small>
                    </div>
                }
            <div>
                    <div className="row">
                        <FormGroup className="mb-4">
                            <FormLabel>
                                Street:
                            </FormLabel>
                            <input 
                            placeholder="e.g 3, Okikiola street, Aboru"
                            className="form-control"
                            onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }} value={formData?.contactAddress?.street!} name="constreet" />
                            
                        </FormGroup>
                    </div>

                    <div className="row">
                        <FormGroup className="col-lg-4 mb-4">
                            <FormLabel>
                                Nearest bus stop:
                            </FormLabel>
                            <input onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }} 
                            placeholder="Enter closest bus stop"
                            className="form-control"
                            value={formData?.contactAddress?.nearestBusStop!} name="connearestBusStop" />
                            
                        </FormGroup>

                        <FormGroup className="col-lg-4 col-md-6 mb-4">
                            <FormLabel>
                                State:
                            </FormLabel>
                            <FormSelect onChange={(e)=>{
                                    const states = JSON.parse(e.target.value) as StatesAndLGAs
                                    setLgasOfResidence(states.lgas)
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }} defaultValue={JSON.stringify(defaultState)} name="stateOfResidence">
                                <option value="default">Select State of residence</option>
                                {
                                     STATES_AND_LOCAL_GOVERNMENTS.map((stateLGA, index)=>{
                                        return <option key={index} value={JSON.stringify(stateLGA)}>{stateLGA.state}</option>
                                    })
                                }
                            </FormSelect>
                        </FormGroup>  
                        {
                            formData?.contactAddress?.state && 
                            <FormGroup className="col-lg-4 col-md-6 mb-4">
                                <FormLabel>
                                    LGA:
                                </FormLabel>
                                <FormSelect onChange={(e)=>{
                                        updateFormData(e, setFormData, setValidationError, formData)
                                    }} defaultValue={formData?.contactAddress?.localGovernmentArea!} name="lgaOfResidence">
                                    <option value="default">Select LGA of residence</option>
                                    {
                                        lgas?.map((lga, index)=>{
                                            return <option key={index} value={lga}>{lga}</option>
                                        })
                                    }
                                </FormSelect>
                            </FormGroup> 
                        }               
                    </div>
                </div>

            <div className="mt-3">
                {pageIsBusy && <CustomSpinner />}
                <div className="d-flex justify-content-center mt-1">
                    <Button onClick={
                        ()=>updateUserData({setPageIsBusy, formData})
                    } disabled={pageIsBusy || !canUpdate} className="btn-sm" variant="success">
                        <FontAwesomeIcon icon={faSave} className="me-2" /> Save
                    </Button>
                </div>
            </div> 
        </div>
    )
}