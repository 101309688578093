import { ReactNode } from "react"
import { Modal } from "react-bootstrap"

export const FormModal = ({show, onHide, form, title}:{show:boolean, onHide:()=>void, form: ReactNode, title: string})=>{
    return (
            <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
            >
            <Modal.Header closeButton>
                {`Update ${title}`}
            </Modal.Header>
            <Modal.Body>
                {form}
            </Modal.Body>
            
            </Modal>
               
    )
}