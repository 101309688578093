import { Fragment } from "react"
import { NextOfKin } from "../../dataTypes/user.types"

export const NextOfKinDisplay = ({nextOfKin}: {nextOfKin:NextOfKin})=>{
    return (
        <Fragment>
            <div>
                <small className="text-grey">Name:</small>
                <div>{nextOfKin.firstName+' '+nextOfKin.middleName+' '+nextOfKin.lastName}</div>
            </div>
            <div className="mt-3" >
                <small className="text-grey">Phone number:</small>
                <div>{nextOfKin.phoneNumber}</div>
            </div>
            <div className="mt-3" >
                <small className="text-grey"> Email address:</small>
                <div>{nextOfKin.email}</div>
            </div>
            <div className="mt-3" >
                <small className="text-grey"> Relationship:</small>
                <div>{nextOfKin.relationship}</div>
            </div>
        </Fragment>
    )
}