import { faDownload, faFileInvoice, faFileInvoiceDollar, faFilterCircleDollar, faHandHoldingDollar, faMoneyBill, faReceipt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback, useContext, useEffect, useState } from "react"
import { Button, ButtonGroup, Card, FormControl, FormLabel } from "react-bootstrap"
import BreadCrumbComponent from "../components/main/body/pageTitle/BreadCrumb.component"
import { CustomTable, balanceColumns, deductionColumns, financialcolumns, loanScheduleColumns, voucherColumns } from "../components/utility/CustomTable.component"
import { AccountBalance, DeductionColumnData, FinanceData, FinancialColumnData, Loan, LoanConfiguration, LoanScheduleColumnData, VoucherData } from "../dataTypes/financials"
// import { generalMessage } from "../dataTypes/notificationMessages.types"
import { ADMIN_ROLE, INFLOW_PURPOSE, LOANSTATUS, MEMBERSHIP_STATUS, MEMBER_BALANCES, OUTFLOW_PURPOSE, VOUCHER_STATUS } from "../constants/UTILITY.constants"
import { LoanConfigurations } from "../models/LoanConfigurations.model"
import { toast } from "react-toastify"
import { Receipts } from "../models/Receipts.model"
import { Payments } from "../models/Payments.model"
import { Dividends } from "../models/Dividends.model"
import { Shares } from "../models/Shares.model"
import { Savings } from "../models/Savings.model"
import { Loans } from "../models/Loans.model"
import { Creditors } from "../models/Creditors.model"
import { Debtors } from "../models/Debtors.model"
import { Model } from "../dataTypes/model.interface"
import { WHEREOPERATOR } from "../dataTypes/firebasequery.types"
import { Users } from "../models/Users"
import { UserData } from "../dataTypes/user.types"
import { VoucherModal } from "../components/finance/VoucherModal.component"
import { Vouchers } from "../models/Vouchers.model"
import { PendingVoucherModal } from "../components/finance/PendingVoucherModal.component"
import { OtherIncomeModal } from "../components/finance/OtherIncomeModal.component"
import exportFromJSON from 'export-from-json'
import { userContext } from "../providers/user.provider"
import { CoreExecutives, StaffFinSec } from "../constants/AdminDefinitions.constant"
import { monitorPasswordResetter } from "./methods/registration"
import { useNavigate } from "react-router-dom"
import { TableColumn } from "react-data-table-component"
import { moneyFormatter } from "../utility/helpers"

const balanceModels: Map<string, Model> = new Map()
balanceModels.set(MEMBER_BALANCES[MEMBER_BALANCES.DIVIDEND], new Dividends())
balanceModels.set(MEMBER_BALANCES[MEMBER_BALANCES.CREDITORS], new Creditors())
balanceModels.set(MEMBER_BALANCES[MEMBER_BALANCES.DEBTORS], new Debtors())
balanceModels.set(MEMBER_BALANCES[MEMBER_BALANCES.LOANS], new Loans())
balanceModels.set(MEMBER_BALANCES[MEMBER_BALANCES.SAVINGS], new Savings())
balanceModels.set(MEMBER_BALANCES[MEMBER_BALANCES.SHARES], new Shares())

const tabs = [
    {
        label: "Income",
        icon: faReceipt,
        model: new Receipts()
    },
    {
        label: "Expenses",
        icon: faFileInvoice,
        model: new Payments()
    },
    {
        label: "Balances",
        icon: faFileInvoiceDollar,
        model: balanceModels
    },
    {
        label: "Deductions",
        icon: faFilterCircleDollar,
        // model: balanceModels
    }
]

// type tabItem = {
//     label: string,
//     icon: IconProp
// }

const Finance = ()=>{
    const d = new Date()
    const navigate = useNavigate()

    const {currentUser} = useContext(userContext)
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(0)
    const [tableData, setTableData] = useState<FinancialColumnData[] | AccountBalance[] | DeductionColumnData[] | LoanScheduleColumnData[]>([])
    // const [message, setMessage] = useState<generalMessage | null>(null)
    const incomeFilter = Object.values(INFLOW_PURPOSE).filter(value=>value.indexOf(' '))
    const expenseFilter = Object.values(OUTFLOW_PURPOSE).filter(value=>value.indexOf(' '))
    const balanceFilter = Object.values(MEMBER_BALANCES).filter(value=>typeof(value)!=='number')
    const [currentFilterList, setCurrentFilterList] = useState<string[]>(incomeFilter)
    const [currentFilter, setCurrentFilter] = useState<string>(incomeFilter[0])
    const [availableLoans, setAvailableLoans] = useState<LoanConfiguration[]>([])
    const [currentLoanCategory, setCurrentLoanCategory] = useState<string | null>(null)
    const noDate = (currentTabIndex===2 && currentFilter==='SHARES') || (currentTabIndex===2 && currentFilter==='DIVIDEND')
                || (currentTabIndex===2 && currentFilter==='SAVINGS') || (currentTabIndex===2 && currentFilter==='CREDITORS')
                || (currentTabIndex===2 && currentFilter==='DEBTORS') || (currentTabIndex===3)
    const [startDate, setStartDate] = useState<number>(new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0).getTime())
    const [endDate, setEndDate] = useState<number>(new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59).getTime())
    const [members, setMembers] = useState<UserData[]>([])
    const [memberLoans, setMemberLoans] = useState<Loan[]>([])
    const [showVoucherModal, setShowVoucherModal] = useState<boolean>(false)
    const [voucherTableData, setVoucherTableData] = useState<VoucherData[]>([])
    const [currentVoucher, setCurrentVoucher] = useState<VoucherData | null>(null)
    const [receiveOtherIncome, setReceiveOtherIncome] = useState<boolean>(false)
    const [loanCategories, setLoanCategories] = useState<string[]>([])
    const [currentDeductionColumn, setCurrentDeductionColumn] = useState<TableColumn<any>[]>(deductionColumns)

    const exportType =  exportFromJSON.types.xls

    const fetchVoucherBalance = useCallback(()=>{
        const voucherModel = new Vouchers()
        voucherModel.streamWhere([
            {
                key: 'status',
                operator: WHEREOPERATOR.NOT_IN,
                value: [VOUCHER_STATUS.PAID, VOUCHER_STATUS.CANCELLED]
            }
        ], (data)=>{
            setVoucherTableData(data as VoucherData[])
        }, (error)=>{
            console.log(error)
        })
    }, [])

    const fetchAvailableLoans = useCallback(()=>{
        const loanConfigModel = new LoanConfigurations()
        loanConfigModel.findAll((loans)=>{
            setAvailableLoans(loans as LoanConfiguration[])
        }, (_)=>{
            toast("Poor internet connection!", {type: 'warning'})
        })
    }, [])

    const fetchLoanCategories = useCallback(()=>{
        if(loanCategories.length <= 0){
            const catModel = new LoanConfigurations()
            catModel.findAll((data)=>{
                const items = data as LoanConfiguration[]
                items.forEach(item=>{
                    const ex = currentDeductionColumn.find((de)=>de.name===item.title)
                    if(ex===undefined){
                        currentDeductionColumn.push({
                            name: item.title,
                            selector: row=>moneyFormatter(row[item.title])
                        })
                    }
                    // check if heading exists
                    const existsing = loanCategories.find((category)=>category===item.title)
                    if(existsing===undefined){
                        loanCategories.push(item.title)
                    }
                })
                setLoanCategories([...loanCategories])
                setCurrentDeductionColumn([...currentDeductionColumn])
            }, ()=>{})
        }
    }, [])
    
    useEffect(()=>{
        // get all available loan categories
        fetchLoanCategories()
        // monitor password resetter
        if(currentUser){
            monitorPasswordResetter(currentUser!, navigate)
        }
        // check unapproved vouchers
        fetchVoucherBalance()

        const tab = currentTabIndex===0?incomeFilter:(currentTabIndex===1?expenseFilter:balanceFilter)
        setCurrentFilterList(tab as string[])

        // get current loan categories
       fetchAvailableLoans()

        // set current loan category
        if(currentTabIndex===2 && currentFilter==="LOANS" && currentLoanCategory===null ){
            setCurrentLoanCategory(availableLoans[0].title)
        }

        // set table data
        if(currentTabIndex===2){
            // balances
            const models = tabs[currentTabIndex].model as Map<string, Model>
            const model = models.get(currentFilter)
            if(currentFilter==="LOANS"){
                // loans
                model?.streamWhere(
                    [
                       
                       {
                           key: 'startDate',
                           operator: WHEREOPERATOR.GREATER_OR_EQUAL_TO,
                           value: startDate
                       },
                       {
                           key: 'startDate',
                           operator: WHEREOPERATOR.LESS_OR_EQUAL_TO,
                           value: endDate
                       },
                       {
                           key: 'category',
                           operator: WHEREOPERATOR.EQUAL_TO,
                           value: currentLoanCategory
                       },
                       {
                            key: 'status',
                            operator: WHEREOPERATOR.IN,
                            value: [LOANSTATUS.ACTIVE, LOANSTATUS.BAD, LOANSTATUS.DOUBTFUL, LOANSTATUS.NON_PERFORMING, LOANSTATUS.COMPLETED]
                       }
                   ],
                   (data) => {
                       // setTableData(data as FinanceData[])
                       const item = data as Loan[]
                       const loanColumns: LoanScheduleColumnData[] = []
                       item.forEach(loanData=>{
                            // compute earned income from repayments in the period
                            const earnedIncome = loanData.repayments ? loanData.repayments.filter(
                                repayment=>(repayment.date <= endDate && repayment.date >= startDate)).reduce(
                                    (prev, curr)=>({...curr, amount: (+curr.amount+prev.amount)})
                                ).amount : 0
                            // compute unearned income in the period
                            const unearnedIncome = (loanData.capital * (1 + (loanData.interestRate/100))) - earnedIncome  
                           loanColumns.push(
                               {
                                collectionDate: new Date(loanData.startDate).toISOString().slice(0, 10),
                                name: loanData.applicantName,
                                capital: loanData.capital,
                                interest: (loanData.capital * (loanData.interestRate/100)),
                                earned: earnedIncome,
                                unearned: unearnedIncome,
                                status: loanData.status,
                                ref: loanData.reference!
                               }
                           )
                       })
                       setTableData(loanColumns)
                   },
                   (error) => {
                       console.log(error)
                   },
               )
            }else{
                // other balances
                model?.stream((data)=>{
                    setTableData(data as AccountBalance[])
                }, (error)=>{
                    console.log(error)
                })
            }
        }else if(currentTabIndex===3){
            // Monthly Deductions
            const loanModel = new Loans()
                loanModel.streamWhere(
                    [
                        {
                            key: 'status',
                            operator: WHEREOPERATOR.NOT_IN,
                            value: [LOANSTATUS.APPROVED, LOANSTATUS.UNGUARANTEED, LOANSTATUS.PARTIALLY_GUARANTEED, LOANSTATUS.GUARANTEED, LOANSTATUS.CANCELLED, LOANSTATUS.COMPLETED]
                       }
                    ],
                    (data)=> {
                        setMemberLoans(data as Loan[])
                    },
                    (error)=> {
                        console.log(error)
                    },
                )
            // savings and Loans of all members
            // (active, bad, non-perfoming loans)
            const userModel = new Users()
            // fetch all active users
            userModel.streamWhere([
                {
                    key: 'status',
                    operator: WHEREOPERATOR.NOT_EQUAL_TO,
                    value: MEMBERSHIP_STATUS.WITHDRAWN
                },
            ], (data)=>{
                setMembers(data as UserData[])
            }, (error)=>{
                console.log(error)
            })

            if(members.length > 0){
                const loans = memberLoans as Loan[]
                // fetch all active loans
                const deductions: DeductionColumnData[] = []
                members.forEach(member=>{
                    if(member.adminRole!==ADMIN_ROLE.SUPER){
                        let grandTotal = 0
                        // instantiate member outstanding loans
                        let outstandingLoan = new Map<string, number>()
                        loanCategories.forEach(category=>outstandingLoan.set(category, 0))
                        // get current member active loans
                        const activeLoans = loans.filter((loan)=>loan.applicantRef===member.reference)

                        // sort active loan of current member
                        activeLoans.forEach(loan=>{
                            const fines = loan.fines.length > 0 ? loan.fines.reduce((p, c)=>({...c, cost: +c.cost+p.cost})).cost: 0
                            const loanAmount = (loan.capital * (1 + (loan.interestRate/100)))
                            
                            // insurance and application form cost
                            const processingFee = (loan.repayments.length > 0) ? 0: (
                                (+loan.capital * 0.015)+500
                            )
                            const instalment = +(loanAmount/loan.instalments)+processingFee
                            const repayments = loan.repayments.length> 0?loan.repayments.reduce((prev, curr)=>(
                                {...curr, amount: +curr.amount+prev.amount}
                            )).amount: 0
                            const balance = (+loanAmount+processingFee+fines - repayments)
                            const total = balance > instalment ? instalment : balance
                            grandTotal+= total
                            outstandingLoan.set(loan.category, Math.ceil(total))
                            
                        })
                        // compute minimum savings amount
                        const minimumSavings = 0.035 * member.employmentDetail.currentSalary
                        const savings = minimumSavings > member.KISCMSInfo.monthlySavings ?  minimumSavings : member.KISCMSInfo.monthlySavings 
                        deductions.push(
                            {
                                KINDNumber: member.employmentDetail.KINDNumber!,
                                name: member.firstName+" "+member.middleName+" "+member.lastName,
                                department: member.employmentDetail.department,
                                savings: Math.ceil(savings),
                                ...Object.fromEntries(outstandingLoan),
                                loans: Math.ceil(grandTotal)+Math.ceil(savings),
                                businessUnit: member.employmentDetail.businessUnit,
                            }
                        )
                    }
                })
                setTableData(deductions)
            }
        }else{
            // receipts and payments
            const model = tabs[currentTabIndex].model as Model
            model.streamWhere(
                 [
                    {
                        key: 'date',
                        operator: WHEREOPERATOR.GREATER_OR_EQUAL_TO,
                        value: startDate
                    },
                    {
                        key: 'date',
                        operator: WHEREOPERATOR.LESS_OR_EQUAL_TO,
                        value: endDate
                    },
                    {
                        key: 'purpose',
                        operator: WHEREOPERATOR.EQUAL_TO,
                        value: currentFilter
                    },
                ],
                (data) => {
                    // setTableData(data as FinanceData[])
                    const item = data as FinanceData[]
                    const finColumns: FinancialColumnData[] = []
                    item.forEach(finData=>{
                        finColumns.push(
                            {
                                date: new Date(finData.date).toDateString(),
                                name: finData.memberName,
                                amount: finData.amount,
                                transactionRef: finData.transactionRef,
                                processor: finData.processor,
                                ref: finData.reference!
                            }
                        )
                    })
                    setTableData(finColumns)
                },
                (error) => {
                    console.log(error)
                },
            )
        }

    }, [currentTabIndex, startDate, endDate, currentFilter, memberLoans.length, members.length, currentLoanCategory, currentUser, navigate])

    return (
        <section>
            <BreadCrumbComponent 
                pageTitle="Accounts & Schedules"
                currentCrumb="Finance"
                parentPages={[]}
                // message={message}
            />

            <section className="mt-3">
                <div className="row">
                    {
                        tabs.map((tab, index)=>{
                            return (
                                <div onClick={()=>setCurrentTabIndex(index)} key={index} className="col-lg-3 col-md-6">
                                    <div className="d-flex">
                                        <div className="cashbook-icon d-flex justify-content-center align-items-center">
                                            <FontAwesomeIcon className="text-white h4" icon={tab.icon} />
                                        </div>
                                        <div className={`cashbook-icon-text btn d-flex justify-content-center align-items-center ${currentTabIndex===index?"active-icon":""}`}>
                                            {tab.label}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>

                <div className="row mt-4">
                    {
                        !noDate &&
                        <>
                            <div className="col-lg-3 col-md-3">
                                <FormLabel>Start date:</FormLabel>
                                <FormControl 
                                    type="date"
                                    className="col-3"
                                    defaultValue={new Date(startDate).toISOString().slice(0, 10)}
                                    onChange={(e)=>{
                                        const d = new Date(e.target.value)
                                        setStartDate(new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0).getTime())
                                    }}
                                />
                            </div>

                            <div className="col-lg-3 col-md-3">
                                <FormLabel>End date:</FormLabel>
                                <FormControl 
                                    type="date"
                                    className="col-3"
                                    defaultValue={new Date(endDate).toISOString().slice(0, 10)}
                                    onChange={(e)=>{
                                        const d = new Date(e.target.value)
                                        setEndDate(new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59).getTime())
                                    }}
                                />
                            </div>
                        </>
                    }

                    {
                        currentTabIndex!==3 &&
                        <div className="col-lg-3 col-md-3">
                            <FormLabel>Filter:</FormLabel>
                            <select className="form-control" onChange={(e)=>{setCurrentFilter(e.target.value)}} title="filter schedule">
                                {currentFilterList.map((item, indx)=>{
                                    return <option key={indx} value={item} >{item}</option>
                                })}
                            </select>
                        </div>
                    }

                    {
                        (currentTabIndex===2 && currentFilter==="LOANS") &&
                        <div className="col-lg-3 col-md-3">
                            <FormLabel>Loan Category:</FormLabel>
                            <select className="form-control" onChange={(e)=>{
                                const loan: LoanConfiguration = JSON.parse(e.target.value)
                                setCurrentLoanCategory(loan.title)
                            }} title="Loan category">
                                {
                                    availableLoans.map((loan, indx)=>{
                                        return <option value={JSON.stringify(loan)} key={indx}>{loan.title}</option>
                                    })
                                }
                            </select>
                        </div>
                    }                    
                </div>
                <div className="mt-3">
                    <ButtonGroup>
                        <Button 
                        disabled={!StaffFinSec.includes(currentUser?.adminRole!)}
                        variant="warning" 
                        className="btn-sm" 
                        onClick={()=>setShowVoucherModal(true)}>
                            <FontAwesomeIcon className="me-2" icon={faMoneyBill} />
                            Raise Voucher
                        </Button>
                        <Button 
                            disabled={!StaffFinSec.includes(currentUser?.adminRole!)}
                            variant="success" 
                            className="btn-sm" 
                            onClick={()=>setReceiveOtherIncome(true)}>
                            <FontAwesomeIcon className="me-2" icon={faHandHoldingDollar} />
                            Receive Other Income
                        </Button>
                        {
                            tableData.length > 0 && 
                            <Button 
                            disabled={!CoreExecutives.includes(currentUser?.adminRole!)}
                            variant="info" 
                            className="btn-sm" 
                            onClick={()=>{
                                exportFromJSON({ data: tableData, fileName:currentFilter, exportType })
                            }}>
                                <FontAwesomeIcon className="me-2" icon={faDownload} />
                                Download Schedule
                            </Button>
                        }
                    </ButtonGroup>
                </div>

            </section>

            <section className="mt-5">
                <Card>
                    <Card.Body>
                        <CustomTable
                            columns={(currentTabIndex===0 || currentTabIndex===1)?financialcolumns:
                                (currentTabIndex===3?deductionColumns:(
                                currentFilter==='LOANS'?loanScheduleColumns:balanceColumns
                            ))}
                            data={tableData}
                            rowKey='reference'
                        />
                    </Card.Body>
                </Card>
            </section>

            <section className="mt-3">
                <Card>
                    <Card.Header>Pending Vouchers</Card.Header>
                    <Card.Body>
                        <CustomTable
                            columns={voucherColumns}
                            data={voucherTableData}
                            rowKey='reference'
                            onClick={(voucher)=>{
                                // display voucher for approval, cancellation and payment
                                setCurrentVoucher(voucher)
                            }}
                        />
                    </Card.Body>
                </Card>
            </section>

            {showVoucherModal && <VoucherModal show={showVoucherModal} onHide={()=>setShowVoucherModal(false)} />}
            {currentVoucher && <PendingVoucherModal show={currentVoucher!==null} onHide={()=>setCurrentVoucher(null)} voucher={currentVoucher} />}
            {receiveOtherIncome && <OtherIncomeModal show={receiveOtherIncome} onHide={()=>setReceiveOtherIncome(false)} />}
        </section>
    )
}

export default Finance