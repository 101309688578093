import { faGear } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card } from "react-bootstrap"
import { OtherConfigurationItem  } from "../../dataTypes/financials"
import { useState, useEffect, ReactNode } from "react"
import { OtherConfig } from "../../models/OtherConfig.model"
import { moneyFormatter } from "../../utility/helpers"
import { OtherConfigModal } from "./OtherConfigModal.component"
import { DocumentData } from "firebase/firestore"

export const PenaltyAndFine = ()=>{

    const [formItem, setFormItem] = useState<string | null>()
    const [formData, setFormData] = useState<OtherConfigurationItem>(
        {
            dividend: new Map(),
            insuranceCost: 0,
            penaltyAndFines: new Map()
        }
    )
    const [dividendNodes, setDividendNodes] = useState<ReactNode[]>([])
    const [penaltyNodes, setPenaltyNodes] = useState<ReactNode[]>([])


    useEffect(()=>{
        // fetch all loan configurations
        const model = new OtherConfig()
        model.stream((d)=>{
            const data:DocumentData = d
            if(data){
                const item = {
                    dividend: new Map(Object.entries(data.dividend??{})),
                    insuranceCost: data.insuranceCost,
                    penaltyAndFines: new Map(Object.entries(data.penaltyAndFines??{}))
                } as unknown as OtherConfigurationItem
                setFormData(item)
            

                // set existing dividends config
                if(item && item.dividend){
                    const nodeList: ReactNode[] = []
                    item.dividend.forEach((rate, basis)=>{
                        nodeList.push(
                            <div key={basis} className="d-flex justify-content-between btn btn-info mt-2 mb-2">
                                <div>{basis}:</div>
                                <div>{rate}%</div>
                            </div>
                        )
                    })
                    setDividendNodes(nodeList)
                }

                // set existing penalties
                if(item && item.penaltyAndFines){
                    const nodeList: ReactNode[] = []
                    item.penaltyAndFines.forEach((cost, offence)=>{
                        nodeList.push(
                            <div key={offence} className="d-flex justify-content-between btn btn-info mt-2 mb-2">
                                <div>{offence}:</div>
                                <div>&#x20A6;{moneyFormatter(cost)}</div>
                            </div>
                        )
                    })
                    setPenaltyNodes(nodeList)
                }
            }
        }, (error)=>{console.log(error)}, 'otherconfigurationsettings')


    }, [])

   

    return (
        <>
            <div className="mt-5">
                <h6 className="text-grey">
                    <FontAwesomeIcon icon={faGear} className="me-2" />
                     Other Configurations
                </h6>
                <Card>
                    <Card.Body className="p-4">
                        <div className="row">
                            <div className="col-4" onClick={()=>setFormItem('dividend')}>
                                <Card className="p-2 btn">
                                    <Card.Header>Dividend Configuration</Card.Header>
                                    <Card.Body>
                                        {dividendNodes}
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-4" onClick={()=>setFormItem('insuranceCost')} >
                                <Card className="p-2 btn">
                                    <Card.Header>Loan Insurance</Card.Header>
                                    <Card.Body>
                                        <div className="d-flex justify-content-between btn btn-info mt-1 mb-1">
                                            <div>Current rate:</div>
                                            <div>{formData.insuranceCost?? 0}%</div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-4" onClick={()=>setFormItem('penaltyAndFines')} >
                                <Card className="p-2 btn">
                                    <Card.Header>Penalties & Fines</Card.Header>
                                    <Card.Body>
                                        {penaltyNodes}
                                    </Card.Body>
                                </Card>
                            </div>
                            
                        </div>

                        
                    </Card.Body>
                </Card>
            </div>
            {
                formItem && 
                <OtherConfigModal show={formItem!==null} onHide={()=>setFormItem(null)} setFormData={setFormData} configItem={formData} item={formItem} />
            }
        </>
    )
}