// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { fbsession } from '../utility/regex';

// declare global {
//   // eslint-disable-next-line no-var
//   var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
// }

const firebaseConfig = {
  apiKey: "AIzaSyCgW7rcMa-ikac12wb49IeH_V3rehLP9Mk",
  authDomain: "kiscms-3e8ab.firebaseapp.com",
  projectId: "kiscms-3e8ab",
  storageBucket: "kiscms-3e8ab.appspot.com",
  messagingSenderId: "447220582227",
  appId: "1:447220582227:web:3db46782c26b9a9730c3a6",
  measurementId: "G-1478Y1Y073"
};

 // Initialize Firebase
const app = initializeApp(firebaseConfig);
// window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

// Recaptcha
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6LfvJrcnAAAAAC_WqDAr8yYKxcxS6qzJAafIZzo3'),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

getToken(appCheck, true)
  


// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestorDB = getFirestore(app);
export const storage = getStorage(app);
export const cloudFunctions = getFunctions(app);

// connectFunctionsEmulator(cloudFunctions, "localhost", 5001);

// session helper
export const getSessionKey = (): string | null =>{
    const sess = window.sessionStorage as object
    const sessKeys = Object.keys(sess)
    const ses = sessKeys.find(item => fbsession.test(item))
    if(typeof(ses)!=='undefined'){
      return ses
    }
    return null
}

