import { useContext, useEffect, useState } from "react"
import { userContext } from "../providers/user.provider"
import { passwordTester } from "../utility/regex"
import CustomSpinner from "../components/utility/CustomSpinner.component"
import { Button } from "react-bootstrap"
import { Users } from "../models/Users"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { logout } from "./methods/registration"


const Profile = ()=>{
  const navigate = useNavigate()
  const {currentUser, setCurrentUser} = useContext(userContext)
  const [currentTab, setCurrentTab] = useState<string>(currentUser?.needResetPassword? 'password': 'profile')
  const [password, setPassword] = useState<string | null>(null)
  const [canSave, setCanSave] = useState<boolean>(password!==null)
  const [error, setError] = useState<string | null>(null)
  const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)

  useEffect(()=>{
    if(currentUser && currentUser.needResetPassword){
      setCurrentTab('password')
    }
  }, [currentUser, currentUser?.needResetPassword, currentTab])


    return <section className="section profile">
    <div className="row">
      <div className="col-xl-4">

        <div className="card">
          <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">

            <img src={currentUser?.photoUrl} alt="Profile" className="rounded-circle"/>
            <h2 className="text-center">{`${currentUser?.firstName} ${currentUser?.middleName??''} ${currentUser?.lastName}`}</h2>
            <h3 className="text-center">{currentUser?.adminRole}</h3>
            <div className="social-links mt-2">
              
            </div>
          </div>
        </div>

      </div>

      <div className="col-xl-8">

        <div className="card">
          <div className="card-body pt-3">
            <ul className="nav nav-tabs nav-tabs-bordered">

              <li className="nav-item">
                <button onClick={()=>{setCurrentTab('profile')}} className={`nav-link ${currentTab==='profile'?'active':''}`}>Overview</button>
              </li>

              <li className="nav-item">
                <button onClick={()=>setCurrentTab('password')} className={`nav-link ${currentTab==='password'?'active':''}`}>Change Password</button>
              </li>

            </ul>
            <div className="tab-content pt-2">

              <div className={`tab-pane fade profile-overview ${currentTab==='profile'?'active show':''}`} >
                
                <h5 className="card-title">Profile Details</h5>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label ">Full Name</div>
                  <div className="col-lg-9 col-md-8">{`${currentUser?.firstName} ${currentUser?.middleName??''} ${currentUser?.lastName}`}</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Department</div>
                  <div className="col-lg-9 col-md-8">{currentUser?.employmentDetail.department}</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Role</div>
                  <div className="col-lg-9 col-md-8">{currentUser?.adminRole}</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Address</div>
                  <div className="col-lg-9 col-md-8">{currentUser?.contactAddress.street+ ', '
                          +currentUser?.contactAddress.nearestBusStop+', '
                          +currentUser?.contactAddress.localGovernmentArea+', '
                          +currentUser?.contactAddress.state
                  }</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Phone</div>
                  <div className="col-lg-9 col-md-8">{currentUser?.phoneNumbers[0]}</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Email</div>
                  <div className="col-lg-9 col-md-8">{currentUser?.email}</div>
                </div>

              </div>

              <div className={`tab-pane fade pt-3 ${currentTab==='password'?'active show':''}`}>
                {error  && <p className="text-danger text-center">{error}</p>}
                <div className="ps-4 pe-4">
                  <div>
                    <input 
                      type="password"
                      placeholder="Enter your new password"
                      className="mb-2 mt-2 form-control"
                      onChange={(e)=>{
                        if(passwordTester.test(e.target.value)){
                          setPassword(e.target.value)
                          setError(null)
                        } else {
                          setError("Password must be at least 8 characters long with at least one uppercase and a number")
                        }
                      }}
                    />
                  </div>

                  <div>
                    <input 
                      type="password"
                      placeholder="Confirm your new password"
                      className="mb-2 mt-2 form-control"
                      onChange={(e)=>{
                        if(password===e.target.value){
                          setError(null)
                          setCanSave(true)
                        } else if(!passwordTester.test(password!)) {
                          setError("Password must be at least 8 characters long with at least one uppercase and a number")
                        } else {
                          setCanSave(false)
                          setError("Passwords do not match!")
                        }
                      }}
                    />
                  </div>
                  <div>
                    {
                      pageIsBusy ?
                      <CustomSpinner />:
                      <Button
                        disabled={!canSave}
                        className="btn-success form-control"
                        onClick={()=>{
                          if(canSave && password){
                            setPageIsBusy(true)
                            const model = new Users()
                            model.changePassword(password, ()=>{
                              // currentUser.nee
                              model.update({needResetPassword: false}, currentUser?.reference!, ()=>{
                                window.sessionStorage.setItem('currentUser', JSON.stringify({...currentUser, needResetPassword: false}))
                                setCurrentUser!({...currentUser!, needResetPassword: false})
                                toast("Password changed successfully", {type: 'success'})
                                setPageIsBusy(false)
                                navigate('')
                              }, (error)=>{
                                toast('Unable to reset password. Check connections!', {type: 'error'})
                                console.log(error)
                              })
                            }, (er)=>{
                              setPageIsBusy(false)
                              // console.log('error changing password: ', er)
                              if(er.toString().includes('(auth/requires-recent-login)')){
                                const confirmed = window.confirm("This session has expired! You might want to login again!")
                                if(confirmed){
                                  logout(setCurrentUser!)
                                }
                              }
                            })
                          }
                        }}
                      >
                        Reset password
                      </Button>
                    }
                </div>
                </div>
                
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>
  </section>
}

export default Profile