import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { LOANSTATUS, INFLOW_PURPOSE, OUTFLOW_PURPOSE, VOUCHER_PURPOSE, VOUCHER_STATUS } from "../constants/UTILITY.constants"
import { Editor, LoanGuarantorData, UserData } from "./user.types"
import { faHeartCirclePlus, faLandmark, faPlugCircleCheck } from "@fortawesome/free-solid-svg-icons"

export enum REPAYMENT_SOURCE {
    SOURCE = 'salary',
    CASH = 'cash',
    SAVINGS = 'savings',
    WITHDRAWAL = 'withdrawal',
    TRANSFER = 'transfer'
}

export type CreditorDebtorData = {
    amount: number,
    uid: string,
    paid: boolean,
}

export type Loan = {
    reference?: string,
    applicantName: string,
    applicantRef: string,
    category: string,
    capital: number,
    instalments: number,
    collectionDate: number,
    interestRate: number,
    guarantors: LoanGuarantorData[],
    guarantorList: string[],
    status: LOANSTATUS,
    applicationDate: number,
    startDate: number,
    expectedEndDate: number,
    guaranteeRequest: number,
    createdBy: Editor,
    day: number,
    month: number,
    year: number,
    repayments: FinanceData[],
    approvals: Map<LOAN_APPROVERS, Editor>,
    fines: Penalty[]
}

export type AccountBalance = {
    amount: number,
    claimed?: boolean,
    name: string,
    lastUpdated: number,
    reference?: string,
    processor: string
}

export const enum LOAN_APPROVERS {
    PRESIDENT = 'president',
    TREASURER = 'treasurer',
    GEN_SEC = 'general secretary'
}

export type Balances = {
    unclaimedDividend: AccountBalance,
    savings: AccountBalance,
    shares: AccountBalance
}

export type FinanceData = {
    date: number,
    description: string,
    reference?: string,
    transactionRef: string,
    amount: number,
    purpose: INFLOW_PURPOSE | OUTFLOW_PURPOSE,
    memberReference: string,
    memberName: string,
    day: number,
    year: number,
    month: number,
    source: REPAYMENT_SOURCE,
    processor: string,
    processorRef: string,
}

export type FinancialColumnData = {
    date: string,
    name: string,
    transactionRef: string,
    amount: number,
    processor: string,
    ref: string
}

export type VoucherData = {
    purpose: VOUCHER_PURPOSE,
    amount: number,
    approver?: Editor,
    payer?: Editor,
    processor: Editor,
    receiver?: UserData,
    status: VOUCHER_STATUS,
    description: string,
    requestDate: number,
    paymentDate?: number,
    day: number,
    month: number,
    year: number,
    reference?: string
}

export type LoanScheduleColumnData = {
    name: string,
    collectionDate: string,
    capital: number,
    interest: number,
    earned: number,
    unearned: number,
    status: LOANSTATUS,
    ref: string
}

export type DeductionColumnData = {
    KINDNumber: string,
    name: string,
    department: string,
    savings: number,
    loans: number,
    businessUnit: string
}


export type LoanRepaymentData = {
    loanReference: string,
    loanCategory: string,
    day: number,
    year: number,
    month: number
    paymentRef: string
    memberReference: string,
    reference?: string,
    amount: number,
    // paymentSource: REPAYMENT_SOURCE
}

export type LoanApplicationCostData = {
    amount: number,
    reference?: string,
    date: number,
    day: number,
    month: number,
    year: number
}

export type LoanApplicationCost = {
    application: LoanApplicationCostData,
    insurance: LoanApplicationCostData
}

export enum DIVIDEND_BASIS {
    SHARES,
    LOAN_REPAYMENTS,
    SAVINGS
}

export type OtherConfigurationItem = {
    dividend: Map<DIVIDEND_BASIS, number>,
    insuranceCost: number,
    penaltyAndFines: Map<string, number>,
    reference?: string
}

export type LoanConfiguration = {
    title: string,
    reference?: string,
    multiplier: number,
    basis: LOAN_BASIS,
    description: string,
    plans: LoanPlans[],
    isAvailable: boolean,
    isFixed: boolean,
    fixedAmount?: number,
}

export type Penalty = {
    title: string,
    reference?: string,
    code?: string,
    cost: number
}

export type WithdrawalData = {
    credits: Balances,
    debits: Loan[],
    guaranteedLoans: Loan[],
    withdrawalDate: number,
    maturityDate: number,
    withdrawalCharges: number,
    uid: string
}

export type UserPenalty = {
    date: number,
    month: number,
    year: number,
    offense: Penalty,
    reference?: string
}

export type RegistrationPaymentItem = {
    memberId: string,
    memberPassword: string,
    dateJoined: Date,
    email: string,
}

export enum LOAN_BASIS {
    SHARE_CAPITAL = 'SHARE CAPITAL',
    SAVINGS = 'SAVINGS',
    MONTHLY_SAVINGS = 'MONTHLY SAVINGS',
    SALARY = 'SALARY',
    MONTHLY_BALANCE = 'MONTHLY BALANCE'
}

export type LoanPlans = {
    instalments: number,
    rate: number
}

export const initialLoanConfig: LoanConfiguration = {
    title: '',
    isAvailable: true,
    isFixed: false,
    plans: [],
    description: '',
    multiplier: 0,
    basis: LOAN_BASIS.MONTHLY_BALANCE,
}

export const fetchLoanConfigIcon = (loanTitle: string): IconDefinition =>{
    let icon = faLandmark
    switch (loanTitle) {
        case 'Electronic Loan':
            icon = faPlugCircleCheck
            break;
        case 'Compassionate Loan':
            icon = faHeartCirclePlus
            break;
    }
    return icon
}


export type CreditData = {
    amount: number,
    source: REPAYMENT_SOURCE
}

export type GuarantoFormLabel = {
    name: string,
    photoUrl: string,
    availableFund: number,
}

