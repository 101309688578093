import { Button, FormGroup, Modal } from "react-bootstrap"
import { DIVIDEND_BASIS, OtherConfigurationItem } from "../../dataTypes/financials"
import { Dispatch, SetStateAction, useState } from "react"
import { toast } from "react-toastify"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OtherConfig } from "../../models/OtherConfig.model"
import CustomSpinner from "../utility/CustomSpinner.component"



export const OtherConfigModal = ({show, onHide, configItem, item, setFormData}:{show: boolean, onHide: ()=>void, configItem: OtherConfigurationItem, item:string, setFormData: Dispatch<SetStateAction<OtherConfigurationItem>>})=>{

    const [insuranceCost, setInsuranceCost] = useState<number>(configItem.insuranceCost)
    const [dividendBasis, setDividendBasis] = useState<DIVIDEND_BASIS>()
    const [dividendRate, setDividendRate] = useState<number>(0)
    const [penaltyKey, setPenaltyKey] = useState<string>()
    const [penaltyValue, setPenaltyValue] = useState<number>(0)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)

    
    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
            >
                <Modal.Header closeButton>
                  <Modal.Title className="d-flex justify-content-between">
                    {`${item==='dividend'?"Dividend":(item==='insuranceCost'?"Loan Insurance":"Fines")} Configuration`}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        item==='insuranceCost'?
                        <FormGroup>
                            <input 
                                type="number"
                                onChange={(e)=>setInsuranceCost(parseInt(e.target.value))}
                                name="insurnceCost"
                                className="form-control"
                                title="Insurance cost"
                                min={0}
                            />
                        </FormGroup>:
                        (item==='dividend'?
                            <div className="row">
                                <FormGroup className="col-6">
                                    <select onChange={(e)=>{
                                        const val = e.target.value
                                        if(val==='default'){
                                            setDividendBasis(undefined)
                                            toast("Please select a dividend basis!", {type: 'warning'})
                                        }else{
                                            setDividendBasis(val as unknown as DIVIDEND_BASIS)
                                        }
                                    }} title="Dividend configuration" className="form-control">
                                        <option value="default" >Select Dividend Basis</option>
                                        {
                                            Object.values(DIVIDEND_BASIS).filter(item=>typeof(item)!=='number').map((basis, key)=>{
                                                return <option value={basis} key={key} >{basis}</option>
                                            })
                                        }
                                    </select>
                                </FormGroup>
                                <FormGroup className="col-6">
                                    <input 
                                        type="number"
                                        onChange={(e)=>setDividendRate(parseInt(e.target.value))}
                                        name="dividendRate"
                                        className="form-control"
                                        title="Dividend configuration"
                                        min={0}
                                    />
                                </FormGroup>
                            </div>:
                            <div className="row">
                            <FormGroup className="col-6">
                                <input placeholder="Penalty title"
                                    onChange={(e)=>{setPenaltyKey(e.target.value)}}
                                    name="penaltyKey"
                                    className="form-control"
                                />
                            </FormGroup>
                            <FormGroup className="col-6">
                                <input 
                                    type="number"
                                    onChange={(e)=>setPenaltyValue(parseInt(e.target.value))}
                                    name="penalty"
                                    className="form-control"
                                    title="Fines and penalty"
                                    min={0}
                                />
                            </FormGroup>
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                  {
                    pageIsBusy?
                    <CustomSpinner />:
                    <Button 
                  disabled={
                    insuranceCost <=0 &&
                    (dividendBasis===undefined || dividendRate <=0) &&
                    (penaltyKey===undefined || penaltyValue <=0)
                  }
                  onClick={()=>{
                    const dbItem = {
                        insuranceCost: 0,
                        dividend: Object.fromEntries(configItem.dividend)??{},
                        penaltyAndFines: Object.fromEntries(configItem.penaltyAndFines)??{}
                    }
                    setPageIsBusy(true)
                    const model = new OtherConfig()
                    if(insuranceCost >= 0){
                        // configItem.insuranceCost = insuranceCost
                        dbItem.insuranceCost = insuranceCost
                    }
                    
                    if(dividendBasis && dividendRate >= 0){
                        configItem.dividend.set(dividendBasis!, dividendRate)
                        dbItem.dividend = Object.fromEntries(configItem.dividend)
                    }
                    
                    if(penaltyKey && penaltyValue >= 0){
                        configItem.penaltyAndFines.set(penaltyKey!, penaltyValue)
                        dbItem.penaltyAndFines = Object.fromEntries(configItem.penaltyAndFines)
                    }

                    // check if config is setting up for the first time

                    // update config item
                    model.save(dbItem, {
                        id: 'otherconfigurationsettings',
                        callBack: (id)=>{
                            // update local value
                            setFormData({...configItem, reference: id})
                            toast("Configuration update successfully!", {type: 'success'})
                            setPageIsBusy(false)
                            onHide()
                        },
                        errorHander(_) {
                            toast("Check connections!", {type: 'error'})
                            setPageIsBusy(false)
                        },
                    })
                    
                  }} variant="success" className="btn-sm" >
                    <FontAwesomeIcon icon={faSave} /> Save
                  </Button>
                  }
                </Modal.Footer>
              </Modal>
    )
}