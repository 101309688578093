import { Dispatch, Fragment, LegacyRef, SetStateAction, useRef, useState } from "react"
import { Card, FormGroup } from "react-bootstrap"
import { toast } from "react-toastify"
import { LOANSTATUS, MEMBERSHIP_STATUS } from "../../constants/UTILITY.constants"
import { Loan, GuarantoFormLabel } from "../../dataTypes/financials"
import { WHEREOPERATOR } from "../../dataTypes/firebasequery.types"
import { LoanGuarantorData, UserData } from "../../dataTypes/user.types"
import { Loans } from "../../models/Loans.model"
import { Savings } from "../../models/Savings.model"
import { Users } from "../../models/Users"
import { moneyFormatter } from "../../utility/helpers"

export const GuarantorLabel = ({
    setError,
    setRequest,
    // index,
    setAllRequests,
    allGuarantor,
    requiredAmount,
    applicant,
    setCurrentGuarantor,
    isUpdate = false
}:{
    setError: Dispatch<SetStateAction<string | null>>,
    setRequest: Dispatch<SetStateAction<Loan>>,
    // index: number,
    setAllRequests: Dispatch<SetStateAction<Map<string, number>>>,
    allGuarantor: Map<string, number>
    requiredAmount: number,
    applicant: UserData
    setCurrentGuarantor?: Dispatch<SetStateAction<LoanGuarantorData | null>>,
    isUpdate?: boolean
})=>{
    const [guarantorLabel, setGuarantoLabel] = useState<GuarantoFormLabel | null>(null)
    // internally accessible guarantor info
    const [gurantor, setGuarantor] = useState<UserData | null>(null)
    const ref: LegacyRef<HTMLInputElement> = useRef(null)
    

    return (
        <Fragment>
            <FormGroup className="mb-2 form-control bg-dark">
                <input ref={ref} title="Laon guarantor" type="number" min={100} className="form-control" onChange={(e)=>{
                        const regNum = parseInt(e.target.value)
                        // validate account number
                        if(!isNaN(regNum) && e.target.value.length===10){
                            if(ref.current){
                                ref.current.disabled = true
                            }
                            // prevent multiple guaranteeing
                            if(allGuarantor.has(`${regNum}`)){
                                setError("Member already a guarantor!")
                                if(ref.current){
                                    ref.current.disabled = false
                                }
                            
                                // prevent self guaranteeing
                            } else if(regNum===parseInt(applicant.accountNumber)){
                                setError("Self guaranteed loans not allowed please!!!")
                                if(ref.current){
                                    ref.current.disabled = false
                                }
                            } else{
                                // remove error message
                                setError(null)
                                // search for user by account number and active status
                                const userModel = new Users()
                                userModel.findWhere({
                                    wh: [
                                        {
                                            key: 'accountNumber',
                                            operator: WHEREOPERATOR.EQUAL_TO,
                                            value: regNum.toString()
                                        },
                                        {
                                            key: 'status',
                                            operator: WHEREOPERATOR.EQUAL_TO,
                                            value: MEMBERSHIP_STATUS.ACTIVE
                                        }
                                    ],
                                    callBack(data) {
                                        // fetch guarantor's savings balance and 
                                        // fetch all active loans guaranteed by guarantor
                                        if(data.length > 0){
                                            const user: UserData = data[0] as UserData
                                            const savingsModel = new Savings()
                                            savingsModel.find(user.reference!, (s)=>{
                                                if(s){
                                                    //user has savings
                                                    const savings: number = s.amount ?? 0
                                                    if(savings > 0){
                                                        // search all active loans guaranteed by guarantor
                                                        const loanModel = new Loans()
                                                        loanModel.findWhere({
                                                            wh: [
                                                            {
                                                                key: 'gurantorList',
                                                                operator: WHEREOPERATOR.ARRAY_CONTAINS,
                                                                value: user.reference!
                                                            },
                                                            {
                                                                key: 'status',
                                                                operator: WHEREOPERATOR.IN,
                                                                value: [LOANSTATUS.ACTIVE, LOANSTATUS.APPROVED, LOANSTATUS.BAD, LOANSTATUS.DOUBTFUL, 
                                                                    LOANSTATUS.GUARANTEED, LOANSTATUS.NON_PERFORMING, LOANSTATUS.PARTIALLY_GUARANTEED,
                                                                    LOANSTATUS.UNGUARANTEED]
                                                            }
                                                            ],
                                                            callBack(l) {
                                                                // calculate guarantor liabilites
                                                                let guaranteeLiablity = 0
                                                                if(l.length > 0){
                                                                    const guarantorLoans = l as Loan[]
                                                                    
                                                                    guarantorLoans.forEach(loan=>{
                                                                        const gu = loan.guarantors.find(g=>g.reference===user.reference)
                                                                        guaranteeLiablity+= (gu?.sumRequest! - gu?.requestOffset!) ?? 0
                                                                    })
                                                                }

                                                               // check guarantor can guarantee
                                                               if(savings > guaranteeLiablity){
                                                                    const label: GuarantoFormLabel = {
                                                                        name: user.firstName+' '+user.middleName+' '+user.lastName,
                                                                        photoUrl: user.photoUrl,
                                                                        availableFund: +savings - guaranteeLiablity
                                                                    }
                                                                    setGuarantor(user as UserData)
                                                                    setGuarantoLabel(label)
                                                                    // add to guarantor list
                                                                    // set all request
                                                                    setAllRequests(new Map([
                                                                        [user?.accountNumber, 0]
                                                                    ] )) 
                                                               }else{
                                                                // user cannot guarantee loan
                                                                setError("Sorry!, this member cannot guarantee loans at the moment")
                                                               }
                                                        
                                                                // release form input
                                                                if(ref.current){
                                                                    ref.current.disabled = false
                                                                }
                                                            },
                                                            errorHandler(e) {
                                                                if(ref.current){
                                                                    ref.current.disabled = false
                                                                }
                                                                console.log(e)
                                                                toast("Unable to get guarantor loans. Check connections!", {type: 'error'})
                                                            },
                                                        })
                                                    }else{
                                                        if(ref.current){
                                                            ref.current.disabled = false
                                                        }
                                                        // user has no money in savings
                                                        toast("Insufficient fund in wallet!", {type: 'info'})
                                                    }
                                                }else{
                                                    if(ref.current){
                                                        ref.current.disabled = false
                                                    }
                                                    // user does not money in savings
                                                    toast("Guarantor has no savings", {type: 'info'})
                                                }
                                            }, (_)=>{
                                                if(ref.current){
                                                    ref.current.disabled = false
                                                }
                                                toast("Unable to get guarantor detail. Check connection!", {type: 'error'})
                                            })
                                        }else{
                                            if(ref.current){
                                                ref.current.disabled = false
                                            }
                                            toast("Guarantor does not exist please!", {type: 'error'})
                                        }
                                    },
                                    errorHandler(_) {
                                        if(ref.current){
                                            ref.current.disabled = false
                                        }
                                        toast("Unable to get guarantor detail. Check connection!", {type: 'error'})
                                    },
                                })
                            }
                        }else{
                            if(ref.current){
                                ref.current.disabled = false
                            }
                            setError("Enter a valid account number")
                        }
                    }} placeholder="Enter guarantor's account number" name="capital" />
            </FormGroup>
            {
                guarantorLabel && 
                <div>
                    <Card>
                        <Card.Body>
                            <div className="d-flex">
                                <div className="me-3 pt-4">
                                    <img src={guarantorLabel.photoUrl} alt="guarantor" className="guarantor-img" />
                                </div>
                                <div className="pt-4">
                                    <h3>{guarantorLabel.name}</h3>
                                    <FormGroup>
                                        <small>Request:</small>
                                        <input onChange={(e)=>{
                                            const amount = parseInt(e.target.value)
                                            let contributions: number = 0
                                                allGuarantor.forEach((value, key)=>{
                                                    if(key!==gurantor?.accountNumber){
                                                        contributions+=value
                                                    }
                                                })
                                                
                                            if(isNaN(amount)){
                                                toast("Enter a valid amount", {type: 'error'})
                                            }else if (amount > guarantorLabel.availableFund){
                                                toast("Amount is beyond guarantor's capacity", {type: 'warning'})
                                            } else if((contributions+amount) > requiredAmount){
                                                toast("Amount is beyond required amount", {type: 'info'})
                                            } else {
                                                setRequest(prev=>{
                                                    // set loan guarantor data
                                                    const index = prev.guarantorList.indexOf(gurantor?.reference!)
                                                    if(index >= 0){
                                                        prev.guarantors[index] = {
                                                            reference: gurantor?.reference!,
                                                            displayName: guarantorLabel.name,
                                                            sumRequest: amount,
                                                            approved: isUpdate,
                                                            requestOffset: 0,
                                                            accountNumber: gurantor?.accountNumber!
                                                        }

                                                        if(isUpdate){
                                                            prev.guarantors[index].approvalDate = Date.now()
                                                        }

                                                        // set current guarantor
                                                        if(setCurrentGuarantor){
                                                            setCurrentGuarantor(prev.guarantors[index])
                                                        }

                                                        // set list of guarantors
                                                        prev.guarantorList[index] = gurantor?.reference!
                                                        return prev
                                                    } else {
                                                        prev.guarantors.push(
                                                            {
                                                                reference: gurantor?.reference!,
                                                                displayName: guarantorLabel.name,
                                                                sumRequest: amount,
                                                                approved: false,
                                                                requestOffset: 0,
                                                                accountNumber: gurantor?.accountNumber!
                                                            }
                                                        )

                                                        // set current guarantor
                                                        if(setCurrentGuarantor){
                                                            setCurrentGuarantor(prev.guarantors[index])
                                                        }

                                                        // set list of guarantors
                                                        prev.guarantorList.push(gurantor?.reference!)
                                                        return prev
                                                    }
                                                })
                                            }
                                            // set all request
                                            setAllRequests(prev=>(new Map([
                                                ...prev,
                                                [gurantor?.accountNumber!, amount]
                                            ])))
                                        }} title="guarantee request" className="form-control" type="number" name="amount" />
                                    </FormGroup>
                                    <p>Available fund: <span className="text-success">&#x20A6;{moneyFormatter(guarantorLabel.availableFund)}</span></p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
        </Fragment>
    )
}