import { Modal, Button, Form, FormGroup, FormLabel, FormSelect } from "react-bootstrap"
import { LOAN_BASIS, LoanConfiguration, LoanPlans, initialLoanConfig } from "../../dataTypes/financials"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCirclePlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { LoanPlanForm } from "./LoanPlanForm.component";
import { decreasePlans, increasePlans, saveLoanConfig, updateForm } from "../../screens/methods/configuration"
import CustomSpinner from "./CustomSpinner.component"

const loanBasisList: LOAN_BASIS[] = [
    LOAN_BASIS.MONTHLY_BALANCE, LOAN_BASIS.MONTHLY_SAVINGS,
    LOAN_BASIS.SALARY, LOAN_BASIS.SAVINGS, LOAN_BASIS.SHARE_CAPITAL
]

export const ConfigurationForm = ({show, handleClose, item, existingConfigs}: {show: boolean, handleClose: ()=>void, item: LoanConfiguration | null, existingConfigs: LoanConfiguration[]})=>{

    const [formData, setFormData] = useState<LoanConfiguration>(item ?? initialLoanConfig)
    const [planIndex, setPlanIndex] = useState<number[]>([0])
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)

    useEffect(()=>{
        if(item){
            item.plans.forEach((_, indx)=>{
                setPlanIndex(pre=>{
                    pre[indx] = indx
                    return [...pre]
                })
            })
        }
    }, [item])

    return (
        <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex justify-content-between">
            {formData.title?formData.title: "New Loan Configuration"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex justify-content-end">
                <Form.Check 
                    type="switch"
                    label={formData.isAvailable?"Deactivate":"Activate"}
                    name="isAvailable"
                    className="me-4"
                    defaultChecked={formData.isAvailable}
                    onChange={(e)=>updateForm(e, setFormData)}
                />

                <Form.Check 
                    type="switch"
                    label={formData.isFixed?"Vary":"Fixed"}
                    name="isFixed"
                    className="me-4"
                    defaultChecked={formData.isFixed}
                    onChange={(e)=>updateForm(e, setFormData)}
                />
            </div>
            <FormGroup className="mt-3">
                <FormLabel>
                    Loan Title:
                </FormLabel>
                <input placeholder="Loan title" 
                    type="text" 
                    onChange={(e)=>updateForm(e, setFormData)}
                    defaultValue={formData?.title!} 
                    name="title"
                    className="form-control"
                />
            </FormGroup>
            <div className="row mt-3">
                <FormGroup className="col-4">
                    <FormLabel>
                        {formData.isFixed?"Fixed amount:":"Loan multiplier:"}
                    </FormLabel>
                    <input placeholder={formData.isFixed?"enter amount":'enter multiplier'}
                        type="number"
                        step={0.01} 
                        onChange={(e)=>updateForm(e, setFormData)}
                        defaultValue={formData.isFixed?formData.fixedAmount:formData.multiplier} 
                        name={formData.isFixed?'fixedAmount':'multiplier'}
                        className="form-control"
                    />
                </FormGroup>
                <FormGroup className="col-8">
                    <FormLabel>
                        Loan Basis:
                    </FormLabel>
                    <FormSelect defaultValue={formData.basis} 
                        onChange={(e)=>updateForm(e, setFormData)} 
                        name="basis">
                        <option value="default">Choose loan basis</option>
                    {
                        loanBasisList.map((base, index)=>{
                            return <option key={index} value={base}>{base.toLocaleLowerCase()}</option>
                        })
                    }
                    </FormSelect>
                </FormGroup>
            </div>
            <div className="row mt-3">
                    <FormGroup>
                        <FormLabel>Description</FormLabel>
                        <input placeholder="Loan Description" 
                            type="text" 
                            onChange={(e)=>updateForm(e, setFormData)}
                            defaultValue={formData?.description!} 
                            name="description"
                            className="form-control"
                        />
                    </FormGroup>
            </div>
            <div className="row mt-3">
                <FormGroup>
                    <FormLabel>
                        Loan plans
                        <FontAwesomeIcon onClick={()=>decreasePlans(planIndex, setPlanIndex, formData, setFormData)} className="btn text-danger" icon={faTrash} />
                        <FontAwesomeIcon onClick={()=>increasePlans(planIndex, setPlanIndex)} className="btn text-success" icon={faCirclePlus} />
                    </FormLabel>
                    {
                        planIndex.map((index)=>{
                            return <LoanPlanForm 
                            currentInstalment={formData.plans[index]?.instalments!}
                            currentRate={formData.plans[index]?.rate!}
                             position={index} key={index} 
                            changeHandler={(e, indx)=>{
                                const name = e.target.name
                                const value = e.target.value
                                const loanPlan:LoanPlans = {...formData.plans[indx], [name]: value}
                                formData.plans[indx] = loanPlan
                                setFormData({...formData})
                            }} />
                        })
                    }
                </FormGroup>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={pageIsBusy} onClick={()=>saveLoanConfig(formData, existingConfigs, handleClose, setFormData, setPageIsBusy)} className="btn-sm" variant="success">
            <FontAwesomeIcon icon={faSave} /> Save
          </Button>
          {pageIsBusy && <div><CustomSpinner /></div>}
        </Modal.Footer>
      </Modal>
    )
}