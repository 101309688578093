import { Button, Card, FormGroup, FormLabel, Modal } from "react-bootstrap"
import { LegacyRef, useContext, useRef, useState } from "react"
import { toast } from "react-toastify"
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CustomSpinner from "../utility/CustomSpinner.component"
import { UserData } from "../../dataTypes/user.types"
import { Users } from "../../models/Users"
import { WHEREOPERATOR } from "../../dataTypes/firebasequery.types"
import { VoucherData } from "../../dataTypes/financials"
import { VOUCHER_PURPOSE, VOUCHER_STATUS } from "../../constants/UTILITY.constants"
import { userContext } from "../../providers/user.provider"
import { Vouchers } from "../../models/Vouchers.model"



export const VoucherModal = ({show, onHide}:{show: boolean, onHide: ()=>void})=>{

    const d = new Date()
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    const ref: LegacyRef<HTMLInputElement> = useRef(null)
    const {currentUser} = useContext(userContext)
    const [validationError, setValidationError] = useState<string | null>(null)
    const [formData, setFormData] = useState<VoucherData>({
        description: '',
        amount: 0,
        purpose: VOUCHER_PURPOSE.SALARY,
        processor: {
            name: currentUser?.firstName + " "+ currentUser?.middleName+" "+ currentUser?.lastName,
            date: d.getTime(),
            reference: currentUser?.reference!
        },
        status: VOUCHER_STATUS.RAISED,
        requestDate: d.getTime(),
        day: d.getDate(),
        month: d.getMonth() + 1,
        year: d.getFullYear()
    })

    
    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
            >
                <Modal.Header closeButton>
                  <Modal.Title className="d-flex justify-content-between">
                    Expenditure Voucher
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {validationError && <p className="text-danger text-center bg-warning p-2">{validationError}</p>}
                    <div className="mt-2">
                        <FormGroup className="mb-2 form-control bg-success">
                            <FormLabel className="text-white">Receiver:</FormLabel>
                            <input ref={ref} title="Request receiver" type="number" min={100} className="form-control" onChange={(e)=>{
                            const regNum = parseInt(e.target.value)
                            if(!isNaN(regNum) && e.target.value.length===10){
                                setValidationError(null)
                                if(ref.current){
                                    ref.current.disabled = true
                                }
                                    // search for user
                                    const userModel = new Users()
                                    userModel.findWhere({
                                        wh: [
                                            {
                                                key: 'accountNumber',
                                                operator: WHEREOPERATOR.EQUAL_TO,
                                                value: regNum.toString()
                                            }
                                        ],
                                        callBack(data) {
                                            // fetch all active loans guaranteed by user
                                            if(data.length > 0){
                                                setFormData(prev=>({...prev, receiver: data[0] as UserData}))
                                            }else{
                                                setFormData(prev=>({...prev, receiver: undefined}))
                                                if(ref.current){
                                                    ref.current.disabled = false
                                                }
                                                toast("User does not exist please!", {type: 'error'})
                                            }
                                        },
                                        errorHandler(_) {
                                            if(ref.current){
                                                ref.current.disabled = false
                                            }
                                            toast("Unable to get user detail. Check connection!", {type: 'error'})
                                        },
                                    })
                            }else{
                                setValidationError("Enter a valid account number")
                                if(ref.current){
                                    ref.current.disabled = false
                                }
                            }
                        }} placeholder="Enter receiver's account number" name="capital" />
                        </FormGroup>
                   
                    {
                        formData.receiver && 
                        
                            <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <div className="me-3 pt-4">
                                            <img src={formData.receiver.photoUrl} alt="receiver" className="guarantor-img" />
                                        </div>
                                        <div className="pt-4 text-center">
                                            <h3>{`${formData.receiver.firstName} ${formData.receiver.middleName} ${formData.receiver.lastName}`}</h3>
                                            <div className="d-flex justify-content-between form-control bg-dark text-white">
                                                <div>Department:</div>
                                                <div>{formData.receiver.employmentDetail.department}</div>
                                            </div>
                                            <div className="d-flex justify-content-between form-control mt-2 bg-dark text-white">
                                                <div>Business unit:</div>
                                                <div>{formData.receiver.employmentDetail.businessUnit}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                    }
                    </div>

                    <div className="mt-2">
                        <FormGroup className="form-control bg-success">
                            <FormLabel className="text-white">Request amount:</FormLabel>
                            <input title="Request amount" type="number" min={100} className="form-control" onChange={(e)=>{
                            const amount = parseInt(e.target.value)
                            if(amount > 0){
                                setFormData(prev=>({...prev, amount}))
                            }else{
                                toast("Enter a valid amount", {type: 'warning'})
                            }
                        }} placeholder="Enter request amount" name="capital" />
                        </FormGroup>
                    </div>

                    <div className="mt-2">
                        <FormGroup className="form-control bg-success">
                            <FormLabel className="text-white">Request description:</FormLabel>
                            <textarea 
                                title="Request description" 
                                rows={4} 
                                className="form-control"
                                placeholder="Enter request description"
                                onChange={(e)=>{
                                    setFormData(prev=>({...prev, description: e.target.value}))
                                }}
                            />
                        </FormGroup>
                    </div>

                    <div className="mt-2">
                        <FormGroup className="form-control bg-success">
                            <FormLabel className="text-white">Request purpose:</FormLabel>
                            <select className="form-control" title="Request purpose" defaultValue={formData.purpose} onChange={(e)=>{
                                setFormData(prev=>({...prev, purpose: e.target.value} as VoucherData))
                            }}>
                                {
                                    Object.values(VOUCHER_PURPOSE).filter(item=>typeof(item)!=='number').map((purpose, index)=>{
                                        return <option key={index} value={purpose} >{purpose}</option>
                                    })
                                }
                            </select>
                        </FormGroup>
                    </div>
            
                </Modal.Body>
                <Modal.Footer>
                  {
                    pageIsBusy?
                    <CustomSpinner />:
                    <Button 
                        disabled={
                            typeof(formData.receiver) === 'undefined' ||
                            formData.amount <= 0 ||
                            formData.description===""
                        }
                        variant="info"
                        className="btn-sm"
                        onClick={()=>{
                            setPageIsBusy(true)
                            const voucherModel = new Vouchers()
                            voucherModel.save(formData, {
                                callBack() {
                                    toast("New voucher raised successfully", {type: 'success'})
                                    onHide()
                                },
                                errorHander(e) {
                                    console.log(e)
                                    toast("Check connections!", {type: 'info'})
                                },
                            })
                        }}
                  >
                    <FontAwesomeIcon icon={faFolderPlus} /> Apply
                  </Button>
                  }
                </Modal.Footer>
              </Modal>
    )
}