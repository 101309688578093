import { faContactBook, faPerson, faQuestionCircle, faSignIn, faSigning } from "@fortawesome/free-solid-svg-icons";
import { ListItem } from "../dataTypes/customTypes/varTypes";
import { usePaystackPayment } from "react-paystack";
import { PaystackProps } from "react-paystack/dist/types";

export const COMPONENTLISTS: ListItem[] = [
    {
        label: 'Alerts',
        path: '/alerts'
    },
    {
        label: 'Accordion',
        path: '/accordion'
    },
    {
        label: 'Badges',
        path: '/badges'
    },
    {
        label: 'Breadcrumbs',
        path: '/breadcrumbs'
    },
    {
        label: 'Buttons',
        path: '/buttons'
    },
    {
        label: 'Cards',
        path: '/cards'
    },
    {
        label: 'Carousel',
        path: '/carousel'
    },
    {
        label: 'List groups',
        path: '/listgroups'
    },
    {
        label: 'Modals',
        path: '/modals'
    },
    {
        label: 'Tabs',
        path: '/tabs'
    },
    {
        label: 'Pagination',
        path: '/pagination'
    },
    {
        label: 'Progress',
        path: '/progress'
    },
    {
        label: 'Spinners',
        path: '/spinners'
    },
    {
        label: 'Tooltips',
        path: '/tooltips'
    },
]

export const FORMS: ListItem[] = [
    {
        label: 'Form elements',
        path: '/formelement'
    },
    {
        label: 'Form layouts',
        path: '/formlayout'
    },
    {
        label: 'Form editors',
        path: '/editors'
    },
    {
        label: 'Form validations',
        path: '/validations'
    },
   
]

export const TABLES: ListItem[] = [
    {
        label: 'Alerts',
        path: '/alerts'
    },
    {
        label: 'Accordion',
        path: '/accordion'
    },
  
]

export const CHARTS: ListItem[] = [
    {
        label: 'Chart.js',
        path: '/chartjs'
    },
    {
        label: 'ApexCharts',
        path: '/apexcharts'
    },
    {
        label: 'Echarts',
        path: '/echarts'
    },
]

export const ICONS: ListItem[] = [
    {
        label: 'Bootstrap Icons',
        path: '/bootstrapicons'
    },
    {
        label: 'Remix Icons',
        path: '/remixicons'
    },
    {
        label: 'Boxicons',
        path: '/boxicons'
    },
]

export const SINGLELISTS: ListItem[] = [
    {
        label: 'Profile',
        path: '/profile',
        icon: faPerson
    },
    {
        label: 'F.A.Q',
        path: '/faq',
        icon: faQuestionCircle
    },
    {
        label: 'Contact',
        path: '/contact',
        icon: faContactBook
    },
    {
        label: 'Register',
        path: '/register',
        icon: faSignIn
    },
    {
        label: 'Login',
        path: '/login',
        icon: faSigning
    },
  
]

const PAYSTACK_TEST_KEY = 'pk_test_636d906302e7382afb1a5c257cb6ebedabeae6dd';

export const useInitializePayment = (email: string, amount:number, reference?: string)=>{
    const config: PaystackProps = {
        email: email,
        amount: amount, 
        publicKey: PAYSTACK_TEST_KEY,
        reference
    };
    return usePaystackPayment(config);
}