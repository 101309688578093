import {  useContext, useEffect, useState } from "react"
import { Button, Card, Form, FormGroup, FormLabel, Modal } from "react-bootstrap"
import { FinanceData, Loan, LoanApplicationCost, OtherConfigurationItem, } from "../../dataTypes/financials"
import {  INFLOW_PURPOSE } from "../../constants/UTILITY.constants"
import { REPAYMENT_SOURCE } from "../../dataTypes/financials"
import { toast } from "react-toastify"
import { saveLoanRepayment } from "./methods"
import { userContext } from "../../providers/user.provider"
import { useInitializePayment } from "../../constants/configuration.constants"
import { moneyFormatter } from "../../utility/helpers"
import { OtherConfig } from "../../models/OtherConfig.model"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCreditCard } from "@fortawesome/free-solid-svg-icons"
import CustomSpinner from "../utility/CustomSpinner.component"

export const LoanRepaymentModal = ({show, onHide, loan }:{show:boolean, onHide:()=>void, loan: Loan})=>{
    const d = new Date()

    const [loanApplicationCost, setLoanApplicationCost] = useState<LoanApplicationCost>({
        insurance: {
            amount: 0,
            date: d.getTime(),
            day: d.getDate(),
            month: d.getMonth() + 1,
            year: d.getFullYear()
        },
        application: {
            amount: loan.repayments.length > 0?0:500,
            date: d.getTime(),
            day: d.getDate(),
            month: d.getMonth() + 1,
            year: d.getFullYear()
        }
     })
    const [paymentDate, setPaymentDate] = useState<Date>(d)
    const repayments:number = loan.repayments.length > 0?loan.repayments.reduce((prev, curr)=>({...curr, amount: (+curr.amount+prev.amount)})).amount:0
    const loanFine = loan.fines.length > 0 ? loan.fines.reduce((prev, curr)=> ({...curr, cost: +curr.cost+prev.cost})).cost : 0
    const instalmnents = ((loan.capital * (1 + (loan.interestRate/100))/loan.instalments)) + loanFine
    const balance = (loan.capital * (1 + (loan.interestRate/100))) - repayments + loanFine
    const paymentSources = [REPAYMENT_SOURCE.CASH, REPAYMENT_SOURCE.SAVINGS, REPAYMENT_SOURCE.SOURCE, REPAYMENT_SOURCE.TRANSFER]
    const {currentUser} = useContext(userContext)
    const {firstName, lastName, middleName} = currentUser!
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    const [formData, setFormData] = useState<FinanceData>({
        date: paymentDate.getTime(),
        description: `Repayment for ${loan.category} loan by ${loan.applicantName}`,
        transactionRef: `REF_${Date.now()}_REPAYMNET_salary`,
        source: REPAYMENT_SOURCE.SOURCE,
        amount: Math.ceil(instalmnents > balance? 
            (+balance+(+loanApplicationCost.application.amount+loanApplicationCost.insurance.amount)): 
            (+instalmnents+(+loanApplicationCost.application.amount+loanApplicationCost.insurance.amount))),
        purpose: INFLOW_PURPOSE.LOAN_REPAYMENT,
        memberReference: loan.applicantRef,
        day: paymentDate.getDate(),
        year: paymentDate.getFullYear(),
        month: paymentDate.getMonth() + 1,
        processor: `${firstName} ${middleName} ${lastName}`,
        memberName: loan.applicantName,
        processorRef: currentUser?.reference!
    } )

     // make payment on paystack
     const initializePayment = useInitializePayment('support@kiscms.com', ((
        formData?.amount!
        )*100), formData?.transactionRef)

    useEffect(()=>{

        // Get loan application cost
        if(loan.repayments.length === 0){
            // get cost of insurance
            const otherConfig = new OtherConfig()
            otherConfig.findAll((data)=>{
                const config = data[0] as OtherConfigurationItem
                if(config){
                    setLoanApplicationCost(prev=>({...prev, insurance: {
                        ...prev.insurance,
                        amount: (config.insuranceCost/100) * loan.capital,
                    }} as LoanApplicationCost))

                    // change loan amount
                    setFormData(prev=>({...prev, amount: Math.ceil(instalmnents > balance? 
                        (+balance+(+(loanApplicationCost.application.amount)+(config.insuranceCost/100) * loan.capital)): 
                        (+instalmnents+(+(loanApplicationCost.application.amount)+(config.insuranceCost/100) * loan.capital)))}))
                }
            }, (error)=>{console.log(error)})
        }

    }, [])
    
    return (
            <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
            >
            <Modal.Header closeButton>
                Loan Payout
            </Modal.Header>
            <Modal.Body>
                <FormGroup>
                    <Form.Label>
                        Payment source:
                    </Form.Label>
                    <select onChange={(e)=>{
                        const dynamicSource = [REPAYMENT_SOURCE.CASH, REPAYMENT_SOURCE.SOURCE]
                        const refSouces = [REPAYMENT_SOURCE.SAVINGS, REPAYMENT_SOURCE.SOURCE, REPAYMENT_SOURCE.TRANSFER]
                        const source = e.target.value

                        if(!dynamicSource.includes(source as REPAYMENT_SOURCE)){
                            setPaymentDate(new Date())
                        }

                        if(refSouces.includes(source as REPAYMENT_SOURCE)){
                            setFormData(prev=>({...prev, 
                                amount: Math.ceil(instalmnents > balance? 
                                    (+balance+(+loanApplicationCost.application.amount+loanApplicationCost.insurance.amount)): 
                                    (+instalmnents+(+loanApplicationCost.application.amount+loanApplicationCost.insurance.amount))), 
                                transactionRef: `REF_${Date.now()}_REPAYMNET_${source}`, 
                                } as FinanceData))
                        }
                        // set form source
                        setFormData(prev=>({...prev, source} as FinanceData))
                    }} className="form-control" name="source" defaultValue={REPAYMENT_SOURCE.SOURCE} title="payment source" >
                        {
                            paymentSources.map((source, index)=>{
                                return <option key={index} value={source}>{source}</option>
                            })
                        }
                    </select>
                </FormGroup>
                {
                    <>

                        {
                            formData?.source === REPAYMENT_SOURCE.CASH && 
                            <FormGroup>
                                <FormLabel>
                                    Reference:
                                </FormLabel>
                                <input className="form-control" placeholder="Enter transaction reference" 
                                    onChange={(e)=>{
                                        // check if amount is greater or equal to minimum amount
                                        const val = e.target.value
                                        setFormData(prev=>({...prev, transactionRef: val} as FinanceData))
                                    }} 
                                    defaultValue={formData?.transactionRef} 
                                    name="transactionRef"
                                />
                            </FormGroup>
                        }

                        <FormGroup className="">
                            <FormLabel>
                                Amount:
                            </FormLabel>
                            <input className="form-control" placeholder="Enter transaction amount" 
                                onChange={(e)=>{
                                    const minimumPayment = Math.ceil(instalmnents > balance? 
                                        (+balance+(+loanApplicationCost.application.amount+loanApplicationCost.insurance.amount)): 
                                        (+instalmnents+(+loanApplicationCost.application.amount+loanApplicationCost.insurance.amount)))

                                    // check if amount is greater or equal to minimum amount
                                    const val = e.target.value
                                    if(parseInt(val) < minimumPayment){
                                        toast(`Repayment cannot be less than ${moneyFormatter(Math.ceil(minimumPayment))}`)
                                        setFormData(prev=>({...prev, amount: Math.ceil(parseInt(val))} as FinanceData))
                                    }else if(isNaN(parseInt(val)) || parseInt(val) <= 0){
                                        toast("Please enter a valid amount", {type: 'error'})
                                    }else{
                                        setFormData(prev=>({...prev, amount: Math.ceil(parseInt(val))} as FinanceData))
                                    }
                                }} 
                                defaultValue={formData?.amount} 
                                name="amount"
                                type="number"
                             />
                        </FormGroup>

                        {
                            formData?.source!== REPAYMENT_SOURCE.TRANSFER &&
                            <FormGroup>
                                <FormLabel>
                                    Payment date:
                                </FormLabel>
                                <input className="form-control" placeholder="Enter transaction reference"
                                    type="date"
                                    onChange={(e)=>{
                                        setPaymentDate(new Date(e.target.value))
                                    }}
                                    defaultValue={new Date(formData.date).toISOString().slice(0, 10)} 
                                    name="paymentDate" />
                            </FormGroup>
                        }

                        {
                            (loan.repayments.length === 0) &&
                            <div className="p-2">
                                <Card.Header className="mb-1"><u>Application Cost</u></Card.Header>
                                <div className="d-flex justify-content-between mb-2">
                                    <div>Insurance:</div>
                                    <div>{moneyFormatter(loanApplicationCost.insurance?.amount??0)}</div>
                                </div>
                                <div className="d-flex justify-content-between mb-2">
                                    <div>Application form:</div>
                                    <div>{moneyFormatter(loanApplicationCost.application?.amount??0)}</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="text-warning h5">Total:</div>
                                    <div className="h5 text-warning">{moneyFormatter(+loanApplicationCost.insurance?.amount+loanApplicationCost.application?.amount)}</div>
                                </div>
                            </div>
                        }

                                <Card.Footer className="d-flex justify-content-between bg-info text-white">
                                    <div className="h4">Grand total:</div>
                                    <div className="h3">{moneyFormatter(formData.amount)}</div>
                                </Card.Footer>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    pageIsBusy?
                    <CustomSpinner />:
                    <Button 
                    onClick={()=>{
                        setPageIsBusy(true)
                        const minimumPayment = Math.ceil(instalmnents > balance? 
                            (+balance+(+loanApplicationCost.application.amount+loanApplicationCost.insurance.amount)): 
                            (+instalmnents+(+loanApplicationCost.application.amount+loanApplicationCost.insurance.amount)))

                        if(formData?.source===REPAYMENT_SOURCE.TRANSFER){
                            // pay with paystack
                            initializePayment(()=>{
                                saveLoanRepayment(loan, formData!, onHide,  minimumPayment, loanApplicationCost, currentUser! )
                            }, ()=>{
                                setPageIsBusy(false)
                                toast("Unsuccessful payment", {type: 'error'})
                            })
                            // save form data
                        }else{
                            saveLoanRepayment(loan, formData!, onHide,  minimumPayment, loanApplicationCost, currentUser! )
                        }
                    }}
                    disabled={formData?.amount! <= 0 || formData?.transactionRef===''} 
                    variant="success" 
                    className="btn-sm"> <FontAwesomeIcon icon={faCreditCard} className="me-2" /> Pay
                </Button>
                }
            </Modal.Footer>
            </Modal>
    )
}