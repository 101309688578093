import { ChangeEvent, Dispatch, SetStateAction, useCallback } from "react";
import { Editor, UserData } from "../../dataTypes/user.types";
import {toast} from 'react-toastify'
import { Users } from "../../models/Users";
import { Balances, CreditorDebtorData, FinanceData, LOAN_APPROVERS, LOAN_BASIS, Loan, 
    LoanApplicationCost, LoanConfiguration, LoanPlans, REPAYMENT_SOURCE, WithdrawalData } from "../../dataTypes/financials";
import { ADMIN_ROLE, LOANSTATUS, MEMBERSHIP_STATUS, INFLOW_PURPOSE, OUTFLOW_PURPOSE } from "../../constants/UTILITY.constants";
import { LoanConfigurations } from "../../models/LoanConfigurations.model";
import { Loans } from "../../models/Loans.model";
import { WHEREOPERATOR } from "../../dataTypes/firebasequery.types";
import { moneyFormatter } from "../../utility/helpers";
import { Creditors } from "../../models/Creditors.model";
import { Debtors } from "../../models/Debtors.model";
import { Dividends } from "../../models/Dividends.model";
import { Receipts } from "../../models/Receipts.model";
import { Savings } from "../../models/Savings.model";
import { Shares } from "../../models/Shares.model";
import { Withdrawals } from "../../models/Withdrawals.model";
import { Payments } from "../../models/Payments.model";
import { LoanApplications } from "../../models/LoanApplication.model";
import { LoanInsurances } from "../../models/LoanInsurance.model";
import { OtherConfig } from "../../models/OtherConfig.model";
import { Trustees } from "../../constants/AdminDefinitions.constant";

/**
 * update member's biodata
 * @param param0 
 */
export const updateUserData = ({setPageIsBusy, formData}:{
    setPageIsBusy: Dispatch<SetStateAction<boolean>>,
    formData: UserData
})=>{
    // disable button
    setPageIsBusy(true)

    // set reference
    const id = formData.reference
    delete formData.reference

    // save to member's db
    const model = new Users()
    model.update(
        formData, 
        id!,
        ()=>{
            // add reference back to data
            formData.reference = id
            // display success message
            toast("user data updated successfully", {type: 'success'})
            setPageIsBusy(false)
        }, 
        (error)=> {
            formData.reference = id
            console.log("unable to update biodata: ", error)
            setPageIsBusy(false)
        },
    )
}

/**
 * LOAN APPLICATION FOR UPDATE
 * check to see if loan does not exist in 
 * current loan the member is servicing if category is selected
 * Add duration and meaure user capacity to pay loan.
 * Choose distinct guarantors (if required) and save loan and show notifications
 * @param e 
 * @param setForm 
 */
export const setLoanApplicationFormData = (
    e:ChangeEvent<HTMLInputElement | HTMLSelectElement>, 
    setForm: Dispatch<SetStateAction<Loan>>,
    existingLoans: Loan[],
    setError: Dispatch<SetStateAction<string|null>>,
    initialData: Loan,
    setLoanConfig: Dispatch<SetStateAction<LoanConfiguration | null>>,
    maxLoan: number,
    )=>{
    const value = e.target.value
    const label = e.target.name
    // update loan category
    if(label==='category'){
        saveLoanCategory(value, existingLoans, setError, setForm, initialData, setLoanConfig)
    }

    // update loan amount
    if(label==='capital'){
        setLoanCapital(value, maxLoan, setError, setForm)
    }

    // update loan duration/instalments
    if(label==='instalments'){
        saveDuration(value, setError, setForm)
    }

    
}


/**
 * Monitor the amount of loan is within range
 * @param capital 
 * @param maxLoan 
 * @param setError 
 */
export const capacityMonitor = (capital: number, maxLoan: number, setError: Dispatch<SetStateAction<string|null>>, 
    currentSavings: number, minSavings: number, currentError: string | null)=>{
    if((capital > maxLoan) && (maxLoan > 0)){
        setError("Maximum allowable loan is N"+moneyFormatter(Math.floor(maxLoan)))
    } else if(currentSavings < minSavings){
        setError("Minium savings must be: N"+moneyFormatter(Math.ceil(minSavings)))
    } else {
        setError(currentError)
    }
}

/**
 * Check if loan category exist and send error else
 * reset loan form to initial state everytime category changes
 * and update loan configurations with duration and rate of loan category
 * @param val 
 * @param existingLoans 
 * @param setError 
 * @param setFormData 
 * @param initialData 
 * @param setLoanConfig
 * 
 */
const saveLoanCategory = (
    val: string, 
    existingLoans: Loan[], 
    setError: Dispatch<SetStateAction<string | null>>, 
    setFormData: Dispatch<SetStateAction<Loan>>,
    initialData: Loan,
    setLoanConfig: Dispatch<SetStateAction<LoanConfiguration | null>>,
    
    )=>{
    // const initialData = setLoanApplicationDefault(user, currentUser?.reference!)
    if(val!=='default'){
        const data: LoanConfiguration = JSON.parse(val)
        const loanExists = existingLoans.find(loan=> loan.category===data.title)
        if(loanExists){
            setError('member currently has unliquidated loan in this category')
        }else{
            // reset form data
            setError(null)
            initialData.category = data.title
            setFormData(initialData)
            setLoanConfig(data)
        }
    }else{
        setError("Kindly select a valid loan category")
        setFormData(pre=>({...pre, category: ''}))
    }
}

/**
 * Choose loan duration or number of installments to determine
 * loan interest rate
 * @param duration 
 * @param setError 
 * @param setFormData 
 */
const saveDuration = (
    duration: string, 
    setError: Dispatch<SetStateAction<string | null>>,
    setFormData: Dispatch<SetStateAction<Loan>>,
    )=>{
    // const duration = e.target.value
    if(duration === 'default'){
        setError("Enter a valid loan duration")
    }else{
        setError(null)
        const loanPlan:LoanPlans = JSON.parse(duration)
        setFormData(prev=>({...prev, instalments: loanPlan.instalments, interestRate: loanPlan.rate}))
    }
}

/**
 * Measure if loan applied for is within the required limit
 * then set amount as loan capital
 * @param value 
 * @param maxLoan 
 * @param setError 
 * @param setFormData 
 */
const setLoanCapital = (
    value: string, 
    maxLoan: number,
    setError: Dispatch<SetStateAction<string | null>>,
    setFormData: Dispatch<SetStateAction<Loan>>,
    )=>{
    const loanCapital = parseInt(value) ?? 0
    if(loanCapital <= 0){
      setError("Enter a valid loan amount")
    } else if(loanCapital > maxLoan && maxLoan > 0){
      setError("Your maximum loan capacity amount is N"+ moneyFormatter(Math.floor(maxLoan)))
    } else{
        setError(null)
        const cap = isNaN(loanCapital)?0:loanCapital
        setFormData(prev=>({...prev, capital:cap}))
    }
}

/**
 * Review member savings upward or downward during loan application process
 * @param e 
 * @param setError 
 * @param setCurrentSavings 
 * @param user 
 */
export const updateSavings = (
    e: ChangeEvent<HTMLInputElement>,
    setError: Dispatch<SetStateAction<string | null>>,
    setCurrentSavings: Dispatch<SetStateAction<number>>,
    user: UserData
    )=>{
    const savings = e.target.value
    if(savings){
        if(parseInt(savings) >= (user.employmentDetail.currentSalary * 0.035)){
            setError(null)
        }else{
            // display error
            setError(`${user.firstName}'s monthly savings cannot be less than N${Math.ceil(user.employmentDetail.currentSalary * 0.035)}`)
        }
        // update savings
        setCurrentSavings(parseInt(savings))
    } else {
        setError("Please enter a valid amount")
    }
}

/************************************************************************************************************************************* */
//                       END OF LOAN APPLICATION FORM DATA
/************************************************************************************************************************************** */

export const setLoanApplicationDefault = (user: UserData, creator: Editor): Loan => {
    const d = new Date()
    const loanData: Loan = {
        applicantName: user.firstName+' '+user.lastName,
        applicantRef: user.reference!,
        category: '',
        capital: 0,
        instalments: 0,
        collectionDate: d.getTime(),
        interestRate: 0,
        guarantors: [],
        status: LOANSTATUS.UNGUARANTEED,
        applicationDate: d.getTime(),
        startDate: d.getTime(),
        expectedEndDate: d.getTime(),
        day: d.getDate(),
        month: d.getMonth()+1,
        year: d.getFullYear(),
        guarantorList: [],
        createdBy: creator,
        approvals: new Map(),
        guaranteeRequest: 0,
        repayments: [],
        fines: []
    }

    return loanData
}

export const withdrawMembership = (formData: WithdrawalData, member: UserData , balance: number, totalLoans: number, guaLiablity:number, isExpress:boolean, admin: UserData)=>{
    const {savings, shares, unclaimedDividend} = formData.credits
    const totalCredits = +savings.amount+shares.amount+unclaimedDividend.amount
    const d = new Date()
    // write all credit balances down (savings, shares and unclaimed dividend)
    const creditModels = [new Savings(), new Shares(), new Dividends()]
    creditModels.forEach(model=>{
        model.update({amount: 0}, member.reference!, ()=>{
        }, (_)=>{})
    })
    
    // write all loans to withdrawal account and set status to completed ( in repayments) 
    // then set maturity date to now if express else a month's time 
    const loanModel = new Loans()
    const receipt:FinanceData = {
        date: d.getTime(),
        description: "Liquidation of loan account to withdrawal",
        transactionRef: `REF_${member.reference!}_WITHDRAWAL`,
        amount: 0,
        purpose: INFLOW_PURPOSE.WITHDRAWAL_SETTLEMENT,
        memberReference: member.reference!,
        source: REPAYMENT_SOURCE.WITHDRAWAL,
        day: d.getDate(),
        year: d.getFullYear(),
        month: d.getMonth()+1,
        processor: `${admin.firstName} ${admin.lastName}`,
        processorRef: admin.reference!,
        memberName: `${member.firstName} ${member.middleName} ${member.lastName}`
    }

    formData.debits.forEach(loan=>{
        const loanId = loan.reference!
        const exemptedStatus = [LOANSTATUS.APPROVED, LOANSTATUS.PARTIALLY_GUARANTEED, LOANSTATUS.UNGUARANTEED]
        if(exemptedStatus.includes(loan.status)){
            // cancel loan
            loan.status = LOANSTATUS.CANCELLED
        }else{
            // get loan balance
            const capInt = (1 + (loan.interestRate/100)) * loan.capital
            const repayments = loan.repayments.length > 0?loan.repayments.reduce((prev, curr)=>({...curr, amount: curr.amount+prev.amount})).amount:0
            const balance = +capInt - repayments
            // balance of loan with repayment balance
            receipt.amount = balance
            delete loan.reference
            loan.repayments.push(receipt)
            loan.status = LOANSTATUS.COMPLETED
        }
        // update loan repayments
        loanModel.update(loan, loanId, ()=>{}, (_)=>{})
    })
    
    // save surplus/deficit on withdrawals to Creditors/Debtors account (excluding amount on guaranteed loans)
    // member is a creditor (all loans recovered)
    const surDefModel = balance > 0 ? new Creditors(): new Debtors()
    const recovered = balance > 0 ? (+totalLoans+guaLiablity+((savings.amount??0)*(isExpress?0.06:0.035))): totalCredits
    
    const dat:CreditorDebtorData = {
        amount: balance > 0 ? balance: (-1 * balance),
        uid: member.reference!,
        paid: false
    }
    
    // save to withdrawal
    const withModel = new Withdrawals()
    withModel.save(formData, {callBack() { }, errorHander(_) { },})
    // which is to be streamed when viewing debtors or creditors
    // write amount recovered from withdrawals to Receipt account
    receipt.amount = recovered
    const receiptModel = new Receipts()
    receiptModel.save(receipt, {callBack() {
        // save creditor or debtor
        surDefModel.save(dat, {callBack() {}, errorHander(_) {},})
        // update user status
        const userModel = new Users()
        userModel.update({status: isExpress? MEMBERSHIP_STATUS.WITHDRAWN: 
            MEMBERSHIP_STATUS.PENDING_WITHDRAWAL}, member.reference!, ()=>{}, (_)=>{})
    }, errorHander(_) { },})
}

/**
 * Get the maximum amount of loan allowable to user
 * @param loanBasis 
 * @param user 
 * @param creditBalances 
 * @param monthlyBalance 
 * @returns 
 */
export const getLoanBasisAmount = (loanBasis: LOAN_BASIS, user: UserData, creditBalances: Balances, loanAbility: number): number => {
    let amount = 0
    switch (loanBasis) {
        // based on monthly balances after all deductions
        case LOAN_BASIS.MONTHLY_BALANCE:
            amount = loanAbility
            break;
        case LOAN_BASIS.SALARY:
            amount = user.employmentDetail.currentSalary
            break;
        case LOAN_BASIS.SHARE_CAPITAL:
            amount = creditBalances.shares.amount
            break;
        case LOAN_BASIS.MONTHLY_SAVINGS:
            amount = user.KISCMSInfo.monthlySavings
            break;
        default:
            amount = creditBalances.savings.amount
            break;
    }

    return amount
} 

export const computeLoanAbility = (user: UserData, userLoans: Loan[], currentSavings?: number): number =>{
    // calclulate salary after tax, deductions and take home allowance
    const allowableSalary = user.employmentDetail.currentSalary * 0.9
    const minimumSavings = user.employmentDetail.currentSalary * 0.035
    const salaryAdvance = user.employmentDetail.currentSalary/6
        // salary after tax, salary advance and savings
    const loanSalary = allowableSalary - (
        +salaryAdvance + (
            currentSavings?(currentSavings < minimumSavings? minimumSavings: currentSavings):minimumSavings
        )
    )

    // sum unliquidated loans
    let sumRepayments = 0
    userLoans.forEach(loan=>{
        const loanSum = (loan.capital * (1 + (loan.interestRate/100)))
        const monthlyInstalments = loanSum/loan.instalments
        const totalRepayments = loan.repayments.length > 0 ?
            loan.repayments.reduce((previous, current)=>({...current, amount: +current.amount + previous.amount})).amount:0
        const loanBalance = +loanSum - totalRepayments
        const monthlyLiability = loanBalance > monthlyInstalments? monthlyInstalments: loanBalance
        sumRepayments+=monthlyLiability
    })

    return (+loanSalary - sumRepayments)
}


export const useGetLoanCategories = (loanCategories: LoanConfiguration[], setLoanCategories: Dispatch<SetStateAction<LoanConfiguration[]>>)=>{
    return useCallback(()=>{
        if(loanCategories.length <= 0){
          const configModel = new  LoanConfigurations()
          configModel.findAll((data)=>{
            setLoanCategories(data as LoanConfiguration[])
          }, (e)=>{
            console.log("error finding loan categories: ", e)
          })
        }
      }, [setLoanCategories, loanCategories])
}

/**
 * fetch all existing loans
 * @param setUserLoans 
 * @returns 
 */
export const useGetExistingLoans = (setUserLoans: Dispatch<SetStateAction<Loan[]>>, userRef: string)=>{
    return useCallback(()=>{
        const loanModel = new Loans()
        loanModel.findWhere({
          wh: [
            {
              key: 'status',
              operator: WHEREOPERATOR.NOT_IN,
              value: [LOANSTATUS.COMPLETED, LOANSTATUS.CANCELLED]
            },
            {
                key: 'applicantRef',
                operator: WHEREOPERATOR.EQUAL_TO,
                value: userRef
              }
          ], 
          callBack: (data)=>{
            setUserLoans(data as Loan[])
          },
          errorHandler(error) {
              console.log('error fetching user exisiting loans: ', error)
          },
        });
    }, [setUserLoans, userRef])
}

/**
 * Get Insurance cost set in configuration
 * @param setInsurance 
 * @returns 
 */
export const useGetInsuranceCost = (setInsurance: Dispatch<SetStateAction<number>> )=>{
    return useCallback(()=>{
        const configModel = new OtherConfig()
        configModel.findAll((data)=>{
            if(data){
            setInsurance(data[0].insuranceCost)
            }
        }, (error)=>{console.log('unable to find other config: ', error)})        
    }, [setInsurance])
}

/**
 * Set loan basis amount
 * @param loanConfig 
 * @param applicant 
 * @param userLoans 
 * @param setLoanBasis 
 * @param creditBalances 
 * @returns 
 */
export const useSetLoanBasis = (loanConfig: LoanConfiguration, applicant: UserData, userLoans: Loan[], setLoanBasis: Dispatch<SetStateAction<number>>, creditBalances: Balances)=>{
    return useCallback(()=>{
        if(loanConfig){
            const basis = getLoanBasisAmount(loanConfig?.basis!, applicant, creditBalances, 
                computeLoanAbility(applicant, userLoans))
            setLoanBasis(basis)
        }
    }, [loanConfig])
}

/**
 * Measure member's loan capacity
 * @param formData 
 * @param applicant 
 * @param userLoans 
 * @param currentSavings 
 * @param setLoanCapacity 
 * @param loanConfig 
 * @param currentBasis 
 * @returns 
 */
export const useCapacityMeasurement = (formData: Loan, applicant: UserData, userLoans: Loan[], 
    currentSavings: number, setHasCapacity: Dispatch<SetStateAction<boolean>>, 
    loanConfig: LoanConfiguration, currentBasis: number = 0, setMaxLoan: Dispatch<SetStateAction<number>>)=>{
    const {capital, instalments, interestRate} = formData
    return useCallback(()=>{
        const monthlyAbility = computeLoanAbility(applicant, userLoans, currentSavings)
        let loanCapacity = 0
        const aggregateAbility = (monthlyAbility * instalments)/(1 + (interestRate/100))
        let capacity = aggregateAbility

        if(loanConfig){
            loanCapacity = currentBasis * loanConfig.multiplier
        }

        if(capital > 0 && instalments > 0 && interestRate >= 0 ){
            // set loan capacity
            capacity = aggregateAbility > loanCapacity ? loanCapacity : aggregateAbility
            setHasCapacity(capacity >= capital)
        }
        setMaxLoan(capacity)
    }, [capital, instalments, interestRate, formData, currentSavings, currentBasis, setMaxLoan, loanConfig])
}

/**
 * check if current user can approve loan
 * @param currentUser 
 * @param loan 
 * @returns 
 */
export const canApproveLoan = (currentUser: UserData, loan: Loan )=>{

    const firstApprovers = [ADMIN_ROLE.GENERAL_SECRETARY, ADMIN_ROLE.TREASURER, ADMIN_ROLE.SUPER]
    // must be a trustee
    // const isTrustee = currentUser?.adminRole && trustees.includes(currentUser?.adminRole)
    // remove if user already approved and show president if Treasurer || Gen Sec Approved
    let canApprove = false
    let hasApproved = false
    if(Trustees.includes(currentUser.adminRole)){
        if(currentUser?.adminRole === ADMIN_ROLE.PRESIDENT || currentUser?.adminRole){
            canApprove = loan.approvals.get(LOAN_APPROVERS.TREASURER)!==undefined || loan.approvals.get(LOAN_APPROVERS.GEN_SEC)!==undefined
            hasApproved = loan.approvals.get(LOAN_APPROVERS.PRESIDENT)!==undefined
        }else if(firstApprovers.includes(currentUser?.adminRole)){
            canApprove = loan.approvals.get(LOAN_APPROVERS.TREASURER)===undefined && loan.approvals.get(LOAN_APPROVERS.GEN_SEC)===undefined
            hasApproved = loan.approvals.get(LOAN_APPROVERS.TREASURER)!==undefined || loan.approvals.get(LOAN_APPROVERS.GEN_SEC)!==undefined
        }
    }
    return (canApprove && !hasApproved) 
}

/**
 * approve loan
 * @param currentUser 
 * @param loan 
 * @param setPageIsBusy 
 */
export const  approveLoan = (currentUser: UserData, loan: Loan, setPageIsBusy: Dispatch<SetStateAction<boolean>>)=>{
    setPageIsBusy(true)
    const {firstName, lastName, middleName, reference} = currentUser!
    // const {approvals} = loan
    const currentApprover = currentUser?.adminRole===ADMIN_ROLE.PRESIDENT?LOAN_APPROVERS.PRESIDENT:
    (currentUser?.adminRole===ADMIN_ROLE.TREASURER?LOAN_APPROVERS.TREASURER: LOAN_APPROVERS.GEN_SEC)
    const editor: Editor = {
        name: `${firstName} ${middleName} ${lastName}`,
        date: Date.now(),
        reference: reference!
    }
    loan.approvals.set(currentApprover, editor)
    // change loan status if approver is president
    if(currentApprover===LOAN_APPROVERS.PRESIDENT){
        loan.status = LOANSTATUS.APPROVED
    }
    const loanModel = new Loans()
    const id = loan.reference
    delete loan.reference
    // update current approval
    loanModel.update({...loan, approvals: Object.fromEntries(loan.approvals)}, id!, ()=>{
        setPageIsBusy(false)
        toast(`Successfuly approval by ${firstName} ${lastName}!`, {type: 'success'})
    }, (_)=>{
        setPageIsBusy(false)
        toast("Poor internet connection!", {type: 'info'})
    })
}

/**
 * save loan issance to member
 * @param loan 
 * @param formData 
 */
export const saveLoanPayment = (loan: Loan, formData: FinanceData, onHide: ()=>void)=>{
    const pyModel = new Payments()
    const loanModel = new Loans()
    loan.status = LOANSTATUS.ACTIVE
    loanModel.update({...loan, approvals: Object.fromEntries(loan.approvals)}, loan.reference!, ()=>{
        pyModel.save(formData!, {
            callBack() {
                toast("Loan issuance successful!", {type: 'success'})
                onHide()
            },
            errorHander(_) {
                toast("unable to update loan transaction. Check connections!", {type: 'error'})
                onHide()
            },
        })
    }, (_)=>{
        toast("unable to update loan transaction. Check connections!", {type: 'error'})
    })
}

/**
 * Store loan repayments
 * @param loan 
 * @param formData 
 * @param onHide 
 * @param minimumPayment 
 */
export const saveLoanRepayment = async (loan: Loan, formData: FinanceData, onHide: ()=>void, minimumPayment: number, otherCost: LoanApplicationCost, admin: UserData)=>{
   
    const registrationCost = Math.floor((otherCost.insurance.amount+otherCost.application.amount))
    let amount = formData?.amount! - registrationCost
    // check over payment
    const fine = loan.fines.length > 0 ? loan.fines.reduce((pre, curr)=>({...curr, cost: +curr.cost+pre.cost})).cost: 0
    const totalLiability = loan.capital * (1+ (loan.interestRate/100))+fine
    const overpayment = checkOverPayment(loan.repayments, amount, totalLiability)
    // check under payment
    const underPayment = checkUnderpayment(formData.source, formData.amount, minimumPayment)
    // treat as over paid loan and close loan
    if(overpayment > 0){
        formData.amount = amount - overpayment
    } else if (underPayment > 0) {
        formData.amount = amount
        // compute fine
        loan.fines.push({
            cost: Math.floor(underPayment * 0.5 * (loan.interestRate/100)),
            title: "Loan under payment"
        })
        // debit fine to Loan
    }
    // save registration cost and save insurance cost
    saveLoanAdminCharge(otherCost, loan.reference!)
    // save income from over payment and increase member savings
    increaseSavings(loan, overpayment, formData, admin)
    // compute instalment interest to be shared by guarantors
    const instalmentInterest = Math.floor(formData.amount * (loan.interestRate/100))
    // save to Guarantors account
    await saveGuarantoBonus(
        loan, 
        instalmentInterest, 
        formData, admin)

    // save loan repayment
    saveLoanReceipt(loan, formData, onHide, fine)
}

/**
 * Check if member has paid more than amount required
 * @param repayments 
 * @param currentPayment 
 * @param liability 
 * @returns 
 */
const checkOverPayment = (repayments: FinanceData[], currentPayment: number, liability: number): number => {
    let overPayment = 0
    const allPayments = repayments.length > 0 ?repayments.reduce((prev, curr)=>({...curr, amount: +prev.amount+curr.amount})).amount + currentPayment : 0 + currentPayment
    overPayment = allPayments - liability
    return Math.floor(overPayment)
}

/**
 * 
 * @param paymentSour 
 * @param currentPayment 
 * @param minimumPayment 
 * @returns 
 */
const checkUnderpayment = (paymentSour: REPAYMENT_SOURCE, currentPayment: number, minimumPayment: number): number => {
    let underPayment = 0
    if(paymentSour===REPAYMENT_SOURCE.SOURCE){
        // if amount is less than minimum amount, remove from savings
        if(currentPayment < minimumPayment){
            // remove the amount from member savings
            underPayment = +minimumPayment - currentPayment
        }
    }
    return Math.floor(underPayment)
}

/**
 * save 10% on loan interest to guaranto savings
 * @param loan 
 * @param guarantorInterest 
 * @param formData 
 * @param admin 
 */
const saveGuarantoBonus = async (loan: Loan, guarantorInterest: number, formData: FinanceData, admin: UserData)=>{
    const d = new Date()
    const savModel = new Savings()
    if(loan.guarantorList.length > 0){
        // guarantor bonus is 10% of repayment less other cost of loan
        // add to payments
        const payModel = new Payments()
        const payData: FinanceData = {
            date: d.getTime(),
            description: `Guarantor interest on ${loan.category}`,
            transactionRef: `REF_${loan.reference}_GUARANTOR_INTEREST`,
            amount: guarantorInterest,
            purpose: OUTFLOW_PURPOSE.GUARANTOR_BONUS,
            memberName: loan.guarantorList.toString(),
            memberReference: loan.guarantorList.toString(),
            day: d.getDate(),
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            source: formData.source,
            processor: `${admin.firstName} ${admin.middleName} ${admin.lastName}`,
            processorRef: admin.reference!,
        }

        payModel.save(payData, {callBack: ()=>{}, errorHander: ()=>{}})
        // reduce guarantors' liabilities
        // calculate total sum guaranteed
        const totalRequest = loan.guarantors.reduce((prev, curr)=>
        ({...curr, sumRequest: +curr.sumRequest+prev.sumRequest})).sumRequest

        for(let indx=0; indx < loan.guarantors.length; indx++){
            const {reference, sumRequest, requestOffset} = loan.guarantors[indx]
            // get proportion of guarantor
            const proportion = Math.floor((sumRequest/totalRequest) * guarantorInterest)
            if(sumRequest > requestOffset){
                // reduce guarantor's liability
                loan.guarantors[indx].requestOffset+=Math.floor((sumRequest/totalRequest) * formData.amount)
            }

            // increase guarantors' savings by guarantor credit
            savModel.incrementDecrement({
                dbReference: reference,
                key: 'amount',
                isIncrement: true,
                incrementalValue: proportion
            }, (error)=>{console.log("unable to increase guarantor savings: ", error)})
        }
    }
}

/**
 * Save loan receipt
 * @param loan 
 * @param formData 
 * @param savingCharge 
 * @param sourcedFromSavings 
 * @param admin 
 * @param onHide 
 * @param otherCost 
 */
const saveLoanReceipt = (loan: Loan, formData: FinanceData, onHide:()=>void, fines: number)=>{
    const receiptModel = new Receipts()
    const loanModel = new Loans()
    receiptModel.save(formData, {callBack(id) {
        const loanId = loan.reference
        delete loan.reference
        // add repayment to loan
        formData.reference = id
        loan.repayments.push(formData)
        // check if the loan is fully paid
        const expectedAmount = Math.ceil(loan.capital * (1+(loan.interestRate/100))) + fines
        const totalReceipts = Math.floor(loan.repayments.length > 0 ?loan.repayments.reduce((pre, curr)=>({...curr, amount: +curr.amount+pre.amount})).amount : 0)
        
        if(totalReceipts >= expectedAmount){
            loan.status = LOANSTATUS.COMPLETED
        }

        loanModel.update({...loan, approvals: Object.fromEntries(loan.approvals)}, loanId!, ()=>{
            onHide()
        }, ()=>{})

    }, errorHander(_) {
        toast("unable to save loan receipt", {type: 'error'})
    },})
}

/**
 * save loan administration cost
 * @param otherCost 
 * @param loanId 
 */
const saveLoanAdminCharge = (otherCost: LoanApplicationCost, loanId: string)=>{
    // save application cost
    if(otherCost.application.amount >0 ){
        const appModel = new LoanApplications()
        // save application cost
        appModel.save(otherCost.application, {
            id: loanId,
            callBack() {},
            errorHander() {},
        })
        
    } 
    
    if (otherCost.insurance.amount > 0){
        const insModel = new LoanInsurances()
        // save insurance cost
        insModel.save(otherCost.insurance, {
            id: loanId,
            callBack() {},
            errorHander() {},
        })
    }
}

/**
 * Increase member's savings from over payment
 * @param loan 
 * @param amount 
 * @param formData 
 * @param admin 
 */
const increaseSavings = (loan: Loan, amount: number, formData: FinanceData, admin: UserData)=>{
    if(amount > 0){
        const savModel = new Savings()
        const receiptModel = new Receipts()
        // const loanModel = new Loans()
        const d = new Date()
        savModel.incrementDecrement({
            dbReference: loan.applicantRef,
            key: 'amount',
            incrementalValue: amount
        }, ()=>{
            // extra charges from user savings account
            const savingsCharges: FinanceData = {
                date: d.getTime(),
                description: `Increase member savings from ${loan.category}`,
                transactionRef: `REF_${d.getTime()}_REPAYMENT_savings`,
                amount: amount,
                purpose: INFLOW_PURPOSE.SAVINGS,
                memberName: loan.applicantName,
                memberReference: loan.applicantRef,
                day: d.getDate(),
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                source: formData.source,
                processor: `${admin.firstName} ${admin.middleName} ${admin.lastName}`,
                processorRef: admin.reference!,
            }

            // increase income from savings
            receiptModel.save(savingsCharges, {callBack(){}, errorHander() {},})
        }, ()=>{})
    }
}

