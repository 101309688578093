import { memo, useContext } from "react"
import { Link } from "react-router-dom"
import { userContext } from "../../providers/user.provider"
import { logout } from "../../screens/methods/registration"

const ProfilePicture = ()=>{

    const {currentUser, setCurrentUser} = useContext(userContext)

    return (
        <li className="nav-item dropdown pe-3">

        <span className="nav-link nav-profile d-flex align-items-center pe-0"  data-bs-toggle="dropdown">
            <img src={currentUser?.photoUrl} alt="Profile" className="rounded-circle" />
        </span>

        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
            <li className="dropdown-header">
            </li>
            <li>
                <hr className="dropdown-divider" />
            </li>

            <li>
                <Link to="/profile" className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                </Link>
            </li>
            <li>
                <hr className="dropdown-divider" />
            </li>

            <li>
                <Link to="/profile/setting" className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-gear"></i>
                    <span>Account Settings</span>
                </Link>
            </li>
            <li>
                <hr className="dropdown-divider" />
            </li>

            <li>
                <Link to="/faq" className="dropdown-item d-flex align-items-center" >
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                </Link>
            </li>
            <li>
                <hr className="dropdown-divider" />
            </li>

            <li onClick={()=>{
                logout(setCurrentUser!)
            }} className="dropdown-item d-flex align-items-center">
               
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
            </li>

        </ul>
        </li>
    )
}

export default memo(ProfilePicture)