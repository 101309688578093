import { faCamera, faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactNode, RefObject, useContext, useEffect, useRef, useState } from "react"
import { Button, Form, FormGroup, FormLabel, FormSelect, FormText } from "react-bootstrap"
import BreadCrumbComponent from "../components/main/body/pageTitle/BreadCrumb.component"
import { CustomPhoneInput } from "../components/utility/CustomForm.component"
import { generalMessage } from "../dataTypes/notificationMessages.types"
import { KISCMSInfo, UserData, } from "../dataTypes/user.types"
import { toast } from 'react-toastify'
import { checkCanSave, fetchBankList, monitorPasswordResetter, updateFormData } from "./methods/registration"
import { useLocation, useNavigate } from "react-router-dom"
import { ADMIN_ROLE, GENDER, intialFormData, MARITAL_STATUS } from "../constants/UTILITY.constants"
import { StatesAndLGAs, STATES_AND_LOCAL_GOVERNMENTS } from "../constants/STATES.LOCALGOVERNMENT.constant"
import { Users } from "../models/Users"
import { CustomCamera } from "../components/utility/Camera.component"
import { BankDetail } from "../dataTypes/customTypes/varTypes"
import CustomSpinner from "../components/utility/CustomSpinner.component"
import { PaymentModal } from "../components/registration/PaymentModal.component"
import { RegistrationPaymentItem } from "../dataTypes/financials"
import { userContext } from "../providers/user.provider"
import { StaffsAndTrustees } from "../constants/AdminDefinitions.constant"
import { getKISCMSNumber } from "../utility/helpers"
// import { FunctionReturn } from "../dataTypes/firebasequery.types"


const RegistrationForm = ()=>{

    const {currentUser} = useContext(userContext)

    const location = useLocation()
    const navigate = useNavigate()
    const [formData, setFormData] = useState<UserData>({...intialFormData, createdBy: currentUser?.reference!, KISCMSInfo: {KISCMSNumber: getKISCMSNumber()} as KISCMSInfo})
    const formRef:RefObject<HTMLFormElement> = useRef(null)
    const [validationError, setValidationError] = useState<generalMessage | null>(null)
    const [canSave, setCanSave] = useState<boolean>(false)
    const [bankList, setBankList] = useState<BankDetail[]>([])
    const [userPhoneForm, setUserPhoneForm] = useState<ReactNode[]>([
            <CustomPhoneInput key={0} 
                description="Primary phone number*" 
                valueSetter={(e)=>{updateFormData(e, setFormData, setValidationError, formData, 0)}}
                placeholder="Enter primary number"
             />
    ])
    const [lgasOfOrigin, setLgasOfOrigin] = useState<string[]|null>(null)
    const [lgasOfResidence, setLgasOfResidence] = useState<string[]|null>(null)
    const [showCamera, setShowCamera] = useState<boolean>(false)
    const [pageIsLoading, setPageIsLoading] = useState<boolean>(false)
    const [makePayment, setMakePayment] = useState<boolean>(false)
    const [paymentItem, setPaymentItem] = useState<RegistrationPaymentItem | null>(null)

    const stringified = JSON.stringify(formData)
    /**
     * set initial data for form
     */
    useEffect(()=>{
        // monitor password resetter
        if(currentUser){
            monitorPasswordResetter(currentUser!, navigate)
        }
        // only Trustees and admin can register members
        if(StaffsAndTrustees.includes(currentUser?.adminRole!) && currentUser?.isAdmin){
            if(location.state){
                setFormData(location.state)
            }
            // get all banks
            fetchBankList((data)=>{
                setBankList(data)
            }, ()=>{
                toast("Check internet connetion", {type: 'warning'})
            })
            // check if form can be saved
            setCanSave(checkCanSave(formData, validationError)) 
        } else {
            navigate('/')
        }
    }, [location.state,  validationError, formData, stringified])

    return (
        <section>
            {showCamera && <CustomCamera show={showCamera} handleClose={()=>setShowCamera(false)} setFormData={setFormData} />}
            <BreadCrumbComponent
                pageTitle="Member Registration"
                currentCrumb="Registration"
                parentPages={[
                    {
                        title: 'Home',
                        link: '/'
                    }
                ]}
                message={validationError}
            />

            

           
            <Form ref={formRef} onSubmit={(e)=>e.preventDefault()} className="mt-3">
                <div>
                    <h4 className="text-warning">Bio Data</h4>
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <div className="circular-profile">
                                        <img src={formData.photoUrl===''?'/assets/images/logo512.png': formData.photoUrl} alt="Profile" className="circular-profile-img"/>
                                    </div>
                                    <Button style={{
                                            position: 'relative',
                                            bottom: '30px'
                                        }} className="btn-sm" variant="success" onClick={()=>setShowCamera(true)} >
                                            <FontAwesomeIcon icon={faCamera} /> 
                                        </Button>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start mb-4 mt-4">
                                <Form.Check 
                                    type="switch"
                                    label={formData.isActive?"Deactivate":"Activate"}
                                    name="isActive"
                                    className="me-4"
                                    defaultChecked={formData?.isActive!}
                                    onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}}
                                />

                                <Form.Check 
                                    type="switch"
                                    label={formData.isAdmin?"Remove Admin":"Assign Admin"}
                                    name="isAdmin"
                                    className="me-4"
                                    defaultChecked={formData?.isAdmin}
                                    onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}}
                                />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-4">
                            <div className="row">
                                <FormGroup className="col-lg-6 mb-4">
                                    <FormLabel>
                                        First name:
                                    </FormLabel>
                                    <input placeholder="first name" 
                                        type="text" 
                                        onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}} 
                                        defaultValue={formData?.firstName!} 
                                        name="firstName"
                                        className="form-control"
                                    />
                                    
                                </FormGroup>

                                <FormGroup className="col-lg-6 mb-4">
                                    <FormLabel>
                                        Last name:
                                    </FormLabel>
                                    <input placeholder="last name" 
                                        type="text" 
                                        onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}} 
                                        defaultValue={formData?.lastName!} 
                                        name="lastName"
                                        className="form-control"
                                    />
                                    
                                </FormGroup>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <FormGroup className="mb-4">
                                        <FormLabel>
                                            Middle name (optional):
                                        </FormLabel>
                                        <input placeholder="middle name" 
                                            type="text" 
                                            onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}} 
                                            defaultValue={formData?.middleName!} 
                                            name="middleName"
                                            className="form-control"
                                        />
                                    </FormGroup>  
                                </div>
                                <div className="col-lg-6">
                                    <FormGroup className="mb-4">
                                        <FormLabel>
                                            Email:
                                        </FormLabel>
                                        <input placeholder="email address" 
                                            type="email" 
                                            onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}} 
                                            defaultValue={formData?.email!} 
                                            name="email"
                                            className="form-control"
                                        />
                                        
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 ">
                            <FormGroup className="mb-4 text-center radio-border">
                                <FormLabel>
                                    Marital status:
                                </FormLabel>
                                <div className="d-flex justify-content-between pe-5 ps-5">
                                    <Form.Check 
                                        name="maritalStatus"
                                        type="radio"
                                        label="Single"
                                        defaultChecked={formData?.maritalStatus===MARITAL_STATUS.SINGLE}
                                        onChange={(e)=>{
                                            updateFormData(e, setFormData, setValidationError, formData)
                                        }}
                                    />
                                    <Form.Check 
                                        type="radio"
                                        label="Maried"
                                        name="maritalStatus"
                                        defaultChecked={formData?.maritalStatus===MARITAL_STATUS.MARRIED}
                                        onChange={(e)=>{
                                            updateFormData(e, setFormData, setValidationError, formData)
                                        }}
                                    />
                                </div>
                                
                            </FormGroup>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <FormGroup className="mb-4 text-center radio-border">
                                <FormLabel>
                                    Gender:
                                </FormLabel>
                                <div className="d-flex justify-content-between pe-5 ps-5">
                                    <Form.Check 
                                        type="radio"
                                        label="Male"
                                        name="gender"
                                        defaultChecked={formData?.gender===GENDER.MALE}
                                        onChange={(e)=>{
                                            updateFormData(e, setFormData, setValidationError, formData)
                                        }}
                                    />
                                    <Form.Check 
                                        type="radio"
                                        label="Female"
                                        name="gender"
                                        defaultChecked={formData?.gender===GENDER.FEMALE}
                                        onChange={(e)=>{
                                            updateFormData(e, setFormData, setValidationError, formData)
                                            setCanSave(false)
                                        }}
                                    />
                                </div>
                            </FormGroup>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <FormGroup className="mb-4">
                                <FormLabel>
                                    Date of birth:
                                </FormLabel>
                                <input className="form-control" title="Member's date of birth" onChange={(e)=>{
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }} defaultValue={formData?.dateOfBirth!} name="dateOfBirth" type="date" />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-md-4">
                            <FormGroup className="mb-4 text-center radio-border">
                                <FormLabel>
                                    Nationality:
                                </FormLabel>
                                <div className="d-flex justify-content-between ps-4 pe-4">
                                    <Form.Check 
                                        type="radio"
                                        label="Nigerian"
                                        name="nationality"
                                        defaultChecked={formData?.nationality==="Nigerian"}
                                        onChange={(e)=>{
                                            updateFormData(e, setFormData, setValidationError, formData)
                                        }}
                                    />
                                    <Form.Check 
                                        type="radio"
                                        label="Foreigner"
                                        name="nationality"
                                        defaultChecked={formData?.nationality==="Foreigner"}
                                        onChange={(e)=>{
                                            updateFormData(e, setFormData, setValidationError, formData)
                                        }}
                                    />
                                </div>
                                
                            </FormGroup>
                        </div>

                        {
                            formData.nationality==='Nigerian' &&
                            <div className="col col-md-4">
                            <FormGroup className="mb-4">
                                <FormLabel>
                                    State of origin:
                                </FormLabel>
                                <FormSelect defaultValue={formData.stateOfOrigin} onChange={(e)=>{
                                    const states = JSON.parse(e.target.value) as StatesAndLGAs
                                    setLgasOfOrigin(states.lgas)
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }}  name="stateOfOrigin">
                                    <option value="default">state of origin</option>
                                {
                                    STATES_AND_LOCAL_GOVERNMENTS.map((stateLGA, index)=>{
                                        return <option key={index} value={JSON.stringify(stateLGA)}>{stateLGA.state}</option>
                                    })
                                }
                                </FormSelect>
                                
                            </FormGroup>
                        </div>

                        }
                       {
                        formData?.stateOfOrigin && 
                        <div className="col col-md-4">
                        <FormGroup className="mb-4">
                            <FormLabel>
                                LGA of origin:
                            </FormLabel>
                            <FormSelect onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }} value={formData?.lgaOfOrigin} name="lgaOfOrigin">
                            <option value="default">Choose LGA</option>
                            {
                                lgasOfOrigin?.map((lga, index)=>{
                                    return <option key={index} value={lga}>{lga}</option>
                                })
                            }
                        </FormSelect>
                            
                        </FormGroup>
                    </div>
                       }
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                    <div className="mb-2 d-flex align-items-start justify-content-start">
                                        <div className="me-3">
                                            <FormLabel>
                                                Phone Numbers
                                            </FormLabel>
                                        </div>
                            
                                        <FontAwesomeIcon onClick={
                                            ()=>{
                                                if(userPhoneForm.length < 3){
                                                    setUserPhoneForm((previous)=>{
                                                        return [...previous, <CustomPhoneInput placeholder="Additional number" key={previous.length}  
                                                        valueSetter={(e)=>{updateFormData(e, setFormData, setValidationError, formData, previous.length)}} />]
                                                    })
                                                }else{
                                                    toast("Maximum of 3 numbers reached!", {type: 'warning'})
                                                }
                                            }
                                        }  icon={faPlus} className="btn btn-success btn-sm me-1" />
                                        <FontAwesomeIcon onClick={
                                            ()=>{
                                                if(userPhoneForm.length > 1){
                                                    userPhoneForm.splice(userPhoneForm.length-1, 1)
                                                    setUserPhoneForm([...userPhoneForm])
                                                    setValidationError(null)
                                                }else{
                                                    toast("You must enter atleast one number", {type: 'warning'})
                                                }
                                            }
                                        }  icon={faTrash} className="btn btn-danger btn-sm" />
                                    </div>
                                {userPhoneForm}
                            </div>
                        </div>

                        {
                            formData?.isAdmin && 
                            <div className="col-lg-4">
                            <FormGroup className="mb-4">
                                <FormLabel>
                                    Admin Role:
                                </FormLabel>
                                    <FormSelect onChange={
                                        (e)=>{updateFormData(e, setFormData, setValidationError, formData)}
                                    } defaultValue={formData?.adminRole!} name="adminRole">
                                        <option value="default">Select role</option>
                                        <option value={ADMIN_ROLE.PRESIDENT}>President</option>
                                        <option value={ADMIN_ROLE.FINANCIAL_SECRETARY}>Financial Secretary</option>
                                        <option value={ADMIN_ROLE.GENERAL_SECRETARY}>General Secretary</option>
                                        <option value={ADMIN_ROLE.OFFICER}>Officer</option>
                                        <option value={ADMIN_ROLE.STAFF}>Staff</option>
                                        <option value={ADMIN_ROLE.TREASURER}>Treasurer</option>
                                    </FormSelect>
                                </FormGroup>  
                        </div>  
                        }

                    </div>
                </div>
                <hr />
                
                <div>
                    <h4 className="text-warning">Contact Address</h4>
                    <div className="row">
                        <FormGroup className="mb-4">
                            <FormLabel>
                                Street:
                            </FormLabel>
                            <input 
                            placeholder="e.g 3, Okikiola street, Aboru"
                            className="form-control"
                            onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }} defaultValue={formData?.contactAddress?.street!} name="constreet" />
                            
                        </FormGroup>
                    </div>

                    <div className="row">
                        <FormGroup className="col-lg-4 mb-4">
                            <FormLabel>
                                Nearest bus stop:
                            </FormLabel>
                            <input onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }} 
                            placeholder="Enter closest bus stop"
                            className="form-control"
                            defaultValue={formData?.contactAddress?.nearestBusStop!} name="connearestBusStop" />
                            
                        </FormGroup>

                        <FormGroup className="col-lg-4 col-md-6 mb-4">
                            <FormLabel>
                                State:
                            </FormLabel>
                            <FormSelect onChange={(e)=>{
                                    const states = JSON.parse(e.target.value) as StatesAndLGAs
                                    setLgasOfResidence(states.lgas)
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }} defaultValue={formData?.contactAddress?.state!} name="stateOfResidence">
                                <option value="default">Select State of residence</option>
                                {
                                     STATES_AND_LOCAL_GOVERNMENTS.map((stateLGA, index)=>{
                                        return <option key={index} value={JSON.stringify(stateLGA)}>{stateLGA.state}</option>
                                    })
                                }
                            </FormSelect>
                        </FormGroup>  
                        {
                            formData?.contactAddress?.state && 
                            <FormGroup className="col-lg-4 col-md-6 mb-4">
                                <FormLabel>
                                    LGA:
                                </FormLabel>
                                <FormSelect onChange={(e)=>{
                                        updateFormData(e, setFormData, setValidationError, formData)
                                    }} value={formData?.contactAddress?.localGovernmentArea!} name="lgaOfResidence">
                                    <option value="default">Select LGA of residence</option>
                                    {
                                        lgasOfResidence?.map((lga, index)=>{
                                            return <option key={index} value={lga}>{lga}</option>
                                        })
                                    }
                                </FormSelect>
                            </FormGroup> 
                        }               
                    </div>
                </div>
                <hr />

                <div>
                    <h4 className="text-warning">Next of kin</h4>
                    <div className="row">
                        <FormGroup className="col-lg-6 col-md-6 mb-4">
                            <FormLabel>
                                First name:
                            </FormLabel>
                            <input placeholder="First name of Next of Kin" 
                                    onChange={(e)=>{
                                        updateFormData(e, setFormData, setValidationError, formData)
                                    }} className="form-control" defaultValue={formData?.nextOfKin?.firstName!} name="nokfirstName" />
                            
                        </FormGroup>

                        <FormGroup className="col-lg-6 col-md-6 mb-4">
                            <FormLabel>
                                Last name:
                            </FormLabel>
                                <input onChange={(e)=>{
                                        updateFormData(e, setFormData, setValidationError, formData)
                                    }} placeholder="Surname of next of kin" className="form-control" 
                                    defaultValue={formData?.nextOfKin?.lastName!} name="noklastName" 
                                />
                            
                        </FormGroup>  

                        <FormGroup className="col-lg-6 col-md-6 mb-4">
                            <FormLabel>
                                Middle name (optional):
                            </FormLabel>
                            <input placeholder="Middle name of next of kin" className="form-control" 
                                onChange={(e)=>{
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }} defaultValue={formData?.nextOfKin?.middleName!} name="nokmiddleName" />
                            
                        </FormGroup> 

                        <FormGroup className="col-lg-6 col-md-6 mb-4">
                            <FormLabel>
                                Email address:
                            </FormLabel>
                            <input type="email" placeholder="Email of next of kin" className="form-control" 
                                onChange={(e)=>{
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }} defaultValue={formData?.nextOfKin?.email!} name="nokEmailAddress" />
                            
                        </FormGroup>

                        <FormGroup className="col-lg-6 col-md-6 mb-4">
                            <FormLabel>
                                Primary phone number:
                            </FormLabel>
                            <input type="tel" placeholder="Phone number of Next of kin" 
                                    onChange={(e)=>{
                                        updateFormData(e, setFormData, setValidationError, formData)
                                    }} className="form-control" defaultValue={formData?.nextOfKin?.phoneNumber!} name="nokPhone" />
                            
                        </FormGroup> 

                        <FormGroup className="col-lg-6 col-md-6 mb-4">
                            <FormLabel>
                                Relationship:
                            </FormLabel>
                            <FormSelect onChange={(e)=>{
                                        updateFormData(e, setFormData, setValidationError, formData)
                                    }} defaultValue={formData?.nextOfKin?.relationship!} name="nokRelationship">
                                <option className="default">Select Relationship</option>
                                <option value="parent">Parent</option>
                                <option value="spouse">Spouse</option>
                                <option value="uncle">Uncle</option>
                                <option value="aunt">Aunt</option>
                                <option value="nephew">Nephew</option>
                                <option value="niece">Niece</option>
                                <option value="cousin">Cousin</option>
                                <option value="sibling">Sibling</option>
                                <option value="child">Child</option>
                            </FormSelect>
                        </FormGroup>                
                    </div>
                </div>
                <hr />
                <div>
                    <h4 className="text-warning">Work information</h4>
                    <div className="row">
                        <FormGroup className="col-lg-3 col-md-6 mb-4">
                            <FormLabel>
                                Business unit:
                            </FormLabel>
                            <FormSelect defaultValue={formData?.employmentDetail?.businessUnit!} onChange={(e)=>{
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }}  name="businessUnit">
                                <option value="default">Select Business unit</option>
                                <option value="KTC">KFJ</option>
                                <option value="KURAMO">KURAMO</option>
                            </FormSelect>
                        </FormGroup>  

                        <FormGroup className="col-lg-3 col-md-6 mb-4">
                            <FormLabel>
                                Department:
                            </FormLabel>
                            <input defaultValue={formData?.employmentDetail?.department!} 
                                    placeholder="Enter department" 
                                    className="form-control" onChange={(e)=>{
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }} name="wokdepartment" />
                            
                        </FormGroup>

                        <FormGroup className="col-lg-3 col-md-6 mb-4">
                            <FormLabel>
                                KIND number (optional):
                            </FormLabel>
                            <input 
                                placeholder="Enter KIND number" 
                                className="form-control" 
                                name="wokKINDNumber"
                                onChange={(e)=>{
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }}
                                defaultValue={formData?.employmentDetail?.KINDNumber!}
                            />
                            
                        </FormGroup>  
                        <FormGroup className="col-lg-3 col-md-6 mb-4">
                            <FormLabel>
                                Current salary:
                            </FormLabel>
                            <input placeholder="Enter Salary" 
                                type="number" 
                                name="wokcurrentSalary"
                                defaultValue={formData?.employmentDetail?.currentSalary!} 
                                className="form-control"
                                onChange={(e)=>{
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }}
                            />
                            
                        </FormGroup>                
                    </div>
                </div>
                <hr />
                <div>
                    <h4 className="text-warning">Bank Information</h4>
                    <div className="row">
                        <FormGroup className="col-lg-6 col-md-6 mb-4">
                            <FormLabel>
                                Bank name:
                            </FormLabel>
                            <FormSelect defaultValue={formData?.bankAccoundDetail?.bankName!} onChange={(e)=>{
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }} name="bankName">
                                <option value="default">Choose Bank</option>
                                {
                                    bankList.length > 0 ?bankList.map((bank, indx)=>{
                                        return <option key={indx} value={JSON.stringify(bank)}>{bank.name}</option>
                                    }):<></>
                                }
                            </FormSelect>
                        </FormGroup>  

                        {
                            formData.bankAccoundDetail.bankCode &&
                            <FormGroup className="col-lg-6 col-md-6 mb-4">
                                <FormLabel>
                                    Account number:
                                </FormLabel>
                                <input placeholder="Bank account number" onChange={(e)=>{
                                        updateFormData(e, setFormData, setValidationError, formData)
                                    }} defaultValue={formData?.bankAccoundDetail?.accountNumber!} 
                                    className="form-control" name="bankAccountNumber" type="number" />
                                {
                                    formData.bankAccoundDetail.accountName && 
                                    <FormText className="text-success">{
                                        formData.bankAccoundDetail.accountName
                                    }</FormText>
                                }
                            </FormGroup>
                        }
                    </div>
                </div>
                <hr />
                <div>
                    <h4 className="text-warning">KISCMS information</h4>
                    <div className="row">
                        <FormGroup className="col-lg-3 col-md-6 mb-4">
                            <FormLabel>
                                Date joined:
                            </FormLabel>
                            <input placeholder="Date joined" className="form-control" 
                                onChange={(e)=>{
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }} 
                                // defaultValue={new Date(formData?.KISCMSInfo?.dateJoined!).toISOString().slice(0, 10)} 
                                name="dateJoined" 
                                type="date" 
                                />
                        </FormGroup>  

                        {
                            formData?.phoneNumbers && 
                            <FormGroup className="col-lg-3 col-md-6 mb-4">
                            <FormLabel>
                                Account number (official):
                            </FormLabel>
                            <input title="Cooperative Account Number"  className="form-control" 
                            defaultValue={formData?.accountNumber} disabled name="accountNumber" />
                            
                        </FormGroup>
                        }

                        <FormGroup className="col-lg-3 col-md-6 mb-4">
                            <FormLabel>
                                KISCMS number:
                            </FormLabel>
                            <input placeholder="KISCMS number" 
                                disabled
                                // onChange={(e)=>{
                                //     updateFormData(e, setFormData, setValidationError, formData)
                                // }} 
                                defaultValue={formData?.KISCMSInfo?.KISCMSNumber!} 
                                className="form-control" name="kisKISCMSNumber" />
                            
                        </FormGroup>  
                        <FormGroup className="col-lg-3 col-md-6 mb-4">
                            <FormLabel>
                                Monthly savings:
                            </FormLabel>
                            <input className="form-control" placeholder="Monthly savings" onChange={(e)=>{
                                    updateFormData(e, setFormData, setValidationError, formData)
                                }} defaultValue={formData?.KISCMSInfo?.monthlySavings!} name="kismonthlySavings" />
                            
                        </FormGroup>                
                    </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                    {
                        !pageIsLoading?
                        <Button className="btn-sm" onClick={()=>{
                            setPageIsLoading(true)
                            const model = new Users()
                            model.createMember(formData, (returned)=>{
                                if(returned.data.success){
                                    // make registration payment
                                    setPaymentItem({
                                        memberId: returned.data.uid,
                                        memberPassword: returned.data.password,
                                        dateJoined: new Date(formData.KISCMSInfo.dateJoined),
                                        email: formData.email
                                    } as RegistrationPaymentItem)
                                    setMakePayment(true)
                                    setPageIsLoading(false)
                                } else {
                                    setPageIsLoading(false)
                                    console.log(returned.data)
                                    toast("unable to register member: ", {type: 'error'})
                                }
                            }, (error)=>{
                                setPageIsLoading(false)
                                console.log(error)
                            } )
                        }} 
                        disabled={!canSave} 
                        variant="success">
                            <FontAwesomeIcon icon={faSave} /> Save
                        </Button>:
                        <CustomSpinner message="please wait" />
                    }
                </div>
            </Form>
            {(makePayment && paymentItem) && <PaymentModal show={makePayment} onHide={()=>setMakePayment(false)} param={paymentItem} setMsg={setValidationError} payer={formData} />}
        </section>
    )
}

export default RegistrationForm