import {  useContext, useEffect, useState } from "react"
import { Button, Form, FormGroup, FormLabel, Modal } from "react-bootstrap"
import { FinanceData, Loan, REPAYMENT_SOURCE, } from "../../dataTypes/financials"
import {  OUTFLOW_PURPOSE } from "../../constants/UTILITY.constants"
import { saveLoanPayment } from "./methods"
import { userContext } from "../../providers/user.provider"

export const LoanPayoutModal = ({show, onHide, loan }:{show:boolean, onHide:()=>void, loan: Loan})=>{
    const [paymentDate, setPaymentDate] = useState<Date>(new Date())
    const [formData, setFormData] = useState<FinanceData>()
    const [isTransfer, setIsTransfer] = useState<boolean>(true)
    const {currentUser} = useContext(userContext)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)

    useEffect(()=>{

        // set form data
        setFormData({
            date: paymentDate.getTime(),
            description: `Advancement of ${loan.category} loan to ${loan.applicantName}`,
            transactionRef: "",
            memberName: loan.applicantName,
            amount: loan.capital * (1 + (loan.interestRate/100)),
            purpose: OUTFLOW_PURPOSE.LOAN_PAYOUT,
            memberReference: loan.applicantRef,
            day: paymentDate.getDate(),
            year: paymentDate.getFullYear(),
            month: paymentDate.getMonth() + 1,
            processor: `${currentUser?.firstName} ${currentUser?.lastName}`,
            processorRef: currentUser?.reference!,
            source: REPAYMENT_SOURCE.SOURCE
        })

    }, [paymentDate, loan.applicantName, loan.applicantRef, loan.capital, loan.category, loan.interestRate])
    
    return (
            <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
            >
            <Modal.Header closeButton>
                Loan Payout
            </Modal.Header>
            <Modal.Body>
                <Form.Check 
                    type="switch"
                    label={isTransfer?"Cash payment": "Transfer"}
                    name="cashTransfer"
                    className="me-4"
                    defaultChecked={isTransfer}
                    onChange={(e)=>{
                        if(!e.target.checked){
                            // reset payment date
                            setPaymentDate(new Date())
                            // reset transaction reference
                            setFormData(prev=>({...prev, transactionRef: `REF_${loan.applicantRef}_LOAN_PAYMENT`} as FinanceData))
                        }
                        setIsTransfer(e.target.checked)
                    }}
                />
                {
                    isTransfer &&
                    <>
                        <FormGroup className="">
                            <FormLabel>
                                Transaction Reference:
                            </FormLabel>
                            <input className="form-control" placeholder="Enter transaction reference" 
                                onChange={(e)=>{
                                    setFormData(prev=>({...prev, transactionRef: e.target.value} as FinanceData))
                                }} 
                                defaultValue={formData?.transactionRef} 
                                name="transactionRef" />
                        </FormGroup>

                        <FormGroup className="">
                            <FormLabel>
                                Payment date:
                            </FormLabel>
                            <input className="form-control" placeholder="Enter transaction reference"
                                type="date"
                                defaultValue={new Date().toISOString().slice(0, 10)}
                                onChange={(e)=>{
                                    setPaymentDate(new Date(e.target.value))
                                }} 
                                name="paymentDate" />
                        </FormGroup>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    onClick={()=>{
                        if(formData?.transactionRef===""){
                            setPageIsBusy(true)
                            // save to payment and activate loan
                            if(!isTransfer){
                                saveLoanPayment(loan, formData!, onHide)
                            }else{
                                // make payment with paystack : TODO
                                // then saveLoanPayment(loan, formData!, onHide)
                            }
                        }
                    }}
                    disabled={ pageIsBusy } 
                    variant="success" 
                    className="btn-sm">
                    {isTransfer? "Transfer": "Save"}
                </Button>
            </Modal.Footer>
            </Modal>
               
    )
}