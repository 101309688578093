import { setPersistence, 
    browserSessionPersistence, 
    signInWithEmailAndPassword, 
    signOut,
    updateProfile,
    updatePassword,
    PhoneAuthProvider,
    multiFactor,
    User,
    ApplicationVerifier,
    PhoneMultiFactorGenerator,
    getMultiFactorResolver,
    MultiFactorResolver
} from "firebase/auth";
import { DocumentData } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { auth, cloudFunctions } from "../configurations/firebase.configuration";
import { UPLOADREF, UPLOADREFTYPES } from "../dataTypes/fileupload.types";
import { FunctionReturn } from "../dataTypes/firebasequery.types";
import { UserData } from "../dataTypes/user.types";
import { FirebaseStorage } from "../filemanger/FirebaseStorage";
import { BaseModel } from "./Base.model";
import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";

export class Users extends BaseModel {

    current?: DocumentData

    table: string = 'users'

   /**
    * create and validate users
    * @param param0 
    */
   async login({email, password, onSuccess, onFail, recaptcha, setter}: 
    {email: string, password: string, onSuccess:({user, userData}:{user: User, userData: UserData})=>void, onFail:(error?:any)=>void, recaptcha: ApplicationVerifier, setter: Dispatch<SetStateAction<{
        verificationId: string,
        resolver?: MultiFactorResolver
    } | null>>}){
        // persist user in session
        setPersistence(auth, browserSessionPersistence)
            .then(async () => {
            // sign in user
            signInWithEmailAndPassword(auth, email, password)
                .then( async (credential )=>{
                // found user in system without multi-factor enrollment
                // enroll user with multi-factor auth if user is admin
                this.find(credential.user.uid, (data)=>{
                    const user = data as UserData
                    if(user.isAdmin && user.isActive){
                        this.setMultiFactorEnrollment(credential.user, recaptcha, setter)
                        onSuccess({user: credential.user, userData: user})
                    } else {
                        onFail("Invalid user credentials")
                    }
                }, ()=>onFail("Invalid user credentials"))
            }).catch((error)=>{
                if (error.code === 'auth/multi-factor-auth-required') {
                    // The user is a multi-factor user. Second factor challenge is required.
                    const resolver = getMultiFactorResolver(auth, error);
                    this.sendOTP(resolver, recaptcha, setter)
                    // onSuccess({user: credential.user, userData: user})
                } else {
                    onFail("Invalid user credentials")
                }
            })        
        }).catch(()=>onFail("Invalid user credentials"))
   }

   /**
    * Register new Admin user
    * @param data 
    * @param callBack 
    * @param errorHander 
    */
   createMember(data: UserData, callBack:(password: FunctionReturn)=>void, errorHandler: (error?:any)=>void):void {
        // upload image and replace picture url
        const fileUploader = new FirebaseStorage(data.photoUrl, UPLOADREF.IMAGES, UPLOADREFTYPES.PROFILES)
        fileUploader.doUpload((path)=>{
            data.photoUrl = path!
            // save data to database
            const create = httpsCallable(cloudFunctions, 'registerMember');
            create(data).then((value:unknown)=>{
                if(value){
                    // display password
                    callBack(value)
                    // send phone verification
                }else{
                    errorHandler(value)
                }
            }).catch(errorHandler)
        }, (error)=>{errorHandler(error)})
   }


   /**
    * User logs out
    * @param onSuccess 
    * @param onError 
    */
   signout(onSuccess: ()=>void, onError:(error?: string)=>void){
        signOut(auth).then(() => {
            onSuccess()
        }).catch((error) => {
            onError(error)
        })
   }

   /**
    * Admin user changes password
    * @param onSuccess 
    * @param onFail 
    */
   resetPassword( userId: string, onSuccess: (value: any)=>void, onFail: (error?:any)=> void){
        const update = httpsCallable(cloudFunctions, 'resetPassword');
        update(userId).then((value:unknown)=>{
            if(value){
                onSuccess(value as FunctionReturn)
            }else{
                onFail(value)
            }
        }).catch(onFail)
   }

   /**
    * Logged in administrator can change their profile
    * picture using webcam
    * @param photoURL 
    * @param callBack 
    * @param errorHandler 
    */
    updateProfilePicture(photoURL: string, callBack: ()=>void, errorHandler: (error:any)=>void){
        updateProfile(auth.currentUser!, {  photoURL: photoURL }).then(callBack).catch(errorHandler);
    }

    /**
     * Currently logged in administrator can change
     * their password
     * @param newPassword 
     * @param callBack 
     * @param errorHandler 
     */
    changePassword(newPassword: string, callBack: ()=>void, errorHandler: (error:any)=>void){
        updatePassword(auth.currentUser!, newPassword).then(callBack).catch(errorHandler);
    }

    /**
     * send OTP to enable 2 factor authentication
     * @param user 
     * @param recaptchaverifier 
     * @param setter 
     */
    private setMultiFactorEnrollment = (user: User, recaptchaverifier: ApplicationVerifier, setter: Dispatch<SetStateAction< {
        verificationId: string,
        resolver?: MultiFactorResolver
    } | null>>)=>{
       
        multiFactor(user).getSession()
            .then(function (multiFactorSession) {
                // Specify the phone number and pass the MFA session.
                const phoneInfoOptions = {
                    phoneNumber: user.phoneNumber,
                    session: multiFactorSession
                };

                const phoneAuthProvider = new PhoneAuthProvider(auth);

                // Send SMS verification code.
                return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaverifier);
            }).then(function (verificationId) {
                setter({verificationId})
            }).catch(()=>toast("Something went wrong! try later.", {type: 'error'}));
    }



    /**
     * complete user signIn process with OTP
     * @param param0 
     * @param userCode 
     * @param user 
     * @param onSuccess 
     */
    enrollWithSecondFactor = ({verificationId, resolver}:{verificationId: string, resolver?: MultiFactorResolver}, userCode: string, onFail: (error?: string)=>void, user?: User, onSuccess?: ({
        user, userData
    }: {user: User, userData: UserData})=>void)=>{
        try {
             // Ask user for the verification code. Then:
             const cred = PhoneAuthProvider.credential(verificationId, userCode);
             const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
 
             // Complete enrollment.
             if(resolver){
                resolver.resolveSignIn(multiFactorAssertion).then((credential)=>{
                    this.find(credential.user.uid, (data)=>{
                        const user = data as UserData
                        if(user.isAdmin && user.isActive){
                            onSuccess!({user: credential.user, userData: user})
                        }
                    }, ()=>onFail("something went wrong! try again later"))
                }).catch(()=>onFail("Invalid OTP!"))
             } else {
                multiFactor(user!).enroll(multiFactorAssertion, "primary phone").catch(()=>onFail("invalid OTP!",));
             }
        } catch (error) {
            // console.log('error verification: ', error)
            onFail("Invalid OTP! try again")
        }
    }

    /**
     * send OTP to member
     * @param resolver 
     * @param recaptchaVerifier 
     * @param setter 
     * @returns 
     */
    private sendOTP =  (resolver: MultiFactorResolver, recaptchaVerifier: ApplicationVerifier, setter: Dispatch<SetStateAction<{
        verificationId: string,
        resolver?: MultiFactorResolver
    } | null>>)=>{
        try {
            if (resolver.hints[0].factorId ===
                PhoneMultiFactorGenerator.FACTOR_ID) {
                const phoneInfoOptions = {
                    multiFactorHint: resolver.hints[0],
                    session: resolver.session
                };
                const phoneAuthProvider = new PhoneAuthProvider(auth);
                // Send SMS verification code
                return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then(verificationId=>{
                    setter({verificationId, resolver})
                })
            } else {
                // error occured
                toast("something went wrong! contact Administrator", {type: 'error'})
            } 
        } catch (error) {
            toast("Invalid code", {type: 'error'})
        }
    }
}