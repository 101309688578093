import { ChangeEvent, Dispatch, SetStateAction } from "react"
import { INFLOW_PURPOSE } from "../../constants/UTILITY.constants"
import { itemConfig } from "../../dataTypes/customTypes/varTypes"
import { AccountBalance, FinanceData, REPAYMENT_SOURCE, RegistrationPaymentItem } from "../../dataTypes/financials"
import { MESSAGE_TYPE, generalMessage } from "../../dataTypes/notificationMessages.types"
import { Savings } from "../../models/Savings.model"
import { Shares } from "../../models/Shares.model"
import { RegistrationFee } from "../../models/RegistrationFee.model"
import { Receipts } from "../../models/Receipts.model"
import { Users } from "../../models/Users"
import { UserData } from "../../dataTypes/user.types"



export const getConfigItems = (paymentItems: string[], param: RegistrationPaymentItem, admin: UserData, member: UserData): itemConfig =>{
    const config:itemConfig = {} as itemConfig
    paymentItems.forEach(configItem=>{
        const configuration: FinanceData = {
            date: param.dateJoined.getTime(),
            description: `Initial payment for ${configItem}`,
            transactionRef: `REF-${Date.now()}-${configItem}`,
            amount: configItem==='registration' ? 2500: (configItem==='shares'?10000:0),
            purpose: configItem==='shares'?INFLOW_PURPOSE.SHARES:(
                configItem==='savings'?INFLOW_PURPOSE.SAVINGS: INFLOW_PURPOSE.REGISTRATION
            ),
            memberReference: param.memberId,
            day: param.dateJoined.getDate(),
            month: +param.dateJoined.getMonth()+1,
            year: param.dateJoined.getFullYear(),
            source: REPAYMENT_SOURCE.CASH,
            processor: `${admin.firstName} ${admin.lastName}`,
            processorRef: admin.reference!,
            memberName: `${member.firstName} ${member.middleName} ${member.lastName}`
        }
        if(configItem==='shares'){
            config.shares = configuration
        }else if(configItem==='savings'){
            config.savings = configuration
        }else{
            config.registration = configuration
        }
    })
    return config
}


export const updateRegFee = (
    e:ChangeEvent<HTMLInputElement>, 
    setError: Dispatch<SetStateAction<string | null>>,
    setPaymentConfigurations: Dispatch<SetStateAction<itemConfig>>
    )=>{
    const item = e.target.name
    const amount = isNaN(parseInt(e.target.value))? 0: parseInt(e.target.value)
    // let error: string| null = null
    if(item==='registration'){
        // registration fee cannot be less than 2,500
        if(amount < 2500){
            setError("Registration fee cannot be less than N2,500")
        }else{
            setError(null)
            setPaymentConfigurations(prev=>({...prev, [item]:{...prev.registration, amount}}))
        }
        
    }else if(item==='shares'){
        // shares contribution cannot be less than 10,000
        if(amount < 2500){
            setError("Share capital contribution cannot be less than N10,000")
        }else{
            setError(null)
            setPaymentConfigurations(prev=>({...prev, [item]:{...prev.shares, amount}}))
        }
    }else{
        setPaymentConfigurations(prev=>({...prev, [item]:{...prev.savings, amount}}))
    }
}


export const confirmPayment = (items: itemConfig, setMessage: Dispatch<SetStateAction<generalMessage | null>>, onHide: ()=>void, password: string, name: string, admin: UserData)=>{
    const savingsModel = new Savings()
    const sharesModel = new Shares()
    const regFeeModel = new RegistrationFee()
    const receiptModel = new Receipts()
    // create savings, shares and refFee accounts for user
    const accountData: AccountBalance = {
        amount: items.savings.amount,
        name: name,
        lastUpdated: Date.now(),
        processor: `${admin.firstName} ${admin.middleName} ${admin.lastName}`
    }
    savingsModel.save(accountData, {
        id: items.savings.memberReference!,
        errorHander(error) {
            console.log(error)
        },
        callBack() {
            console.log('done saving savings: ', items.savings.amount)
        },
    })

    // update shares amount
    accountData.amount = items.shares.amount
    sharesModel.save(accountData, {
        id: items.shares.memberReference!,
        callBack() {
            onHide()
            console.log('done saving shares: ', items.shares.amount)
        },
        errorHander(error) {
            console.log(error)
        },
    })

    accountData.amount = items.registration.amount
    regFeeModel.save(accountData, {
        id: items.registration.memberReference!,
        callBack() {
            console.log('done saving reg: ', items.registration.amount)
            onHide()
        },
        errorHander(error) {
            console.log(error)
        },
    })

    // record receipts of funds
   
    const data: FinanceData[] = Object.values(items).map(value=>value) 
    console.log(data)
    receiptModel.saveBatch({
        data,
        callBack: ()=>{
          // set message
            setMessage({
                message: "Registration successful, Your password is: "+password,
                type: MESSAGE_TYPE.SUCCESS
            } as generalMessage)
            onHide()
        },
        errorHandler: (error)=>{
            console.log('error registering user',error)
        }
    })
}

export const deactivateMember = (memberId: string, closeModal:()=>void)=>{
    const model = new Users()
    model.update({isActive: false}, memberId, ()=>{
        closeModal()
    }, (error)=>{
        console.log(error)
    })
}