import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faPiggyBank, faUsers, faBank, faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons"
import { INFLOW_PURPOSE, OUTFLOW_PURPOSE } from "../../constants/UTILITY.constants"
import { Model } from "../model.interface"
import { Users } from "../../models/Users"
import { Receipts } from "../../models/Receipts.model"
import { Payments } from "../../models/Payments.model"
import { Dividends } from "../../models/Dividends.model"
import { LoanConfigurations } from "../../models/LoanConfigurations.model"
import { Messages } from "../../models/Messages.model"
import { Notifications } from "../../models/Notifications.model"
import { RegistrationFee } from "../../models/RegistrationFee.model"
import { Savings } from "../../models/Savings.model"
import { Shares } from "../../models/Shares.model"
import { FinanceData } from "../financials"



export type ListItem = {
    label: string,
    path: string,
    icon?: IconProp,
}

export type itemConfig = {
  shares: FinanceData,
  registration: FinanceData,
  savings: FinanceData,
}

export interface PageData  {
    currentPath: string,
    toggleSidebar: boolean,
    toggleSearchBar: boolean,
    pageTitle: string,
    currentCrumb: string,
    parentPages: ParentPage[]
}

export type ParentPage = {
    title: string,
    link: string,
}

export type Message = {
    title: string,
    time: string,
    subject: string,
    body: string
}

export type AnalyticItem = {
    title: string,
    amount: number,
    iconBackgroundColor: string,
    iconColor: string,
    icon: IconProp,
    isMoney: boolean,
    model: Model,
    purpose?: INFLOW_PURPOSE | OUTFLOW_PURPOSE
}

export interface dbItems  {
  reference?: string,
}

export type CounterData = {
    year?: number,
    month?: string,
    date?: number,
    count: number
}

export const analysisCard: AnalyticItem[] = [
    {
      title: 'Savings',
      amount: 0,
      iconBackgroundColor: '#022604',
      iconColor: '#ffffff',
      icon: faPiggyBank,
      isMoney: true,
      model: new Receipts(),
      purpose: INFLOW_PURPOSE.SAVINGS
    },
    {
      title: 'Members',
      amount: 0,
      iconBackgroundColor: '#ead920',
      iconColor: '#022604',
      icon: faUsers,
      isMoney: false,
      model: new Users()
    },
    {
      title: 'Loans',
      amount: 0,
      iconBackgroundColor: '#022604',
      iconColor: '#ffffff',
      icon: faBank,
      isMoney: true,
      model: new Payments(),
      purpose: OUTFLOW_PURPOSE.LOAN_PAYOUT
    },
    {
      title: 'Loan Repayments',
      amount: 0,
      iconBackgroundColor: '#ead920',
      iconColor: '#022604',
      icon: faHandHoldingDollar,
      isMoney: true,
      model: new Receipts(),
      purpose: INFLOW_PURPOSE.LOAN_REPAYMENT
    }
  
  ]

  export type BankDetail = {
    id: number,
    name: string,
    slug: string,
    code: string,
    longcode: string,
    gateway?: string,
    pay_with_bank: boolean,
    active: boolean,
    country: string,
    currency: string,
    type: string,
    is_deleted: boolean,
    createdAt: string,
    updatedAt: string 
};

export enum DBMODELS {
  dividend,
  loanConfiguration,
  messages,
  notifications,
  payments,
  receipts,
  registrationFee,
  savings,
  shares,
  users
}

export const getModel = (modelTitle: DBMODELS) : Model =>{
    
  switch (modelTitle) {
      case DBMODELS.dividend:
          return  new Dividends()

      case DBMODELS.loanConfiguration:
          return new LoanConfigurations()
  
      case DBMODELS.messages:
          return new Messages()

      case DBMODELS.notifications:
          return new Notifications()

      case DBMODELS.payments:
          return new Payments()

      case DBMODELS.receipts:
          return new Receipts()
      
      case DBMODELS.registrationFee:
          return new RegistrationFee()
      
      case DBMODELS.savings:
          return new Savings()
      
      case DBMODELS.shares:
          return new Shares()

      default:
          return new Users()
  }
}



export enum LOAN_APPLICATION_PAGES  {
  FORM,
  GUARANTOR
}