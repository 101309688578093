import { getDownloadURL, ref, StorageReference, uploadBytesResumable, UploadResult, uploadString } from "firebase/storage";
import { storage } from "../configurations/firebase.configuration";
import { UPLOADREF, UPLOADREFTYPES } from "../dataTypes/fileupload.types";
import { generateRandomString } from "../utility/helpers";

export class FirebaseStorage {


    uploadError?: string
    // private fileUrl?: string
    file?: File | string
    private additionalPath?: string
    fullPath?: string

    constructor(file: File | string, storageRef: UPLOADREF, uploadCategory: UPLOADREFTYPES, path?: string){
       if(file){
        // set user additional path
        this.additionalPath = path
        // set file globally
        this.file = file
         // validate file
         this.validateFile(file, storageRef, uploadCategory)
       }
    }


    // validate file (size, type)
    private validateFile = (file: File | string, storageRef: UPLOADREF, uploadCategory: UPLOADREFTYPES): void =>{
        let goodSize: boolean = false
        let goodType: boolean = false
        let extension = ''
        if(typeof(file)!=='string'){
            if(storageRef===UPLOADREF.IMAGES){
                extension = this.getExtensionName(file.type)
                goodType = file.type==='image/png' || file.type==='image/jpg' || file.type==='image/jpeg'
                goodSize = this.checkImageFileSize(file.size, uploadCategory)
            }else if(storageRef===UPLOADREF.DOCUMENTS){
                goodSize =  file.size > 0 && file.size <= 1000000
                goodType = file.type === 'application/pdf'
            }else if(storageRef===UPLOADREF.VIDEOS){
                goodSize =  file.size > 0 && file.size <= 5000000
                goodType = file.type==='video/mp4'
            }
        }else{
            goodSize = true
            goodType = true
        }

        // check and set error messages
        if(!goodSize || !goodType){
            this.setUploadError(uploadCategory, storageRef)
        }else{
            // set file name and path
            this.setFilePath(storageRef, uploadCategory, extension)
        }
    }

    /**
     * Get file extensions
     * @param fileType 
     * @returns 
     */
    private getExtensionName = (fileType: string): string =>{
        // file.type==='image/png' || file.type==='image/jpg' || file.type==='image/jpeg'
        let ext = ''
        if(fileType==='image/png'){
            ext = 'png'
        }
        if(fileType==='image/jpg'){
            ext = 'jpg'
        }
        if(fileType==='image/jpeg'){
            ext = 'jpeg'
        }
        return ext
    }

    /**
     * Determining the size of an image file
     * @param filesize 
     * @param category 
     * @returns 
     */
    private checkImageFileSize = (filesize: number, category: UPLOADREFTYPES):boolean => {

        let passed = false
        switch (category) {
            case UPLOADREFTYPES.ICONS: 
                passed = filesize > 0 && filesize <= 100000
                break;
            case UPLOADREFTYPES.PROFILES:
                passed = filesize > 0 && filesize <= 1000000
                break;
            case UPLOADREFTYPES.DOCUMENTS:
                passed = filesize > 0 && filesize <= 1000000
                break;
            default:
                passed = false
                break;
        }

        return passed
    }

    /**
     * setting error messages for failed file validation
     * @param category 
     * @param ref 
     */
    private setUploadError = (category: UPLOADREFTYPES, ref: UPLOADREF): void => {
        if(ref===UPLOADREF.IMAGES){
           if(category===UPLOADREFTYPES.ICONS){
                this.uploadError = 'All icons must be a png file not larger than 100kb'
           }else if(category===UPLOADREFTYPES.PROFILES){
                this.uploadError = 'Your profile image must be a png file not larger than 50kb'
           }else if(category===UPLOADREFTYPES.DOCUMENTS){
                this.uploadError = 'All vehilce images must be a png file not larger than 1Mb'
           }else{
                this.uploadError = 'File format is not allowed'
           }
        }else if(ref===UPLOADREF.DOCUMENTS){
            this.uploadError = 'Documents must be a pdf file and must not be larger than 1Mb'
        }else if(ref===UPLOADREF.VIDEOS){
           this.uploadError = 'Videos cannot be larger than 20Mb and must be an mp4 format'
        }else{
            this.uploadError = 'File format is not allowed'
        }

    }

    // generate new file name and extension
    private setFilePath = (ref: UPLOADREF, category?: UPLOADREFTYPES, ext?: string): void => {
        const d = new Date()
        const fileExtension:string = ref===UPLOADREF.IMAGES?'.'+ext:(ref===UPLOADREF.DOCUMENTS?'.pdf':'.mp4')
        const fileName = generateRandomString(30)
        this.fullPath = ref.concat(`/`, 
            typeof(category)==='undefined'?'':`${category}/`, 
            typeof(this.additionalPath)==='undefined'?'':`${this.additionalPath}/`, 
            `${fileName}_${d.getTime()}${fileExtension}`)
    }

    /**
     * Upload images, documents and videos
     * @param progressMonitor 
     * @param onSuccess 
     * @param errorHandler 
     */
    doUpload(onSuccess:(path?: string)=>void, errorHandler:(msg?: string)=>void, progressMonitor?: (progress: number)=>void, ){
       if(this.uploadError){
            errorHandler(this.uploadError)
       }else{
        const reference = ref(storage, this.fullPath);
            if(typeof(this.file)==='string'){
                this.uploadAsString(reference, onSuccess, errorHandler)
            }else{
                this.uploadAsFile(progressMonitor!, reference, onSuccess, errorHandler)
            }
                
       }
    }

    /**
     * upload base64 data_url
     * @param reference 
     * @param onSuccess 
     * @param errorHander 
     */
    private uploadAsString = (reference: StorageReference, onSuccess:(path?:string)=>void, errorHander:(error?:any)=>void)=>{
        uploadString(reference, this.file! as string, 'data_url').then((value: UploadResult)=>{
            this.doDownload(value.ref.fullPath, errorHander, onSuccess)
        }, errorHander)
            
    }

    /**
     * upload blobs and files
     * @param progressMonitor 
     * @param reference 
     * @param onSuccess 
     * @param errorHandler 
     */
    private uploadAsFile = (progressMonitor: (progress: number)=>void, reference: StorageReference, onSuccess:(path:string)=>void, errorHandler:(error?: any)=>void)=>{
        const uploadTask = uploadBytesResumable(reference, this.file! as File)
        try {
            uploadTask.on('state_changed', (snapshot)=>{
                const progress:number = (snapshot.bytesTransferred/snapshot.totalBytes) * 100
                
                // still uploading, monitor progress
                progressMonitor(progress)
                
                if(progress === 100){
                    // finished uploading
                    this.doDownload(this.fullPath!, errorHandler, onSuccess)
                }
            })
        } catch (error) {
            console.log('firebase upload error', error)
            errorHandler(error)
        }
    }

    /**
     * Get image url
     * @param path 
     * @param errorHander 
     * @returns 
     */
    doDownload(fullPath: string, errorHander?: (error?: any)=>void, callBack?: (url: string)=>void) {
        getDownloadURL(ref(storage, fullPath))
        .then(((url) => {
            callBack!(url)
        }))
        .catch(errorHander);
    }
}