import { faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext, useState } from "react"
import { Button, FormGroup, FormLabel, FormSelect } from "react-bootstrap"
import { generalMessage } from "../../dataTypes/notificationMessages.types"
import { UserData } from "../../dataTypes/user.types"
import { userContext } from "../../providers/user.provider"
import { updateFormData } from "../../screens/methods/registration"
import CustomSpinner from "../utility/CustomSpinner.component"
import { updateUserData } from "./methods"
import { StaffsAndTrustees } from "../../constants/AdminDefinitions.constant"

export const NextOfKinForm = ({user}:{user: UserData})=>{

    const {currentUser} = useContext(userContext)
    const canUpdate: boolean = (StaffsAndTrustees.includes(currentUser?.adminRole!))
    const [formData, setFormData] = useState<UserData>(user)
    const [validationError, setValidationError] = useState<generalMessage | null>(null)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)

    return (
        <div>
        {
            validationError &&
            <div className="mb-2">
                <small className={validationError.type}>{validationError.message}</small>
            </div>
        }
            <div className="row">
                <FormGroup className="col-lg-6 col-md-6 mb-4">
                    <FormLabel>
                        First name:
                    </FormLabel>
                    <input placeholder="First name of Next of Kin" onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }} className="form-control" defaultValue={formData?.nextOfKin?.firstName!} name="nokfirstName" />
                    
                </FormGroup>

                <FormGroup className="col-lg-6 col-md-6 mb-4">
                    <FormLabel>
                        Last name:
                    </FormLabel>
                        <input onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }} placeholder="Surname of next of kin" className="form-control" 
                            defaultValue={formData?.nextOfKin?.lastName!} name="noklastName" 
                        />
                    
                </FormGroup>  

                <FormGroup className="col-lg-6 col-md-6 mb-4">
                    <FormLabel>
                        Middle name (optional):
                    </FormLabel>
                    <input placeholder="Middle name of next of kin" className="form-control" 
                        onChange={(e)=>{
                            updateFormData(e, setFormData, setValidationError, formData)
                        }} defaultValue={formData?.nextOfKin?.middleName!} name="nokmiddleName" />
                    
                </FormGroup> 

                <FormGroup className="col-lg-6 col-md-6 mb-4">
                    <FormLabel>
                        Email address:
                    </FormLabel>
                    <input type="email" placeholder="Email of next of kin" className="form-control" onChange={(e)=>{
                            updateFormData(e, setFormData, setValidationError, formData)
                        }} defaultValue={formData?.nextOfKin?.email!} name="nokEmailAddress" />
                    
                </FormGroup>

                <FormGroup className="col-lg-6 col-md-6 mb-4">
                    <FormLabel>
                        Primary phone number:
                    </FormLabel>
                    <input type="tel" placeholder="Phone number of Next of kin" onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }} className="form-control" defaultValue={formData?.nextOfKin?.phoneNumber!} name="nokPhone" />
                    
                </FormGroup> 

                <FormGroup className="col-lg-6 col-md-6 mb-4">
                    <FormLabel>
                        Relationship:
                    </FormLabel>
                    <FormSelect onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }} defaultValue={formData?.nextOfKin?.relationship!} name="nokRelationship">
                        <option className="default">Select Relationship</option>
                        <option value="parent">Parent</option>
                        <option value="spouse">Spouse</option>
                        <option value="uncle">Uncle</option>
                        <option value="aunt">Aunt</option>
                        <option value="nephew">Nephew</option>
                        <option value="niece">Niece</option>
                        <option value="cousin">Cousin</option>
                        <option value="sibling">Sibling</option>
                    </FormSelect>
                </FormGroup>                
            </div>

            <div className="mt-3">
                {pageIsBusy && <CustomSpinner />}
                <div className="d-flex justify-content-center mt-1">
                    <Button onClick={
                        ()=>updateUserData({setPageIsBusy, formData})
                    } disabled={pageIsBusy || !canUpdate} className="btn-sm" variant="success">
                        <FontAwesomeIcon icon={faSave} className="me-2" /> Save
                    </Button>
                </div>
            </div> 
        </div>
    )
}