import { useContext, useEffect, useState } from "react";
import { faArrowRightLong, faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button } from "react-bootstrap";
import { Balances, Loan, LoanConfiguration } from "../../dataTypes/financials";
import { UserData } from "../../dataTypes/user.types";
import { capacityMonitor, setLoanApplicationDefault, useCapacityMeasurement, 
  useGetExistingLoans, useGetLoanCategories, useSetLoanBasis } from "./methods";
import { moneyFormatter } from "../../utility/helpers";
import { LOAN_APPLICATION_PAGES } from "../../dataTypes/customTypes/varTypes";
import { toast } from "react-toastify";
import { userContext } from "../../providers/user.provider";
import { Loans } from "../../models/Loans.model";
import { LoanApplicationForm } from "./LoanApplicationForm.component";
import { LoanGuarantorForm } from "./LoanGurantorForm.component";
import { Users } from "../../models/Users";
import { LOANSTATUS } from "../../constants/UTILITY.constants";
import CustomSpinner from "../utility/CustomSpinner.component";


const LoanApplication = ({onHide, show=false, applicant, creditBalances}: {onHide: ()=>void, show:boolean, applicant: UserData, creditBalances: Balances})=>{

    const {currentUser} = useContext(userContext)
    const initialData = setLoanApplicationDefault(applicant, {
      name: `${currentUser?.firstName} ${currentUser?.middleName??""} ${currentUser?.lastName}`,
      date: Date.now(),
      reference: currentUser?.reference!
    })
    const [loanCategories, setLoanCategories] = useState<LoanConfiguration[]>([])
    const [formData, setFormData] = useState<Loan>(initialData)
    const [error, setError] = useState<string | null>(null)
    const [userLoans, setUserLoans] = useState<Loan[]>([])
    const [hasCapacity, setHasCapacity] = useState<boolean>(false)
    const [fullyGuaranteed, setFullyGuranteed] = useState<boolean>(false)
    const [currentSavings, setCurrentSavings] = useState<number>(applicant.KISCMSInfo.monthlySavings)
    const [currentPage, setCurrentPage] = useState<LOAN_APPLICATION_PAGES>(LOAN_APPLICATION_PAGES.FORM)
    const [maxLoan, setMaxLoan] = useState<number>(0)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)

    const getLoanCategories = useGetLoanCategories(loanCategories, setLoanCategories)
    const getExistingLoans = useGetExistingLoans(setUserLoans, applicant.reference!)
    const [loanConfig, setLoanConfig] = useState<LoanConfiguration | null>(null)
    const [loanBasis, setLoanBasis] = useState<number>(0)
    const baseSetter = useSetLoanBasis(loanConfig!, applicant, userLoans, setLoanBasis, creditBalances)
    const capacityMeasurer = useCapacityMeasurement(formData, applicant, userLoans, currentSavings, setHasCapacity, loanConfig!, loanBasis, setMaxLoan)

    useEffect(()=>{
        // get all loan categories
        getLoanCategories()
        // fetch user's loans
        if(userLoans.length <= 0){
          getExistingLoans()
        }
        if(formData.instalments){
          capacityMonitor(formData.capital, maxLoan, setError, currentSavings,  (applicant.employmentDetail.currentSalary * 0.035), error)
        }
        // change loan basis
        baseSetter()
        // set the amount required for loan
        // measure user loan capacity
        capacityMeasurer()

    }, [
          maxLoan, 
          formData.capital, 
          formData.interestRate, 
          formData.instalments, 
          currentSavings, 
          loanBasis, 
          formData, 
          formData.category, 
          loanConfig,
          getLoanCategories,
          userLoans.length,
          applicant.employmentDetail.currentSalary,
          baseSetter,
          capacityMeasurer,
          error,
          getExistingLoans
      ])

    return (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={onHide}
        >
          <Modal.Header closeButton>
            <Modal.Title >
              Loan Application Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && <p className="text-center text-danger bg-warning p-3">{error}</p>}
              {
                currentPage === LOAN_APPLICATION_PAGES.FORM?
                <LoanApplicationForm
                  formData={formData}
                  userLoans={userLoans}
                  setError={setError}
                  user={applicant}
                  setFormData={setFormData}
                  loanCategories={loanCategories}
                  // creditBalances={creditBalances}
                  currentSavings={currentSavings}
                  setCurrentSavings={setCurrentSavings}
                  loanConfig={loanConfig!}
                  setLoanConfig={setLoanConfig}
                  loanBasis={loanBasis}
                  setFullyGuranteed={setFullyGuranteed}
                  initialData={initialData}
                  maxLoan={maxLoan}
                 />: 
                 <LoanGuarantorForm 
                  unguaranteedSum={Math.ceil(+(formData.capital * (1+(formData.interestRate/100))) - loanBasis)}
                  setIsGuaranteed={setFullyGuranteed}
                  setError={setError}
                  setRequest={setFormData}
                  isGuaranteed={fullyGuaranteed}
                  applicant={applicant}
                 />
              }
                <div className="mt-3 card card-body p-3 bg-info">
                    <h5 className="text-danger">Analysis</h5>
                    <div className="row mb-2">
                      <div className="col-5">
                        Amount:
                      </div>
                      <div className="col">{moneyFormatter(formData.capital)}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5">
                        Interest @ {formData.interestRate}%:
                      </div>
                      <div className="col">{moneyFormatter((Math.floor(formData.capital * (formData.interestRate/100))))}</div>
                    </div>
                    {
                      formData.instalments > 0 && 
                      <>
                        <div className="row mb-2">
                          <div className="col-5">
                            Instalments:
                          </div>
                          <div className="col">{moneyFormatter(Math.ceil(((formData.capital * (1 + (formData.interestRate/100)))/formData.instalments)))}</div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-5">
                            Loan capacity:
                          </div>
                          <div className={`col ${(hasCapacity && (currentSavings >= applicant.employmentDetail.currentSalary * 0.035))? 
                            'text-success': 
                            'text-danger'}`}>{(hasCapacity && (currentSavings >= applicant.employmentDetail.currentSalary * 0.035)) ? "Good": "Bad"}</div>
                        </div>
                      </>
                    }
                </div>
          </Modal.Body>
          <Modal.Footer>
            {
              (currentPage===LOAN_APPLICATION_PAGES.FORM && (loanBasis < (formData.capital * (1+(formData.interestRate/100)))))?
              <Button disabled={hasCapacity===false || (currentSavings < applicant.employmentDetail.currentSalary * 0.035)} className="btn-sm" onClick={()=>{
                
                if(hasCapacity){
                  setCurrentPage(LOAN_APPLICATION_PAGES.GUARANTOR)
                }else{
                  toast(`${applicant.firstName} does not have the capacity for this loan`)
                }
              }}>
               Choose Guarantor(s) <FontAwesomeIcon icon={faArrowRightLong} className="ms-2" /> 
            </Button>:
            <>
              {
                pageIsBusy?
                <CustomSpinner />:
                <Button 
              disabled={hasCapacity===false || fullyGuaranteed===false || (currentSavings < applicant.employmentDetail.currentSalary * 0.035) || error!==null} 
              className="btn-sm" variant="success" onClick={()=>{
                setPageIsBusy(true)
                // check guaranty status
                if((loanBasis >= (formData.capital * (1+(formData.interestRate/100))))){
                  formData.status = LOANSTATUS.GUARANTEED
                }
                //update savings 
                if(currentSavings !== applicant.KISCMSInfo.monthlySavings){
                  const userModel = new Users()
                  applicant.KISCMSInfo.monthlySavings = currentSavings
                  const id = applicant.reference
                  delete applicant.reference
                  userModel.update(applicant, id!, ()=>{toast("savings updated", {type: 'info'})}, (error)=>{console.log('unable to update savings: ', error)})
                }
                // create loan
                const model = new Loans()
                // loan liability
                const loanL = (formData.capital * (1+(formData.interestRate/100)))
                formData.guaranteeRequest = loanL > loanBasis? Math.ceil(+loanL - loanBasis):0
                model.save({...formData, approvals: {}}, {
                  callBack() {
                    toast("Loan application successful!", {type: 'success'})
                    onHide()
                    setPageIsBusy(false)
                  },
                  errorHander(_) {
                    toast("Loan application not successful!", {type: 'error'})
                    setPageIsBusy(false)
                  },
                })
              }}>
              <FontAwesomeIcon icon={faFolderPlus} />  Apply
            </Button>

              }
            </>
            }
          </Modal.Footer>
        </Modal>
      );
}

export default LoanApplication