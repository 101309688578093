
export enum UPLOADREF {
    IMAGES = 'images',
    DOCUMENTS = 'documents',
    VIDEOS = 'videos'
}

export enum UPLOADREFTYPES {
    ICONS = 'icons',
    DOCUMENTS = 'documents',
    PROFILES = 'profiles'
}