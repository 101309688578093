import { Fragment, useCallback, useContext, useEffect, useState } from "react"
import { Button, ButtonGroup, Card } from "react-bootstrap"
import LoanApplication from "../components/cooperator/LoanApplication.component"
import { useLocation, useNavigate } from "react-router-dom"
import BreadCrumbComponent from "../components/main/body/pageTitle/BreadCrumb.component"
import { Editor, UserData } from "../dataTypes/user.types"
import { CustomEditableCard } from "../components/utility/CustomEditableCard.component"
import { BiodataDisplay } from "../components/cooperator/BiodataDisplay.component"
import { ContactDisplay } from "../components/cooperator/ContactDisplay.component"
import { EmploymentInfoDisplay } from "../components/cooperator/EmploymentInfoDisplay.component"
import { NextOfKinDisplay } from "../components/cooperator/NextOfKinDisplay.component"
import { CooperativeInfoDisplay } from "../components/cooperator/CooperativeInfoDisplay.component"
import { BiodataForm } from "../components/cooperator/BiodataForm.component"
import { CooperatorForm } from "../components/cooperator/CooperatorForm.component"
import { ContactForm } from "../components/cooperator/ContactForm.component"
import { NextOfKinForm } from "../components/cooperator/NextOfKinForm.component"
import { EmploymentForm } from "../components/cooperator/EmploymentForm.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileLines, faGavel, faPause, faPlay, faRefresh, faUserXmark } from "@fortawesome/free-solid-svg-icons"
import { changeMemberStatus, fetchActiveLoans, fetchGuaranteedLoans, getCreditBalances, resetUserPassword } from "./methods/cooperator"
import { Balances, LOAN_APPROVERS, Loan } from "../dataTypes/financials"
import { CrediBalance } from "../components/utility/CreditBalances.component"
import { DBMODELS } from "../dataTypes/customTypes/varTypes"
import { LoanCard } from "../components/cooperator/LoanCard.component"
import { MEMBERSHIP_STATUS } from "../constants/UTILITY.constants"
import { Users } from "../models/Users"
import CustomSpinner from "../components/utility/CustomSpinner.component"
import { PenaltyModal } from "../components/cooperator/PenaltyModal.component"
import { WithdrawalModal } from "../components/cooperator/WithdrawalModal.component"
import { CustomTable, loanGuaranteeColumn, loanRepaymentsColumn } from "../components/utility/CustomTable.component"
import { MESSAGE_TYPE, generalMessage } from "../dataTypes/notificationMessages.types"
import { toast } from "react-toastify"
import { userContext } from "../providers/user.provider"
import { StaffsAndTrustees, Trustees } from "../constants/AdminDefinitions.constant"
import { monitorPasswordResetter } from "./methods/registration"


const Cooperator = ()=>{
    const location = useLocation()
    const {currentUser} = useContext(userContext)
    const navigate = useNavigate()
    const [showLoanApplication, setShowLoanApplication] = useState<boolean>(false)
    const openLoanApplication = ()=>setShowLoanApplication(true)
    const closeLoanApplication = ()=>setShowLoanApplication(false)
    const uid:string = location.state 
    const [creditBalances, setCreditBalances] = useState<Balances | null>(null)
    const [currentLoans, setCurrentLoans] = useState<Loan[]>([])
    const [guaranteedLoans, setGuaranteedLoans] = useState<Loan[]>([])
    const [user, setUser] = useState<UserData>()
    const [showPenalty, setShowPenalty] = useState<boolean>(false)
    const [showWithdrawal, setShowWithdrawal] = useState<boolean>(false)
    const [message, setMessage] = useState<generalMessage | null>(null)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    
    const getBalances = useCallback(getCreditBalances, [setCreditBalances, creditBalances])
    const getLoans = useCallback(fetchActiveLoans, [setCurrentLoans, currentLoans])
    const getGuaranteedLoans = useCallback(fetchGuaranteedLoans, [setGuaranteedLoans, guaranteedLoans])
    const [currentLoanIndex, setCurrentLoanIndex] = useState<number | null>(null)
    
    const withdrawalStatus = [MEMBERSHIP_STATUS.WITHDRAWN, MEMBERSHIP_STATUS.PENDING_WITHDRAWAL]
    useEffect(()=>{
        if(!uid){
            navigate('/')
        }else{
            // monitor password resetter
            if(currentUser){
                monitorPasswordResetter(currentUser!, navigate)
            }
            const userModel = new Users()
            userModel.stream((data)=>{
                setUser(data as UserData)
            }, (error)=>{
                console.log('unable to fecth user: ', error)
            }, uid)
            getBalances(setCreditBalances, uid)
            getLoans(uid, setCurrentLoans)
            getGuaranteedLoans(uid, setGuaranteedLoans)

            // set loan repayments schedule
            if(currentLoans.length > 0 && currentLoanIndex===null){
                setCurrentLoanIndex(0)
            }
        }
    }, [location.state, getBalances, getGuaranteedLoans, getLoans, navigate, uid, currentLoans.length, currentUser, navigate])

   
    return (
        <>
            {
                user ?
                <Fragment>
                    <BreadCrumbComponent 
                        pageTitle="Member Profile"
                        currentCrumb="profile"
                        parentPages={[]}
                        message={message}
                    />
                    {pageIsBusy && <CustomSpinner />}
                    <section className="row">
                        <div className="col-lg- col-md-4">
                        <CustomEditableCard isEdit={
                            !withdrawalStatus.includes(user.status) &&
                            (
                                StaffsAndTrustees.includes(currentUser?.adminRole!)
                            )
                        } pageTitle="Bio-Data" cardBody={<BiodataDisplay user={user!} />} modalForm={<BiodataForm biodata={user!} />} />
                        <ButtonGroup className="btn-shadow">
                            <Button disabled={withdrawalStatus.includes(user.status)} onClick={()=>{
                                setPageIsBusy(true)
                                resetUserPassword(uid, (data)=>{
                                    toast("password reset successful", {type: 'success'})
                                    setMessage({
                                        message: `Your new password is ${data.data.password}`,
                                        type: MESSAGE_TYPE.SUCCESS
                                    } as generalMessage)
                                    setPageIsBusy(false)
                                }, ()=>{
                                    toast("password reset unsuccessful!", {type: 'error'})
                                    setPageIsBusy(false)
                                })
                            }} variant="warning" className="btn-sm">
                                <FontAwesomeIcon icon={faRefresh} /> Reset password
                            </Button>
                            <Button disabled={withdrawalStatus.includes(user.status)} onClick={()=>{
                                if(window.confirm("Action is irreversible! Are you sure?")){
                                    setShowWithdrawal(true)
                                }
                            }} variant="danger" className="btn-sm">
                                <FontAwesomeIcon icon={faUserXmark} /> Membership Withdrawal
                            </Button>
                        </ButtonGroup>
                       
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="row">
                                <div className="col-lg-6">
                                    <CustomEditableCard isEdit={
                            !withdrawalStatus.includes(user.status) &&
                            (
                                StaffsAndTrustees.includes(currentUser?.adminRole!)
                            )
                        } pageTitle="Cooperative Info" cardBody={<CooperativeInfoDisplay info={user?.KISCMSInfo!} accountNumber={user?.accountNumber!} />} modalForm={<CooperatorForm user={user!} />}  />
                                </div>
                                <div className="col-lg-6">
                                    <CustomEditableCard isEdit={
                            !withdrawalStatus.includes(user.status) &&
                            (
                                StaffsAndTrustees.includes(currentUser?.adminRole!)
                            )
                        } pageTitle="Next of kin" cardBody={<NextOfKinDisplay nextOfKin={user?.nextOfKin!} />} modalForm={<NextOfKinForm user={user!} />}  />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <CustomEditableCard isEdit={
                            !withdrawalStatus.includes(user.status) &&
                            // Only trustees can edit
                            (
                                // currentUser?.adminRole === ADMIN_ROLE.STAFF ||
                                Trustees.includes(currentUser?.adminRole!)
                            )
                        } pageTitle="Contact Info" cardBody={<ContactDisplay address={user?.contactAddress!} />} modalForm={<ContactForm user={user!} />}  />
                                </div>
                                <div className="col-lg-6">
                                    <CustomEditableCard isEdit={
                            !withdrawalStatus.includes(user.status) &&
                            // only trustees can edit
                            (
                                Trustees.includes(currentUser?.adminRole!)
                            )
                        } pageTitle="Employment Info" cardBody={<EmploymentInfoDisplay info={user?.employmentDetail!} />} modalForm={<EmploymentForm user={user!} />}  />
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    
                    <section className="row">
                        <div className="col-lg-4 col-md-4">
                            <Card>
                                <Card.Header className="text-success">Balances</Card.Header>
                                <Card.Body className="pt-5">
                                    <CustomEditableCard pageTitle="Unclaimed Dividend" cardBody={
                                        (creditBalances && creditBalances?.unclaimedDividend?.amount) &&
                                        <CrediBalance amount={creditBalances.unclaimedDividend.amount} title={DBMODELS.dividend} isCalculated={true} user={user!} />
                                    } isEdit={false}  />
                                    <CustomEditableCard pageTitle="Shares" cardBody={
                                        <CrediBalance amount={creditBalances?.shares.amount??0} title={DBMODELS.shares} user={user!}  />
                                    }  isEdit={false}  />
                                    <CustomEditableCard pageTitle="Savings" cardBody={
                                        <CrediBalance amount={creditBalances?.savings.amount ?? 0} title={DBMODELS.savings} user={user!} />
                                    } isEdit={false}   />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-lg-8 col-md-8">
                        {/* className="d-flex mb-2 justify-content-between align-items-center bg-info pe-5" */}
                            <Card>
                                <Card.Header className="text-success me-5 d-flex justify-content-between">
                                    Active Loans
                                    <Fragment>
                                        <Button onClick={()=>openLoanApplication()} disabled={
                                            withdrawalStatus.includes(user.status)
                                        } title="New Loan" className="text-white btn-sm btn-success icon-btn" >
                                            <FontAwesomeIcon className="me-2" icon={faFileLines} /> 
                                        </Button>
                                        <Button disabled={withdrawalStatus.includes(user.status)} onClick={()=>{
                                            // show form modal
                                            setShowPenalty(true)
                                        }} title="Fine & Penalty" className="text-white btn-sm btn-warning icon-btn" >
                                            <FontAwesomeIcon className="me-2" icon={faGavel} /> 
                                        </Button>
                                        <Button onClick={()=>{
                                            const newStatus = (user.status===MEMBERSHIP_STATUS.FROZEN || withdrawalStatus.includes(user.status))?
                                            MEMBERSHIP_STATUS.ACTIVE:MEMBERSHIP_STATUS.FROZEN
                                            if(window.confirm(`Are you sure you want to ${newStatus===MEMBERSHIP_STATUS.ACTIVE?"activate":"freeze"} this account?`)){
                                                changeMemberStatus(newStatus, user.reference!, `User account successfully ${newStatus===MEMBERSHIP_STATUS.ACTIVE?"activated":"frozen!"}`)
                                            }
                                        }} title={(user.status===MEMBERSHIP_STATUS.FROZEN || withdrawalStatus.includes(user.status))?"Reactivate Account":"Freeze Account"} className="text-white btn-sm btn-dark icon-btn" >
                                            <FontAwesomeIcon className="me-2" icon={(user.status===MEMBERSHIP_STATUS.FROZEN
                                                || withdrawalStatus.includes(user.status))?faPlay:faPause} /> 
                                        </Button>
                                    </Fragment>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row mt-4">
                                        {
                                            currentLoans && 
                                            currentLoans.map((loan, index)=>{
                                                loan.approvals = new Map(Object.entries(loan.approvals)) as Map<LOAN_APPROVERS, Editor>
                                                return <LoanCard 
                                                    key={index} 
                                                    loan={loan} 
                                                    isCurrent={index===currentLoanIndex} 
                                                    applicant={user!}
                                                    className={['btn']} onClick={()=>setCurrentLoanIndex(index)} />
                                            })
                                        }
                                    </div>
                                   { 
                                        currentLoanIndex!==null && 
                                        <Card>
                                            <Card.Header>Repayment schedule</Card.Header>
                                            <CustomTable
                                                columns={loanRepaymentsColumn}
                                                data={currentLoans[currentLoanIndex]?.repayments!}
                                                rowKey='reference'
                                            />
                                        </Card>
                                    }
                                </Card.Body>
                            </Card>
                        </div>
                    </section>

                    <Card>
                        <Card.Header>Guaranteed Loans</Card.Header>
                        <Card.Body>
                            <CustomTable
                                columns={loanGuaranteeColumn(uid)}
                                data={guaranteedLoans}
                                rowKey='reference'
                            />
                        </Card.Body>
                    </Card>
                    {showLoanApplication && <LoanApplication 
                        show={showLoanApplication} 
                        onHide={closeLoanApplication} 
                        applicant={user!} 
                        creditBalances={creditBalances!} 
                    />}
                    {
                        showPenalty && 
                        <PenaltyModal show={showPenalty} onHide={()=>setShowPenalty(false)} uid={user.reference!} offender={
                            `${user.firstName} ${user.middleName} ${user.lastName}`
                        } />
                    }
                    {
                        showWithdrawal &&
                        <WithdrawalModal show={showWithdrawal} 
                            onHide={()=>{setShowWithdrawal(false)}} 
                            data={{
                                credits: creditBalances!,
                                debits: currentLoans,
                                guaranteedLoans,
                                withdrawalDate: Date.now(),
                                maturityDate: (+Date.now()+(1000*60*60*24*30)),
                                uid: user.reference!,
                                withdrawalCharges: 0
                            }} 
                            member={user}
                        />
                    }
                </Fragment>:
                <CustomSpinner />
            }
        </>
    )
}

export default Cooperator