
import { ChangeEvent } from "react"
import { FormGroup, FormText } from "react-bootstrap"

export const CustomPhoneInput = ({ defaultValue, description, valueSetter, placeholder, disabled} : {
    // label?: string,
    description?: string,
    defaultValue?: string,
    placeholder?: string,
    valueSetter: (e: ChangeEvent<HTMLInputElement>)=>void,
    disabled?: boolean
})=>{
    return (
        <FormGroup className="col-lg-4 mb-4 text-center">
            <input className="form-control" type="tel"
            disabled={disabled} 
            placeholder={placeholder} 
            onChange={valueSetter} defaultValue={defaultValue} name="phoneNumber" />
            {description && <FormText className="text-info" >{description}</FormText>}
        </FormGroup>   
    )
}