import AnalysisCardComponent from "../components/utility/AnalysisCard.component";
import { AnaysisCardItem } from "../components/main/body/dashboard/AnalysisCardItem.component";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BreadCrumbComponent from "../components/main/body/pageTitle/BreadCrumb.component";
import { analysisCard, AnalyticItem } from "../dataTypes/customTypes/varTypes";
import { useCallback, useContext, useEffect, useState } from "react";
import { adminColumn, CustomTable, userColumn } from "../components/utility/CustomTable.component";
import { moneyFormatter } from "../utility/helpers";
import { UserData } from "../dataTypes/user.types";
import { WHEREOPERATOR } from "../dataTypes/firebasequery.types";
import { ADMIN_ROLE } from "../constants/UTILITY.constants";
import { Users } from "../models/Users";
import { StaffsAndTrustees } from "../constants/AdminDefinitions.constant";
import { userContext } from "../providers/user.provider";
import { monitorPasswordResetter } from "./methods/registration";




type TabItem = {
  id: string,
  label: string
}

const tabs: TabItem[] = [
  {
    id: 'active-members',
    label: 'Active'
  },
  {
    id: 'inactive-members',
    label: 'Inactive'
  },
  {
    id: 'administrators',
    label: 'Administrators'
  }
]

export const Dashboard = ()=>{
  const {currentUser} = useContext(userContext)
  const navigate = useNavigate()
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
  const [tableData, setTableData] = useState<UserData[]>([])
  const [currentAnalytics, setCurrentAnalytics] = useState<AnalyticItem[]>(analysisCard)
  
  // const [message, setMessage] = useState<generalMessage | null>(null)

  /**
   * Fetch all counters
   * members, savings, repayments and loans
   */
  const fetchMemberList = useCallback((tab: string)=>{
    const whereKey = tab==='Administrators'?'isAdmin':'isActive'
    const whereValue = tab==='Administrators'?true:tab==='Active'
    const model = new Users()
    model.findWhere({
      wh: [
        {
          key: 'adminRole',
          operator: WHEREOPERATOR.NOT_EQUAL_TO,
          value: ADMIN_ROLE.SUPER
        },
        {
          key: whereKey,
          operator: WHEREOPERATOR.EQUAL_TO,
          value: whereValue
        }
      ],
      lim: 100,
      callBack: (data)=>{
        setTableData(data as UserData[])
      },
      errorHandler: (error)=>{
        console.log('unable to fetch users: ', error)
      }
    })

  }, [setTableData])

  useEffect(()=>{
    // monitor password resetter
    if(currentUser){
      monitorPasswordResetter(currentUser!, navigate)
    }
    // fetch all members
    fetchMemberList(tabs[activeTabIndex].label)
  }, [activeTabIndex, currentUser])

    return (
        <>
        <BreadCrumbComponent 
          pageTitle="Dashboard"
          currentCrumb="Home"
          parentPages={[]}
          message={null}
        />
        <section className="section dashboard">
          <div className="row">
            {
              currentAnalytics.map((item, indx)=>{
                return <AnalysisCardComponent onFilter={setCurrentAnalytics}  item={item} key={indx} index={indx} component={
                  <AnaysisCardItem label={item.isMoney?`N${moneyFormatter(item.amount)}`:moneyFormatter(item.amount)} icon={{
                    backgroundColor: item.iconBackgroundColor,
                    color: item.iconColor,
                    name: item.icon
                  }} />
                }  />
              })
            }
          </div>

          <div className="row">
            <div className="mb-3 d-flex justify-content-between">
              
              {/**DISABLE IF NOT STAFF OR TRUSTEE */}
              <Button
                className="btn-sm btn-success" 
                onClick={()=>{
                  navigate('/registration')
                }}
                disabled={!StaffsAndTrustees.includes(currentUser?.adminRole!) || !currentUser?.isAdmin!}
              >
                <i className="bi bi-person-add"></i> New member
              </Button>
                
                {/**DISABLE IF NOT PRESIDENT */}
              <Button
              
                disabled={currentUser?.adminRole!==ADMIN_ROLE.PRESIDENT && currentUser?.adminRole!==ADMIN_ROLE.SUPER}
                className="btn-sm btn-warning"
              >
                <i className="bi bi-hr"></i> Declare dividend

              </Button>
            </div>
            <div className="card">
          <div className="card-body pt-3">
            <ul className="nav nav-tabs nav-tabs-bordered">
              {
                tabs.map((tab, index)=>{
                  const activeTab = tabs[activeTabIndex]
                  return <li key={index} className="nav-item">
                  <button onClick={()=>setActiveTabIndex(index)} type="button" className={`nav-link ${tab.id===activeTab.id?"active":""}`} >{tab.label}</button>
                </li>
                })
              }

            </ul>
            <div className="tab-content pt-2">
              {
                tabs.map((tab, index)=>{
                  const activeTab = tabs[activeTabIndex]
                  return <div key={index} className={`tab-pane fade show profile-overview ${tab.id===activeTab.id?"active":""}`} id={tab.id}>
                    {
                      tab.id===activeTab.id &&
                      <CustomTable
                        columns={tab.id==='administrators'?adminColumn:userColumn}
                        data={tableData}
                        rowKey='reference'
                        onClick={(row: UserData)=>{
                          navigate('/cooperator', {state: row.reference})
                        }}
                      />
                    }
                  </div>
                })
              }
            </div>

          </div>
        </div>
          
          </div>
        </section>
        </>
    );
}

