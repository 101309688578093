import { ChangeEvent, Dispatch, SetStateAction } from "react"
import { toast } from "react-toastify"
import { LoanConfiguration, initialLoanConfig } from "../../dataTypes/financials"
import { LoanConfigurations } from "../../models/LoanConfigurations.model"

export const updateForm = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, setFormData: Dispatch<SetStateAction<LoanConfiguration>>)=>{
    const key = e.target.name
    let value: string | number | boolean = ''
    if(e.target.type==='checkbox'){
        const i = e.target as HTMLInputElement
        value = i.checked
    }else if(e.target.type==='select-one'){
        if(e.target.value==='default'){
            toast("please select a valid value", {type: 'error'})
        }else{
            value = e.target.value
        }
    }else{
        value = e.target.value
    }
    setFormData(prev=>({...prev, [key]: value}))
}

export const increasePlans = (planIndex: number[], setPlanIndex: Dispatch<SetStateAction<number[]>>)=>{
    if(planIndex.length < 5){
        setPlanIndex((prev)=>[...prev, prev.length])
    }else{
        toast("Maximum loan plans reached!", {type: 'warning'})
    }
}

export const decreasePlans = (
    planIndex: number[], 
    setPlanIndex: Dispatch<SetStateAction<number[]>>, 
    formData: LoanConfiguration, 
    setFormData: Dispatch<SetStateAction<LoanConfiguration>>
)=>{
    if(planIndex.length > 1){
        if(formData.plans.length > 1){
            // remove from formData
            setFormData((prev)=>({...prev, plans: [...prev.plans.slice(0, -1)]}))
        }
        setPlanIndex((prev)=>prev.slice(0, -1))
    }else{
        toast("Loan must contain at least One plan!", {type: 'warning'})
    }
}

export const saveLoanConfig = (
    formData: LoanConfiguration, 
    existingData: LoanConfiguration[], 
    handleClose: ()=>void,
    setFormData: Dispatch<SetStateAction<LoanConfiguration>>,
    setPageIsBusy: Dispatch<SetStateAction<boolean>>
)=>{
    // check if saving for the first time
    const model = new LoanConfigurations()
    let id: string | undefined
    let canSave: boolean = false
    setPageIsBusy(true)
    if(formData.reference){
        // update
        id = formData.reference
        delete formData.reference
        canSave = true
    }else{
        // fresh save
        // check if loan already exists
        if(existingData.find((value)=>value.title.toLowerCase()===formData.title.toLowerCase())){
            // item already exists, show error message
            toast(`${formData.title} already exists`, {type: 'error'})
            setPageIsBusy(false)
        }else{
            // no data exists
            canSave = true
        }
    }
    // check if it is safe to save
   if(canSave){
    model.save(formData, {
        id, 
        callBack:()=>{
            toast("Configuration data updated successfully!", {type: 'success'})
            handleClose()
            setFormData(initialLoanConfig)
            setPageIsBusy(false)
        },
        errorHander: (error)=>{
            console.log('error updating loan: ', error)
            setPageIsBusy(false)
        }
    })
   }
}