export const getCurrentLocation = ():string =>{
    const currentPath:string = window.location.pathname
    // convert path to array
    const pathArr = currentPath.split('/')
    return pathArr[1]
}

/**
 * Generates random strings of n length
 * containing 0-9 and Aa-Zz
 * @param length 
 * @returns 
 */
export const generateRandomString = (length: number): string => {
    if(length > 40){
        throw new Error(`Maximum generatable character is 40, ${length} was required`)
    }
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/**
 * format unicode characters
 * @param input 
 * @returns 
 */
export function convertUnicode(input: string) {
    return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a,b) =>
      String.fromCharCode(parseInt(b, 16)));
  }

/**
 * converts number to money format of comma 
 * separated thousands
 * @param x 
 * @returns 
 */
export const moneyFormatter = (x:number):string => {
    const base: number = 1000000
    // number is less than a million
    if(x < base){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }else{
        let val = ''
        // check number is less than a billion
        if(x < (base * 1000)){
            val = (x/base).toFixed(2) + "M"
        }else{
            val = (x/(base * 1000)).toFixed(2) + "B"
        }
        return val
    }
}

/**
 * Generate random number as KISCMS number
 * @returns 
 */
export const getKISCMSNumber = ():string=>{
    const d = new Date()
    return `KISCMS${d.getMonth()}${d.getDate()}${d.getFullYear().toString().slice(-2)}${+d.getTime().toString().slice(-4)}`
}