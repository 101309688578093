import { useContext, useState } from "react"
import { Button, ButtonGroup, Form, FormGroup, FormLabel, Modal } from "react-bootstrap"
import CustomSpinner from "../utility/CustomSpinner.component"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { INFLOW_PURPOSE } from "../../constants/UTILITY.constants"
import { FinanceData, REPAYMENT_SOURCE } from "../../dataTypes/financials"
import { userContext } from "../../providers/user.provider"
import { moneyFormatter } from "../../utility/helpers"
import { toast } from "react-toastify"
import { Receipts } from "../../models/Receipts.model"
import { useInitializePayment } from "../../constants/configuration.constants"

export const OtherIncomeModal = ({show, onHide}:{
    show: boolean,
    onHide: ()=>void
})=>{

    const initialRef = `REF_${Date.now()}_OTHER_INCOME`
    const {currentUser} = useContext(userContext)
    const [paymentDate, setPaymentDate] = useState<Date>(new Date())
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    const [isTransfer, setIsTransfer] = useState<boolean>(false)
    const [receiptData, setReceiptData] = useState<FinanceData>({
        date: paymentDate.getTime(),
        day: paymentDate.getDate(),
        month: paymentDate.getMonth()+1,
        year: paymentDate.getFullYear(),
        transactionRef: '',
        description: '',
        amount: 0,
        purpose: INFLOW_PURPOSE.OTHER_INCOME,
        memberName: '',
        memberReference: 'OTHER INCOME',
        source: REPAYMENT_SOURCE.CASH,
        processor: currentUser?.firstName+" "+currentUser?.lastName,
        processorRef: currentUser?.reference!
    })

    /**
     * save receipt
     */
    const saveReceipt = ()=>{
        const recModel = new Receipts()
        recModel.save(receiptData, {
            callBack() {
                toast("Receipt successful!", {type: 'success'})
                onHide()
            },
            errorHander(error) {
                onHide()
                toast("check connection!", {type: 'warning'})
                console.log(error)
            },
        })
    }

    // pay with paystack
    const initializePayment = useInitializePayment('support@kiscms.com', ((
        receiptData?.amount!
        )*100), receiptData?.transactionRef)

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
            >
                <Modal.Header closeButton>
                  <Modal.Title className="d-flex justify-content-between">
                    Other Income
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Check 
                        type="switch"
                        label={isTransfer?"Cash payment":"Transfer payment"}
                        name="isTransfer"
                        className="ms-2"
                        defaultChecked={isTransfer}
                        onChange={(e)=>{
                            setIsTransfer(e.target.checked)
                            if(e.target.checked){
                                setPaymentDate(new Date())
                                setReceiptData(prev=>({...prev, transactionRef: initialRef, source: REPAYMENT_SOURCE.TRANSFER}))
                            }else{
                                setReceiptData(prev=>({...prev, transactionRef: '', source: REPAYMENT_SOURCE.CASH}))
                            }
                        }}
                    />
                    {
                        !isTransfer &&
                            <section>
                                <FormGroup className="form-control bg-dark text-white mt-2 mb-2">
                                    <FormLabel>Payment reference:</FormLabel>
                                    <input title="receipt reference" className="form-control" onChange={(e)=>{
                                        setReceiptData(prev=>({...prev, transactionRef: e.target.value}))
                                    }} />
                                </FormGroup>
                                <FormGroup className="form-control bg-dark text-white mt-2 mb-2">
                                    <FormLabel>Payment date:</FormLabel>
                                    <input title="receipt date" type="date" className="form-control" onChange={(e)=>{
                                        setPaymentDate(new Date(e.target.value))
                                    }} />
                                </FormGroup>
                            </section>
                     }

                    <FormGroup className="form-control bg-dark text-white mt-2 mb-2">
                        <FormLabel>Donor:</FormLabel>
                        <input title="giver"  className="form-control" onChange={(e)=>{
                            setReceiptData(prev=>({...prev, memberName: e.target.value}))
                        }} />
                    </FormGroup>

                    <FormGroup className="form-control bg-dark text-white mt-2 mb-2">
                        <FormLabel>Amount:</FormLabel>
                        <input title="amount" type="number" min={100}  className="form-control" onChange={(e)=>{
                            const amount = parseInt(e.target.value)
                            if(amount <=0){
                                toast('Enter a valid amount', {type: 'warning'})
                            }else{
                                setReceiptData(prev=>({...prev, amount}))
                            }
                        }} />
                    </FormGroup>

                    <FormGroup className="form-control bg-dark">
                        <FormLabel className="text-white">Brief description:</FormLabel>
                        <textarea 
                            title="Receipt description" 
                            rows={4} 
                            className="form-control"
                            placeholder="Enter brief description"
                            onChange={(e)=>{
                                setReceiptData(prev=>({...prev, description: e.target.value}))
                            }}
                        />
                    </FormGroup>
                        
                </Modal.Body>
                <Modal.Footer>
                  {
                    pageIsBusy?
                    <CustomSpinner />:
                    <ButtonGroup>
                        {
                            <Button 
                                disabled={
                                    receiptData.transactionRef==='' ||
                                    receiptData.amount <= 0 ||
                                    receiptData.memberName === '' ||
                                    receiptData.description === ''
                                }
                                variant="success"
                                className="btn-sm"
                                onClick={()=>{
                                    const confirmed = window.confirm(`Are you receiving ${moneyFormatter(receiptData.amount)} from ${receiptData.memberName}?`)
                                    if(confirmed){
                                        setPageIsBusy(true)
                                        if(isTransfer){
                                            // show paystack payment modal
                                            // pay with paystack
                                            initializePayment(()=>{
                                                saveReceipt()
                                            }, ()=>{
                                                toast("Unsuccessful payment", {type: 'error'})
                                            })
                                        }else{
                                            // save receipt
                                            saveReceipt()
                                        }
                                    }                                 
                                }}
                            >
                                <FontAwesomeIcon icon={faSave} /> Save
                            </Button>
                        }
                        
                    </ButtonGroup>
                  }
                </Modal.Footer>
        </Modal>
    )
}