import { Fragment, ReactNode, useContext, useEffect, useState } from "react"
import { Button, Form, FormGroup, FormLabel, FormSelect } from "react-bootstrap"
import { UserData } from "../../dataTypes/user.types"
import { faCircleMinus, faCirclePlus, faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-toastify"
import { STATES_AND_LOCAL_GOVERNMENTS } from "../../constants/STATES.LOCALGOVERNMENT.constant"
import { MARITAL_STATUS, GENDER, ADMIN_ROLE } from "../../constants/UTILITY.constants"
import { CustomPhoneInput } from "../utility/CustomForm.component"
import { userContext } from "../../providers/user.provider"
import { updateFormData } from "../../screens/methods/registration"
import { generalMessage } from "../../dataTypes/notificationMessages.types"
import CustomSpinner from "../utility/CustomSpinner.component"
import { updateUserData } from "./methods"
import { StaffsAndTrustees } from "../../constants/AdminDefinitions.constant"

export const BiodataForm = ({biodata}:{biodata: UserData})=>{
    
    const {currentUser} = useContext(userContext)
    const canUpdate: boolean = (StaffsAndTrustees.includes(currentUser?.adminRole!))
    const isSuper: boolean = (currentUser?.isAdmin! && currentUser?.adminRole===ADMIN_ROLE.SUPER)
    const [formData, setFormData] = useState<UserData>(biodata)
    const [validationError, setValidationError] = useState<generalMessage | null>(null)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    const [lgasOfOrigin, setLgasOfOrigin] = useState<string[]>([])
    const [userPhoneForm, setUserPhoneForm] = useState<ReactNode[]>([
        formData.phoneNumbers.map((phoneNumber, indx)=>{
            return (
                <CustomPhoneInput key={indx} 
                description={indx===0?"Primary phone number*":""}
                valueSetter={(e)=>{
                    updateFormData(e, setFormData, setValidationError, formData, indx)
                    }
                }
                placeholder={indx===0?"Enter primary number":""}
                defaultValue={phoneNumber}
                disabled={!isSuper && indx===0}
            />
            )
        })
    ])

    useEffect(()=>{
        if(formData.stateOfOrigin){
            const state = STATES_AND_LOCAL_GOVERNMENTS.find(s=>s.state===formData.stateOfOrigin)
            setLgasOfOrigin(state?.lgas!)
        }
    }, [formData.stateOfOrigin])
   

    return <Fragment>
        {
            canUpdate &&
            <div className="d-flex justify-content-start mb-4 mt-4">
                {
                    validationError &&
                    <div className="mb-2">
                        <small className={validationError.type}>{validationError.message}</small>
                    </div>
                }
            <Form.Check 
                type="switch"
                label="Deactivate"
                name="isActive"
                className="me-4"
                defaultChecked={formData?.isActive!}
                onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}}
            />

            <Form.Check 
                type="switch"
                label="Assign Administration"
                name="isAdmin"
                defaultChecked={formData?.isAdmin!}
                onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}}
            />
            </div>
        }

            <div className="row">
                <FormGroup className="col-lg-6 mb-4">
                    <FormLabel>
                        First name:
                    </FormLabel>
                    <input placeholder="first name" 
                        type="text" 
                        onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}} 
                        defaultValue={formData?.firstName!} 
                        name="firstName"
                        className="form-control"
                        disabled={!canUpdate}
                    />
                    
                </FormGroup>

                <FormGroup className="col-lg-6 mb-4">
                    <FormLabel>
                        Last name:
                    </FormLabel>
                    <input placeholder="last name" 
                        type="text" 
                        onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}} 
                        defaultValue={formData?.lastName!} 
                        name="lastName"
                        className="form-control"
                        disabled={!canUpdate}
                    />
                    
                </FormGroup>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <FormGroup className="mb-4">
                        <FormLabel>
                            Middle name (optional):
                        </FormLabel>
                        <input placeholder="middle name" 
                            type="text" 
                            onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}} 
                            defaultValue={formData?.middleName!} 
                            name="middleName"
                            className="form-control"
                            disabled={!canUpdate}
                        />
                    </FormGroup>  
                </div>
                <div className="col-lg-6">
                    <FormGroup className="mb-4">
                        <FormLabel>
                            Email:
                        </FormLabel>
                        <input placeholder="email address" 
                            type="email" 
                            onChange={(e)=>{updateFormData(e, setFormData, setValidationError, formData)}} 
                            defaultValue={formData?.email!} 
                            name="email"
                            className="form-control"
                            disabled={!canUpdate}
                        />
                        
                    </FormGroup>
                </div>
            </div>

                <FormGroup className="text-center radio-border d-flex justify-content-between pe-4 ps-4">
                    <FormLabel>
                        Marital status:
                    </FormLabel>
                    {/* <div className=""> */}
                        <Form.Check 
                            name="maritalStatus"
                            type="radio"
                            label="Single"
                            value={MARITAL_STATUS.SINGLE}
                            defaultChecked={formData?.maritalStatus===MARITAL_STATUS.SINGLE}
                            onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }}
                        />
                        <Form.Check 
                            type="radio"
                            label="Maried"
                            name="maritalStatus"
                            value={MARITAL_STATUS.MARRIED}
                            defaultChecked={formData?.maritalStatus===MARITAL_STATUS.MARRIED}
                            onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }}
                        />
                </FormGroup>

                {
                    isSuper &&
                    <FormGroup className="text-center d-flex justify-content-between pe-4 ps-4 radio-border">
                    <FormLabel>
                        Gender:
                    </FormLabel>
                        <Form.Check 
                            type="radio"
                            label="Male"
                            name="gender"
                            value={GENDER.MALE}
                            defaultChecked={formData?.gender===GENDER.MALE}
                            onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }}
                        />
                        <Form.Check 
                            type="radio"
                            label="Female"
                            name="gender"
                            value={GENDER.FEMALE}
                            defaultChecked={formData?.gender===GENDER.FEMALE}
                            onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }}
                        />
                    </FormGroup>
                }

                {
                    isSuper && 
                    <FormGroup className="mb-4 d-flex justify-content-between text-center radio-border pe-3 ps-3">
                    <FormLabel>
                        Nationality:
                    </FormLabel>
                        <Form.Check 
                            type="radio"
                            label="Nigerian"
                            name="nationality"
                            value="Nigerian"
                            defaultChecked={formData?.nationality==="Nigerian"}
                            onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }}
                        />
                        <Form.Check 
                            type="radio"
                            label="Foreigner"
                            name="nationality"
                            value="Foreigner"
                            defaultChecked={formData?.nationality==="Foreigner"}
                            onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }}
                        />
                    </FormGroup>
                }
            
                {
                    isSuper && 
                    <div className="row">
                    {
                        formData.nationality==='Nigerian' &&
                        <div className="col col-6">
                        <FormGroup className="mb-4">
                            <FormLabel>
                                State of origin:
                            </FormLabel>
                            <FormSelect defaultValue={
                                JSON.stringify(STATES_AND_LOCAL_GOVERNMENTS.find(state=>state.state===formData.stateOfOrigin)!)
                            } onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }}  name="stateOfOrigin">
                                <option value="default">state of origin</option>
                            {
                                STATES_AND_LOCAL_GOVERNMENTS.map((stateLGA, index)=>{
                                    return <option key={index} value={JSON.stringify(stateLGA)}>{stateLGA.state}</option>
                                })
                            }
                            </FormSelect>
                            
                        </FormGroup>
                        </div>

                    }
                    {
                    formData?.lgaOfOrigin && 
                        <div className="col col-6">
                        <FormGroup className="mb-4">
                            <FormLabel>
                                LGA of origin:
                            </FormLabel>
                            <FormSelect onChange={(e)=>{
                                updateFormData(e, setFormData, setValidationError, formData)
                            }} defaultValue={formData?.lgaOfOrigin} name="lgaOfOrigin">
                            <option value="default">Choose LGA</option>
                            {
                                lgasOfOrigin?.map((lga, index)=>{
                                    return <option key={index} value={lga}>{lga}</option>
                                })
                            }
                        </FormSelect>
                            
                        </FormGroup>
                        </div>
                    }
                    </div>
                }

        <div className="row">
        <div className="col-6">
                <FormGroup className="mb-4">
                    <FormLabel>
                        Date of birth:
                    </FormLabel>
                    <input className="form-control" title="Member's date of birth" onChange={(e)=>{
                        updateFormData(e, setFormData, setValidationError, formData)
                    }} defaultValue={formData?.dateOfBirth!} name="dateOfBirth" type="date" disabled={!canUpdate} />
                </FormGroup>
            </div>
            {
                formData?.isAdmin && 
                <div className="col-6">
                <FormGroup className="mb-4">
                    <FormLabel>
                        Admin Role:
                    </FormLabel>
                        <FormSelect disabled={!canUpdate} defaultValue={formData?.adminRole!} name="adminRole">
                            <option value="default">Select role</option>
                            <option value={ADMIN_ROLE.PRESIDENT}>President</option>
                            <option value={ADMIN_ROLE.FINANCIAL_SECRETARY}>Financial Secretary</option>
                            <option value={ADMIN_ROLE.GENERAL_SECRETARY}>General Secretary</option>
                            <option value={ADMIN_ROLE.OFFICER}>Officer</option>
                            <option value={ADMIN_ROLE.STAFF}>Staff</option>
                            <option value={ADMIN_ROLE.TREASURER}>Treasurer</option>
                        </FormSelect>
                    </FormGroup>  
            </div>  
            }

        </div>
        
        <div className="row">
            <div className="mb-2 d-flex align-items-start justify-content-start">
                <div className="me-3">
                    <FormLabel>
                        Phone Numbers
                    </FormLabel>
                </div>
    
                <FontAwesomeIcon onClick={
                    ()=>{
                        if(userPhoneForm.length < 3){
                            setUserPhoneForm((previous)=>{
                                return [...previous, <CustomPhoneInput placeholder="Additional number" key={previous.length}  valueSetter={(e)=>{
                                    updateFormData(e, setFormData, setValidationError, formData, previous.length)
                                }} />]
                            })
                        }else{
                            toast("Maximum of 3 numbers reached!", {type: 'warning'})
                        }
                    }
                }  icon={faCirclePlus} className="btn text-success me-1" />
                <FontAwesomeIcon onClick={
                    ()=>{
                        if(userPhoneForm.length > 1){
                            userPhoneForm.splice(userPhoneForm.length-1, 1)
                            setUserPhoneForm([...userPhoneForm])
                            setValidationError(null)
                        }else{
                            toast("You must enter atleast one number", {type: 'warning'})
                        }
                    }
                }  icon={faCircleMinus} className="btn text-danger" />
            </div>
            {userPhoneForm}

            <div className="mt-3">
                {pageIsBusy && <CustomSpinner />}
                <div className="d-flex justify-content-center mt-1">
                    <Button onClick={
                        ()=>updateUserData({setPageIsBusy, formData})
                    } disabled={pageIsBusy || !canUpdate} className="btn-sm" variant="success">
                        <FontAwesomeIcon icon={faSave} className="me-2" /> Save
                    </Button>
                </div>
            </div>
        </div>
    </Fragment>
}