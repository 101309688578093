import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png"
import MessageNotificationComponents from "../../utility/Notification.components";
import ProfilePictureComponent from "../../utility/ProfilePicture.component";

const Header = ({setToggle}:{setToggle: Dispatch<SetStateAction<boolean>>}) => {

    let messages:[]

    return (
        <header id="header" className="header fixed-top d-flex align-items-center primary-background">

            <div className="d-flex align-items-center justify-content-between">
                <Link to="/" className="logo d-flex align-items-center">
                    <img className="header-logo" src={logo} alt="" />
                    <span className="d-none d-lg-block text-success">KISCMS</span>
                </Link>
                <i className="bi bi-list toggle-sidebar-btn text-light" onClick={
                    () => {
                        setToggle(prev=>!prev)
                    }
                }></i>
            </div>

            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">

                    <li className="nav-item d-block d-lg-none">
                        <span className="nav-link nav-icon search-bar-toggle " >
                            <i className="bi bi-search"></i>
                        </span>
                    </li>

                    <MessageNotificationComponents messages={messages!} />
                    <MessageNotificationComponents messages={messages!} icon="bi bi-chat-left-text" />
                    <ProfilePictureComponent />
                </ul>
            </nav>

        </header>
    )
}

export default Header;