import { Button, ButtonGroup, Form, FormGroup, FormLabel, Modal } from "react-bootstrap"
import { useContext, useState } from "react"
import { toast } from "react-toastify"
import { faCancel, faCheckCircle,  faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CustomSpinner from "../utility/CustomSpinner.component"
import { FinanceData, REPAYMENT_SOURCE, VoucherData } from "../../dataTypes/financials"
import { ADMIN_ROLE, OUTFLOW_PURPOSE, VOUCHER_STATUS } from "../../constants/UTILITY.constants"
import { userContext } from "../../providers/user.provider"
import { Vouchers } from "../../models/Vouchers.model"
import { moneyFormatter } from "../../utility/helpers"
import { Payments } from "../../models/Payments.model"
import { Treasurer } from "../../constants/AdminDefinitions.constant"



export const PendingVoucherModal = ({show, onHide, voucher}:{show: boolean, onHide: ()=>void, voucher: VoucherData})=>{

    const initialRef = `REF_${Date.now()}_${voucher.receiver?.reference}_VOUCHER`
    const [paymentDate, setPaymentDate] = useState<Date>(new Date())
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    const {currentUser} = useContext(userContext)
    const [isTransfer, setIsTransfer] = useState<boolean>(false)
    const [paymentData, setPaymentData] = useState<FinanceData>({
        date: paymentDate.getTime(),
        day: paymentDate.getDate(),
        month: paymentDate.getMonth()+1,
        year: paymentDate.getFullYear(),
        transactionRef: '',
        description: voucher.description,
        amount: voucher.amount,
        purpose: voucher.purpose as unknown as  OUTFLOW_PURPOSE,
        memberName: voucher.receiver?.firstName + " "+voucher.receiver?.lastName,
        memberReference: voucher.receiver?.reference!,
        source: REPAYMENT_SOURCE.CASH,
        processor: currentUser?.firstName+" "+currentUser?.lastName,
        processorRef: currentUser?.reference!
    })
    
    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
            >
                <Modal.Header closeButton>
                  <Modal.Title className="d-flex justify-content-between">
                    Voucher Approval
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {
                            (voucher.status===VOUCHER_STATUS.APPROVED && Treasurer.includes(currentUser?.adminRole!)) &&
                            <Form.Check 
                            type="switch"
                            label={isTransfer?"Cash payment":"Transfer payment"}
                            name="isTransfer"
                            className="ms-2"
                            defaultChecked={isTransfer}
                            onChange={(e)=>{
                                setIsTransfer(e.target.checked)
                                if(e.target.checked){
                                    setPaymentDate(new Date())
                                    setPaymentData(prev=>({...prev, transactionRef: initialRef, source: REPAYMENT_SOURCE.TRANSFER}))
                                }else{
                                    setPaymentData(prev=>({...prev, transactionRef: '', source: REPAYMENT_SOURCE.CASH}))
                                }
                            }}
                        />
                        }

                        <div className="d-flex mt-2  form-control bg-grey justify-content-between p-2 align-items-center">
                            <div>Purpose:</div>
                            <div>{voucher.purpose}</div>
                        </div>

                        <div className="d-flex mt-2 form-control bg-grey justify-content-between p-2 align-items-center">
                            <div>Amount:</div>
                            <div>{moneyFormatter(voucher.amount)}</div>
                        </div>

                        {
                            (voucher.status===VOUCHER_STATUS.APPROVED && !isTransfer && Treasurer.includes(currentUser?.adminRole!)) &&
                            <section>
                                <FormGroup className="form-control bg-dark text-white mt-2 mb-2">
                                    <FormLabel>Payment reference:</FormLabel>
                                    <input title="payment reference" className="form-control" onChange={(e)=>{
                                        setPaymentData(prev=>({...prev, transactionRef: e.target.value}))
                                    }} />
                                </FormGroup>

                                <FormGroup className="form-control bg-dark text-white mt-2 mb-2">
                                    <FormLabel>Payment date:</FormLabel>
                                    <input title="payment date" 
                                    defaultValue={new Date().toISOString().slice(0, 10)}
                                    type="date" 
                                    className="form-control" onChange={(e)=>{
                                        setPaymentDate(new Date(e.target.value))
                                    }} />
                                </FormGroup>
                            </section>
                        }
                        
                </Modal.Body>
                <Modal.Footer>
                  {
                    pageIsBusy?
                    <CustomSpinner />:
                    <ButtonGroup>
                        {
                            (voucher.status===VOUCHER_STATUS.RAISED && currentUser?.adminRole===ADMIN_ROLE.PRESIDENT) && 
                            <Button 
                                variant="warning"
                                className="btn-sm"
                                onClick={()=>{
                                    const confirmed = window.confirm("Are you sure you want to approve voucher?")
                                    if(confirmed){
                                        setPageIsBusy(true)
                                        voucher.status = VOUCHER_STATUS.APPROVED
                                        voucher.approver = {
                                            name: currentUser?.firstName+" "+currentUser?.lastName,
                                            date: Date.now(), 
                                            reference: currentUser?.reference!
                                        }
                                        const id = voucher.reference!
                                        delete voucher.reference
                                        const voucherModel = new Vouchers()
                                        voucherModel.update(voucher, id, ()=>{
                                            toast("Voucher has been approved!", {type: 'success'})
                                            onHide()
                                        }, (error)=>{
                                            console.log(error)
                                            onHide()
                                        })
                                    }                                 
                                }}
                            >
                                <FontAwesomeIcon icon={faCheckCircle} /> Approve
                            </Button>
                        }
                        {
                            (voucher.status!==VOUCHER_STATUS.PAID && currentUser?.adminRole===ADMIN_ROLE.PRESIDENT) &&
                            <Button 
                                variant="danger"
                                className="btn-sm"
                                onClick={()=>{
                                    const confirmed = window.confirm("Do you want to cancel this voucher?")
                                    if(confirmed){
                                        setPageIsBusy(true)
                                        voucher.status = VOUCHER_STATUS.CANCELLED
                                        voucher.approver = {
                                            name: currentUser?.firstName+" "+currentUser?.lastName,
                                            date: Date.now(), 
                                            reference: currentUser?.reference!
                                        }
                                        const id = voucher.reference!
                                        delete voucher.reference
                                        const voucherModel = new Vouchers()
                                        voucherModel.update(voucher, id, ()=>{
                                            toast("Voucher has been cancelled!", {type: 'info'})
                                            onHide()
                                        }, (error)=>{
                                            console.log(error)
                                            onHide()
                                        })
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faCancel} /> Cancel
                            </Button>
                        }
                        {
                            (voucher.status===VOUCHER_STATUS.APPROVED && currentUser?.adminRole===ADMIN_ROLE.TREASURER) &&
                            <Button 
                                disabled={paymentData.transactionRef===''}
                                variant="success"
                                className="btn-sm"
                                onClick={()=>{
                                    const confirmed = window.confirm("You are about to payout this voucher!!!")  
                                    if(confirmed){
                                        // TODO: make payment through gateway
                                        setPageIsBusy(true)
                                        voucher.status = VOUCHER_STATUS.PAID
                                        voucher.payer = {
                                            name: currentUser?.firstName+" "+currentUser?.lastName,
                                            date: Date.now(), 
                                            reference: currentUser?.reference!
                                        }
                                        const id = voucher.reference!
                                        delete voucher.reference
                                        const voucherModel = new Vouchers()
                                        voucherModel.update(voucher, id, ()=>{
                                            // save to payment data
                                            const paymentModel = new Payments()
                                            paymentModel.save(paymentData, {
                                                callBack() {
                                                    toast("Payment made to "+voucher.receiver?.firstName, {type: 'success'})
                                                    onHide()
                                                },
                                                errorHander(error) {
                                                    console.log(error)
                                                    onHide()
                                                },
                                            })
                                        }, (error)=>{
                                            onHide()
                                            console.log(error)
                                        })
                                    }                                 
                                }}
                            >
                                <FontAwesomeIcon icon={faMoneyCheckDollar} /> Payout
                            </Button>
                        }
                    </ButtonGroup>
                  }
                </Modal.Footer>
        </Modal>
    )
}