import { Button, FormGroup, FormLabel, FormSelect } from "react-bootstrap"
import { updateFormData } from "../../screens/methods/registration"
import { useContext, useState } from "react"
import { generalMessage } from "../../dataTypes/notificationMessages.types"
import { UserData } from "../../dataTypes/user.types"
import { userContext } from "../../providers/user.provider"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CustomSpinner from "../utility/CustomSpinner.component"
import { updateUserData } from "./methods"
import { StaffsAndTrustees } from "../../constants/AdminDefinitions.constant"

export const EmploymentForm = ({user}:{user:UserData})=>{

    const {currentUser} = useContext(userContext)
    const canUpdate: boolean = (StaffsAndTrustees.includes(currentUser?.adminRole!))
    const [formData, setFormData] = useState<UserData>(user)
    const [validationError, setValidationError] = useState<generalMessage | null>(null)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    
   return (
        <div>
            {
                    validationError &&
                    <div className="mb-2">
                        <small className={validationError.type}>{validationError.message}</small>
                    </div>
                }
                <FormGroup>
                    <FormLabel>
                        Business unit:
                    </FormLabel>
                    <FormSelect defaultValue={formData?.employmentDetail?.businessUnit!} onChange={(e)=>{
                            updateFormData(e, setFormData, setValidationError, formData)
                        }}  name="businessUnit">
                        <option value="default">Select Business unit</option>
                        <option value="KTC">KTC</option>
                        <option value="COPAC">COPAC</option>
                        <option value="KURAMO">KURAMO</option>
                    </FormSelect>
                </FormGroup>  

                <FormGroup>
                    <FormLabel>
                        Department:
                    </FormLabel>
                    <input defaultValue={formData?.employmentDetail?.department!} 
                            placeholder="Enter department" 
                            className="form-control" onChange={(e)=>{
                            updateFormData(e, setFormData, setValidationError, formData)
                        }} name="wokdepartment" />
                    
                </FormGroup>

                <FormGroup>
                    <FormLabel>
                        KIND number (optional):
                    </FormLabel>
                    <input 
                        placeholder="Enter KIND number" 
                        className="form-control" 
                        name="wokKINDNumber"
                        onChange={(e)=>{
                            updateFormData(e, setFormData, setValidationError, formData)
                        }}
                        defaultValue={formData?.employmentDetail?.KINDNumber!}
                    />
                    
                </FormGroup>  
                <FormGroup>
                    <FormLabel>
                        Current salary:
                    </FormLabel>
                    <input placeholder="Enter Salary" 
                        type="number" 
                        name="wokcurrentSalary"
                        defaultValue={formData?.employmentDetail?.currentSalary!} 
                        className="form-control"
                        onChange={(e)=>{
                            updateFormData(e, setFormData, setValidationError, formData)
                        }}
                    />
                    
                </FormGroup>                

            <div className="mt-3">
                {pageIsBusy && <CustomSpinner />}
                <div className="d-flex justify-content-center mt-1">
                    <Button onClick={
                        ()=>updateUserData({setPageIsBusy, formData})
                    } disabled={pageIsBusy || !canUpdate} className="btn-sm" variant="success">
                        <FontAwesomeIcon icon={faSave} className="me-2" /> Save
                    </Button>
                </div>
            </div> 
        </div>
   ) 
}