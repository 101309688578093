import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from "react";
import { UserData } from "../dataTypes/user.types";
import { getSessionKey } from "../configurations/firebase.configuration";



let initialUser: UserData | null = null;
type userContextType = {
    currentUser: UserData | null,
    setCurrentUser?: Dispatch<SetStateAction<UserData | null>>
}

const initialValue: userContextType =  {
    currentUser: initialUser,
}

export const userContext = createContext(initialValue);


export const UserProvider = ({children} : {children: ReactNode}) =>{
    const [currentUser, setCurrentUser] = useState<UserData | null>(null);
    const value = {currentUser, setCurrentUser};

    const session = getSessionKey()
    const handleUserSetter = useCallback((sess: string| null, user: UserData)=>{
        if(sess && user){
         setCurrentUser(user)
        }else{
          setCurrentUser(null)
        }
      }, [setCurrentUser]);

    useEffect(()=>{
        handleUserSetter(session, JSON.parse(sessionStorage.getItem('currentUser')!))
    }, [session, handleUserSetter])

    return (
        <userContext.Provider value={value}>
            {children}
        </userContext.Provider>
    )
}