import { faCamera, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, LegacyRef, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap"
import Webcam from "react-webcam"
import { UserData } from "../../dataTypes/user.types";


export const CustomCamera = ({show, handleClose, setFormData}: {show: boolean, handleClose: ()=>void, setFormData: Dispatch<SetStateAction<UserData>>})=>{

    const [deviceId, setDeviceId] = useState<number>(0);
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

    const handleDevices = useCallback(
        (mediaDevices: MediaDeviceInfo[]) =>
        setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
        [setDevices]
    );

    const webcamRef: LegacyRef<Webcam> = useRef(null);

    useEffect(
        () => {
          navigator.mediaDevices.enumerateDevices().then(handleDevices);
        },
    [handleDevices]
    );

    return (
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
            <div>
                <div className="d-flex justify-content-center"> 
                    {
                        devices.length > 0 && 
                        <Webcam
                            audio={false}
                            height={400}
                            screenshotFormat="image/jpeg"
                            width={400}
                            ref={webcamRef}
                            videoConstraints={{ deviceId: devices[deviceId].deviceId }}
                        /> 
                    }
                </div>
                <ButtonGroup>
                    <Button onClick={()=>{
                        setDeviceId((prev)=>{
                            const current = prev as number
                            if(devices.length > current + 1){
                                return current + 1
                            }else{
                                return 0
                            }
                        })
                    }} className="btn-sm" variant="info">
                      <FontAwesomeIcon icon={faRotate} />  Switch Camera
                    </Button>
                    <Button onClick={
                        ()=>{
                            const imageSrc = webcamRef.current?.getScreenshot()
                            if(imageSrc){
                                setFormData((prev)=>({...prev, photoUrl: imageSrc}))
                                // handleClose(true)
                            }
                        }
                    } className="btn-sm" variant="success">
                        <FontAwesomeIcon icon={faCamera} />    Snap
                    </Button>
                </ButtonGroup>
            </div>
        </Modal.Body>
      </Modal>
    )
}