import { faCheckCircle, faXmarkCircle, faEdit, faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, FormGroup } from "react-bootstrap"
import { toast } from "react-toastify"
import { ADMIN_ROLE, LOANSTATUS } from "../../constants/UTILITY.constants"
import { Loans } from "../../models/Loans.model"
import { moneyFormatter } from "../../utility/helpers"
import CustomSpinner from "../utility/CustomSpinner.component"
import { LoanGuarantorData, UserData } from "../../dataTypes/user.types"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { Loan } from "../../dataTypes/financials"
import { userContext } from "../../providers/user.provider"
import { Trustees } from "../../constants/AdminDefinitions.constant"

// can delete loan
const canDelete = (loan: Loan, currentUser: UserData):boolean => {
    return [
        LOANSTATUS.UNGUARANTEED, 
        LOANSTATUS.PARTIALLY_GUARANTEED,
    ].includes(loan.status) && Trustees.includes(currentUser.adminRole)
}


export const GuarantorInfoLabel = ({guarantor, loan, index, loanSetter}: {
    guarantor: LoanGuarantorData, loan: Loan, index: number, 
    loanSetter: Dispatch<SetStateAction<Loan>>
})=>{

    const {currentUser} = useContext(userContext)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    const [isInput, setIsInput] = useState<boolean>(false)
    const sumGuaranteed = loan.guarantors.length > 0 ?loan.guarantors.reduce((prev, curr)=>
            ({...curr, sumRequest: +curr.sumRequest+prev.sumRequest})).sumRequest:0

    
    return (
        <Card>
            <Card.Header>
                <div className="d-flex justify-content-between">
                    <div>{guarantor.displayName}</div>
                    {guarantor.approved && <div className="btn btn-success btn-sm">Approved</div>}
                </div>
            </Card.Header>
            <Card.Body>
                {
                    isInput ?
                    <FormGroup className="form-control d-flex bg-dark">
                            <input className="form-control me-2" onChange={(e)=>{
                                // guaranteed sum should not exceed required sum
                                const value = e.target.value
                                const availableAmount = +sumGuaranteed - guarantor.sumRequest
                                if(isNaN(parseInt(value)) || parseInt(value) <= 0){
                                    toast("enter a valid amount", {type: 'error'})
                                }else if((+availableAmount+parseInt(value)) > loan.guaranteeRequest){
                                    toast(`Request should not be more than ${
                                        moneyFormatter(+loan.guaranteeRequest - availableAmount)
                                    }`)
                                }else{
                                    loanSetter(prev=>{
                                        const guarantors = prev.guarantors
                                        guarantors[index] = {
                                            ...guarantor, 
                                            sumRequest: parseInt(value)
                                        }
                                        
                                        return {...prev, guarantors}
                                    })
                                }
                            }} title="guarantee request" type="number" defaultValue={guarantor.sumRequest} />
                        <Button onClick={()=>{
                            setPageIsBusy(true)
                            if(sumGuaranteed < loan.guaranteeRequest){
                                loan.status = LOANSTATUS.PARTIALLY_GUARANTEED
                            }else{
                                loan.status = LOANSTATUS.GUARANTEED
                            }
                            const model = new Loans()
                            const id = loan.reference!
                            delete loan.reference
                            model.update({...loan, approvals: Object.fromEntries(loan.approvals)}, id, ()=>{
                                toast("Guarantor request updated", {type: 'success'})
                                setIsInput(false)
                                setPageIsBusy(false)
                            }, (e)=>{console.log(e)})
                        }} variant="success" className="btn-sm icon-btn text-white"><FontAwesomeIcon icon={faSave} /></Button>
                    </FormGroup>:
                    <div className="d-flex justify-content-between btn btn-light mt-2 mb-2">
                        <div>Request:</div>
                        <div>&#x20A6;{moneyFormatter(Math.ceil(guarantor.sumRequest))}</div>
                    </div>
                }
                <div className="d-flex justify-content-between btn btn-light mt-2 mb-2">
                    <div>Offset:</div>
                    <div>&#x20A6;{moneyFormatter(Math.ceil(guarantor.requestOffset??0))}</div>
                </div>
                {
                    guarantor.approved && 
                    <div className="d-flex justify-content-between btn btn-light mt-2 mb-2">
                        <div>Approval date:</div>
                        <div>{new Date(guarantor.approvalDate!).toDateString()}</div>
                    </div>
                }
                {
                    pageIsBusy?
                    <CustomSpinner />:
                    <div className="d-flex justify-content-between mt-3">
                        {
                           ( !guarantor.approved) &&
                            <div onClick={()=>{
                                setPageIsBusy(true)
                                // confirm if guarantor conscent
                                if(window.confirm("Have you gotten Guarantor's consent?")){
                                    guarantor.approved = true
                                    guarantor.approvalDate = Date.now()
                                    // update loan guarantors
                                    loan.guarantors[index] = guarantor
                                    // match required guarantee to sum guaranteed and change loan status
                                    const unGuaranteed = loan.guarantors.find(g=>g.approved===false)
                                    if(unGuaranteed || sumGuaranteed < loan.guaranteeRequest){
                                        loan.status = LOANSTATUS.PARTIALLY_GUARANTEED
                                    }else{
                                        loan.status = LOANSTATUS.GUARANTEED
                                    }

                                    const model = new Loans()
                                    const id = loan.reference
                                    delete loan.reference
                                    model.update({...loan, approvals: Object.fromEntries(loan.approvals)}, id!, ()=>{
                                        toast(`Loan guaranteed by ${guarantor.displayName}`)
                                        setPageIsBusy(false)
                                    }, (error)=>{
                                        console.log("Error guaranteeing loan ", error)
                                        setPageIsBusy(false)
                                    })
                                }

                                }} title="Approve"><FontAwesomeIcon className="btn btn-success icon-btn" icon={faCheckCircle} />
                            </div>
                        }

                        {
                            canDelete(loan, currentUser!) &&
                            <div onClick={()=>{
                                // decline guarantor consent
                                if(window.confirm("Did guarantor consent to decline?")){
                                    setPageIsBusy(true)
                                    // delete guarantor from list of guarantors
                                    loan.guarantors.splice(index, 1)
                                    loan.guarantorList.splice(loan.guarantorList.indexOf(guarantor.reference), 1)
                                    // check if there are other approved guarantors
                                    const approvedGuarantors = loan.guarantors.find(g=>g.approved && g.approved===true)
                                    if(approvedGuarantors){
                                        if(sumGuaranteed < loan.guaranteeRequest){
                                            loan.status = LOANSTATUS.PARTIALLY_GUARANTEED
                                        }else{
                                            loan.status = LOANSTATUS.GUARANTEED
                                        }
                                    }else{
                                        loan.status = LOANSTATUS.UNGUARANTEED
                                    }
                                    // update loan
                                    const model = new Loans()
                                    const id = loan.reference
                                    delete loan.reference
                                    model.update({...loan, approvals: Object.fromEntries(loan.approvals)}, id!, ()=>{
                                        setPageIsBusy(false)
                                        toast(`${guarantor.displayName} removed from guarantor`)
                                    }, (e)=>{
                                        setPageIsBusy(false)
                                        console.log('unable to delete guarantor ', e)
                                    })
                                }
                            }} title="Decline">
                                <FontAwesomeIcon className="btn btn-danger icon-btn" icon={faXmarkCircle} />
                            </div>
                            }
                        {(!guarantor.approved && sumGuaranteed < loan.guaranteeRequest) && <div onClick={()=>{
                            setIsInput(true)
                        }} title="Update"><FontAwesomeIcon className="btn btn-info icon-btn" icon={faEdit} /></div>}
                    </div>
                }
            </Card.Body>
        </Card>
    )
}