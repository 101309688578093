import { FormSelect } from "react-bootstrap"
import { LoanConfiguration, LoanPlans } from "../../dataTypes/financials"
import { ChangeEvent } from "react"

const loanInstalments: number[] = [1, 6, 12, 18, 24, 36, 48, 60]
const loanRates: number[] = Array.from({length: 30}, (_, index)=>+index+1)

export const LoanPlanForm = ({currentInstalment, currentRate, position, changeHandler}:
    {currentInstalment: number, currentRate: number, position: number, changeHandler:(e:ChangeEvent<HTMLSelectElement | HTMLInputElement>, indx: number)=>void})=>{
        // console.log('plan: ', plans[formPosition], 'index: ', formPosition)
        
   return (
    <div className="row mb-3 bg-grey pt-2 pb-2">
        <div className="col-6">
        <FormSelect defaultValue={currentInstalment} 
        onChange={(e)=>changeHandler(e, position)}  
        name="instalments">
        <option value="default">Set Instalments</option>
    {
        loanInstalments.map((instalment, index)=>{
            return <option key={index} value={instalment}>{instalment}</option>
        })
    }
    </FormSelect>
        </div>
        <div className="col-6">
         <input placeholder="Enter loann interest"
            type="number"
            step={0.01} 
            onChange={(e)=>changeHandler(e, position)}
            defaultValue={currentRate} 
            name="rate"
            className="form-control"
        />
        </div>
    </div>
   ) 
}