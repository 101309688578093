import { Modal, Button, FormGroup, FormLabel, ButtonGroup } from "react-bootstrap";
import { RegistrationPaymentItem } from "../../dataTypes/financials";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { generalMessage } from "../../dataTypes/notificationMessages.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { moneyFormatter } from "../../utility/helpers";
import { confirmPayment, deactivateMember, getConfigItems, updateRegFee } from "./method";
import { itemConfig } from "../../dataTypes/customTypes/varTypes";
import CustomSpinner from "../utility/CustomSpinner.component";
import { useInitializePayment } from "../../constants/configuration.constants";
import { userContext } from "../../providers/user.provider";
import { UserData } from "../../dataTypes/user.types";

export const PaymentModal = ({show, onHide, param, setMsg, payer}:{
    show: boolean, 
    onHide: ()=>void, 
    param: RegistrationPaymentItem,
    setMsg: Dispatch<SetStateAction<generalMessage | null>>
    payer: UserData
})=>{

    const {currentUser} = useContext(userContext)
    const paymentItems = ['shares', 'registration', 'savings']
    const [error, setError] = useState<string | null>(null)
    const [paymentConfigurations, setPaymentConfigurations] = useState<itemConfig>(getConfigItems(
        paymentItems, 
        param, 
        currentUser!, 
        payer))
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)

    const onRelease = ()=>{
        setPageIsBusy(false)
        onHide()
    }

    // make payment on paystack
    const initializePayment = useInitializePayment(param.email, 
        (+paymentConfigurations.registration.amount+
        paymentConfigurations.savings.amount+
        paymentConfigurations.shares.amount
        )*100)
    
    

    return (
        <Modal
          centered
          show={show}
          onHide={onHide}
        >
          <Modal.Header>
            <Modal.Title>
              Member Registration Fee
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && <small className="text-center text-danger">{error}</small>}
            {
                paymentItems.map((item, key)=>{
                    return (
                        <FormGroup key={key} className="mb-2 mt-2">
                            <FormLabel>
                            {item==='registration'?"Registration fee:":(item==='savings'?"Initial savings":"Share capital")}
                            </FormLabel>
                            <input type="number" className="form-control" title={
                                item==='registration'?"Registration fee:":(item==='savings'?"Initial savings":"Share capital")
                            } onChange={(e)=>{
                                updateRegFee(e, setError, setPaymentConfigurations)
                            }} defaultValue={
                                    item==='registration'?paymentConfigurations.registration.amount:
                                    (item==='savings'?paymentConfigurations.savings.amount:
                                    paymentConfigurations.shares.amount)
                                } name={item} />
                        </FormGroup> 
                    )
                })
            }
            <div className="d-flex justify-content-between mt-4">
                <h5>Total:</h5>
                <h2 className="text-danger">&#8358;{
                moneyFormatter(
                    +paymentConfigurations.savings.amount+
                    paymentConfigurations.registration.amount+
                    paymentConfigurations.shares.amount
                )}</h2>
            </div>    
          </Modal.Body>
          <Modal.Footer>
            {pageIsBusy && <CustomSpinner />}
                <ButtonGroup>
                    <Button disabled={pageIsBusy} onClick={()=>{
                        setError(null)
                        setPageIsBusy(true)
                        initializePayment(()=>{
                            confirmPayment(paymentConfigurations, setMsg, onRelease, param.memberPassword, `${payer.firstName} ${payer.lastName}`, currentUser!)
                        }, ()=>{
                            setError("Unable to complete registration payment")
                            // deactivate user and close modal
                            deactivateMember(param.memberId, onHide)
                        })
                        
                    }} variant="success" className="btn-sm" >
                    <FontAwesomeIcon icon={faMoneyBill} className="me-1" /> Make payment 
                    </Button>
                    <Button disabled={pageIsBusy} onClick={()=>{
                        setError(null)
                        setPageIsBusy(true)
                        confirmPayment(paymentConfigurations, setMsg, onRelease, param.memberPassword, `${payer.firstName} ${payer.lastName}`, currentUser!)
                    }} className="btn-sm" >
                        <FontAwesomeIcon icon={faCircleCheck} className="me-1" /> Confirm payment
                    </Button>
                </ButtonGroup>
            {/* </div> */}
          </Modal.Footer>
        </Modal>
    )
}
