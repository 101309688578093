import { Fragment } from "react"
import { KISCMSInfo } from "../../dataTypes/user.types"
import { moneyFormatter } from "../../utility/helpers"

export const CooperativeInfoDisplay = ({info, accountNumber}:{info: KISCMSInfo, accountNumber: string})=>{
    return (
        <Fragment>
            <div>
                <small className="text-grey">KISCMS number:</small>
                <div>{info.KISCMSNumber}</div>
            </div>

            <div className="mt-3">
                <small className="text-grey">Account number:</small>
                <div>{accountNumber}</div>
            </div>

            <div className="mt-3">
                <small className="text-grey">Date joined:</small>
                <div>{new Date(info.dateJoined).toLocaleDateString()}</div>
            </div>

            <div className="mt-3">
                <small className="text-grey">Monthly savings:</small>
                <div>N{moneyFormatter(info.monthlySavings)}</div>
            </div>
        </Fragment>
    )
}