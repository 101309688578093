import { useContext, useState } from "react"
import { Outlet } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import Footer from "../components/main/footer/Footer.component"
import Header from "../components/main/header/Header.component"
import Sidebar from "../components/main/sidebar/Sidebar.component"
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider, userContext } from "../providers/user.provider"
import { useIdleTimer } from "react-idle-timer"
import { logout } from "../screens/methods/registration"


const Admin = ()=>{
    const [toggle, setToggle] = useState<boolean>(true)
    const {setCurrentUser} = useContext(userContext)

    const onIdle = () => {
        toast("End of session!", {type: "info"})
        logout(setCurrentUser!)
      }

    useIdleTimer({
        onIdle,
        timeout: 900_000,
        throttle: 500
    })

    return (
        <UserProvider>
            <section className={toggle?"":"toggle-sidebar"}>
                <Header setToggle={setToggle} />
                <Sidebar />
                <main id="main" className="main">
                    <ToastContainer />
                    <Outlet />
                </main>
                <Footer />
            </section>
        </UserProvider>
    )
}

export default Admin