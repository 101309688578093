import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { moneyFormatter } from "../../utility/helpers"
import { Loan } from "../../dataTypes/financials"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { UserData } from "../../dataTypes/user.types"
import { GuarantorLabel } from "./GuarantorLabel.component"

export const LoanGuarantorForm = ({
    unguaranteedSum, 
    setIsGuaranteed,
    setError,
    setRequest,
    isGuaranteed,
    applicant
}: {
    unguaranteedSum: number,
    setIsGuaranteed: Dispatch<SetStateAction<boolean>>,
    setError: Dispatch<SetStateAction<string | null>>
    setRequest: Dispatch<SetStateAction<Loan>>,
    isGuaranteed: boolean,
    applicant: UserData
})=>{
    const [unguaranteed, setUnguaranteed] = useState<number>(unguaranteedSum)
    const [labelIndex, setLabelIndex] = useState<number[]>([0])
    const [allRequests, setAllRequests] = useState<Map<string, number>>(new Map()) 

    useEffect(()=>{
        // check if loan if fully guaranteed
        let totalGuarantee = 0
        allRequests.forEach(value=>{
            const amount = value??0
            totalGuarantee+=amount
        })
        setIsGuaranteed(unguaranteedSum===totalGuarantee)
        setUnguaranteed((unguaranteedSum-totalGuarantee)<=0?0:unguaranteedSum-totalGuarantee)

    }, [unguaranteed, allRequests, setIsGuaranteed, unguaranteedSum])

    return (
        <div>
            <div className="text-center">
                <h6 className="me-2 text-danger">Unguaranteed Sum:</h6>
                <div className="display-3">&#x20A6;{moneyFormatter(unguaranteed)} </div>
            </div>
            {
                !isGuaranteed &&
                <div className="ps-2 mb-1">
                    <Button onClick={()=>{
                        setLabelIndex(prev=>[...prev, prev.length])
                    }} variant="warning" className="btn-sm">
                        Add Guarantor <FontAwesomeIcon className="ms-2" icon={faPlusCircle} /> 
                    </Button>
                </div>
            }
            <div>
                {
                  labelIndex.map(indx=><GuarantorLabel 
                        key={indx}
                        setError={setError}
                        setRequest={setRequest}
                        // index={indx}
                        setAllRequests={setAllRequests}
                        allGuarantor={allRequests}
                        requiredAmount={unguaranteedSum}
                        applicant={applicant}
                    />)  
                }
            </div>
        </div>
    )
}