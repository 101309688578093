import {
    RouterProvider,
  } from "react-router-dom";

import 'bootstrap/dist/js/bootstrap.min'
import { adminRouter } from "./navigation/AdminRoute";


// Bootstrap dependencies
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

// Custom CSS
import './assets/styles/style.css'
import Login from "./wrapper/Login.wrapper";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { getSessionKey } from "./configurations/firebase.configuration";
import { UserData } from "./dataTypes/user.types";

const App = ()=>{

  
  const [currentPage, setCurrentPage] = useState<ReactNode>(<div>Loading</div>)
  const session = getSessionKey()
  

  const handlePageSetter = useCallback((sess: string| null, user: UserData)=>{
    if(sess && user && user.isAdmin){
      setCurrentPage(
          <RouterProvider router={adminRouter} />
      )
    }else{
      setCurrentPage(<Login setCurrentPage={setCurrentPage} />)
    }
  }, [setCurrentPage]);

  const u = JSON.parse(sessionStorage.getItem('currentUser')!)
  useEffect(()=>{
    // sessionStorage.clear()
      handlePageSetter(session, u)
  }, [session, handlePageSetter])
    return (
      <>
        {
          currentPage
        }
      </>
    )
}

export default App