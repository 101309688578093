import { ADMIN_ROLE } from "./UTILITY.constants";

export const Trustees: ADMIN_ROLE[] = [
    ADMIN_ROLE.PRESIDENT,
    ADMIN_ROLE.SUPER,
    ADMIN_ROLE.GENERAL_SECRETARY,
    ADMIN_ROLE.TREASURER
]

export const CoreExecutives: ADMIN_ROLE[] = [
    ...Trustees,
    ADMIN_ROLE.FINANCIAL_SECRETARY
]

export const StaffsAndTrustees: ADMIN_ROLE[] = [
    ADMIN_ROLE.STAFF,
    ...Trustees
]

export const StaffsAndCoreExco: ADMIN_ROLE[] = [
    ...StaffsAndTrustees,
    ADMIN_ROLE.FINANCIAL_SECRETARY
]

export const staffs: ADMIN_ROLE[] = [
    ADMIN_ROLE.STAFF,
    ADMIN_ROLE.SUPER
]

export const Treasurer: ADMIN_ROLE[] = [
    ADMIN_ROLE.TREASURER,
    ADMIN_ROLE.SUPER
]

export const Secretariat: ADMIN_ROLE[] = [
    ADMIN_ROLE.STAFF,
    ADMIN_ROLE.GENERAL_SECRETARY,
    ADMIN_ROLE.SUPER
]

export const StaffFinSec: ADMIN_ROLE[] =[
    ADMIN_ROLE.STAFF,
    ADMIN_ROLE.FINANCIAL_SECRETARY,
    ADMIN_ROLE.SUPER,
    ADMIN_ROLE.GENERAL_SECRETARY
]

