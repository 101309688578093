import { ADMIN_ROLE, GENDER, MARITAL_STATUS, MEMBERSHIP_STATUS, NEXTOFKIN_RELATIONSHIP } from "../constants/UTILITY.constants"

export type UserData = {
    firstName: string,
    lastName: string,
    middleName?: string
    reference?: string,
    email: string,
    phoneNumbers: string[],
    accountNumber: string,
    isActive: boolean,
    isAdmin: boolean,
    photoUrl: string,
    maritalStatus: MARITAL_STATUS,
    gender: GENDER,
    nationality: string,
    dateOfBirth: string,
    stateOfOrigin?: string,
    lgaOfOrigin?: string,
    contactAddress: Address,
    nextOfKin: NextOfKin,
    adminRole: ADMIN_ROLE,
    employmentDetail: UserEmploymentInfo,
    bankAccoundDetail: BankInfo,
    KISCMSInfo: KISCMSInfo,
    status: MEMBERSHIP_STATUS,
    createdBy: string,
    needResetPassword: boolean
}

export type UserEmploymentInfo = {
    department: string,
    businessUnit: string,
    KINDNumber?: string,
    currentSalary: number
}

export type Address = {
    street: string,
    nearestBusStop: string,
    state: string,
    localGovernmentArea: string
}

export type BankInfo = {
    bankName: string,
    accountNumber: string,
    bankCode?: string,
    accountName?: string
}

export type KISCMSInfo = {
    dateJoined: number,
    KISCMSNumber: string,
    monthlySavings: number
}

export type NextOfKin = {
    firstName: string,
    lastName: string,
    email?: string,
    phoneNumber: string,
    middleName?: string
    relationship: NEXTOFKIN_RELATIONSHIP
}

export type BiometricData = {
    leftThumb: string,
    rightThumb: string,
    leftIndex: string,
    rightIndex: string
}

export type MembershipWithdrawalData = {
    reference?: string,
    memberReference: string,
    savings: number
}

export type LoanGuarantorData = {
    reference: string,
    displayName: string,
    sumRequest: number,
    approved: boolean,
    approvalDate?: number,
    requestOffset: number,
    accountNumber: string
}

export type FunctionReturn = {
    success: boolean,
    data?: any
}

export const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };

export type AccountVerificationRequest = {
    bankCode: string,
    accountNumber: string
}

export type UserAccount = {
    accountNumber: string,
    accountName : string,
    bankId: number,
    bankCode: string,
    success?: boolean
}

// export const trustees:ADMIN_ROLE[] = [
//     ADMIN_ROLE.GENERAL_SECRETARY,
//     ADMIN_ROLE.TREASURER,
//     ADMIN_ROLE.PRESIDENT,
//     ADMIN_ROLE.SUPER
// ]

// export const administrators: ADMIN_ROLE[] = [
//     ADMIN_ROLE.FINANCIAL_SECRETARY,
//     ADMIN_ROLE.TREASURER,
//     // ADMIN_ROLE.PRESIDENT,
//     ADMIN_ROLE.SUPER,
//     ADMIN_ROLE.STAFF
// ]

export type Editor = {
    name: string,
    date: number,
    reference: string
}


export const membershipStatusColor = (status: MEMBERSHIP_STATUS): string =>{
    let stat = 'bg-success'
    switch (status) {
        case MEMBERSHIP_STATUS.FROZEN:
            stat = 'bg-info'
            break;

    case MEMBERSHIP_STATUS.PENDING_WITHDRAWAL:
        stat = 'bg-warning'
        break;

    case MEMBERSHIP_STATUS.WITHDRAWN:
        stat = 'bg-danger'
        break;
    }
    return stat
}

