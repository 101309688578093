import { Modal, Button } from "react-bootstrap"
import {  Loan } from "../../dataTypes/financials"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle, faSave } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import { GuarantorInfoLabel } from "./GuarantorInfoLabel.component"
import { GuarantorLabel } from "./GuarantorLabel.component"
import { LoanGuarantorData, UserData } from "../../dataTypes/user.types"
import { Loans } from "../../models/Loans.model"
import { toast } from "react-toastify"
import CustomSpinner from "../utility/CustomSpinner.component"
import { LOANSTATUS } from "../../constants/UTILITY.constants"
import { moneyFormatter } from "../../utility/helpers"



export const LoanGuarantorInfo = ({show, onHide, loan, applicant}: {show: boolean, onHide: ()=>void, loan: Loan, applicant: UserData})=>{

    const [currentLoan, setCurrentLoan] = useState<Loan>(loan)
    const sumGuaranteed = currentLoan.guarantors.length > 0 ?
        currentLoan.guarantors.reduce((prev, curr)=>
        ({...curr, sumRequest: +curr.sumRequest+prev.sumRequest})).sumRequest:0
    
    const [addGuarantor, setAddGuarantor] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const allRequests = new Map()
        if(loan.guarantors.length > 0){
            loan.guarantors.forEach(guarantor=>{
                allRequests.set(guarantor.accountNumber, guarantor.sumRequest)
            })
        }
    const [allGuarantor, setAllRequests] = useState<Map<string, number>>(allRequests)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    const [currentGuarantor, setCurrentGuarantor] = useState<LoanGuarantorData | null>(null)

    



    return (
        <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        centered
        >
        <Modal.Header closeButton>
            <div className="me-3">Loan Guarantors' Information</div>
            {(sumGuaranteed < loan.guaranteeRequest) && <div>
                <Button onClick={()=>{
                    setAddGuarantor(true)
                }} className="icon-btn text-center btn btn-sm me-5" title="Add new guarantor">
                    <FontAwesomeIcon  icon={faPlusCircle} />
                </Button>
                
            </div>}
            <div>
            {
                    pageIsBusy ?
                    <CustomSpinner />:
                    <>
                        {
                            currentGuarantor &&
                            <>
                                <Button disabled={sumGuaranteed <=0} onClick={()=>{
                                    setPageIsBusy(true)
                                    //change guarantor status
                                    // const currentGuarantor = loan.guarantors.find(gur=>gur.reference==='')
                                    const notGuaranteed = sumGuaranteed < loan.guaranteeRequest
                                    if(notGuaranteed){
                                        loan.status = LOANSTATUS.PARTIALLY_GUARANTEED
                                    } else {
                                        const nonApproved = loan.guarantors.find(gu=>gu.approved===false)
                                        loan.status = nonApproved?LOANSTATUS.PARTIALLY_GUARANTEED:LOANSTATUS.GUARANTEED
                                    }
                                    
                                    const model = new Loans()
                                    const id = loan.reference!
                                    model.update(loan, id, ()=>{
                                        setPageIsBusy(false)
                                        toast("Guarantee request updated", {type: 'success'})
                                        setCurrentGuarantor(null)
                                        onHide()
                                    }, (e)=>{
                                        setPageIsBusy(false)
                                        setCurrentGuarantor(null)
                                        console.log('unable to update loan: ', e)
                                    })
                                }} className="btn-sm btn-success">
                                    <FontAwesomeIcon  icon={faSave} /> Save
                                </Button>
                                <p className="text-center text-danger"><small>Update changes</small></p>
                            </>
                           
                        }
                    </>
                }
            </div>
        </Modal.Header>
        <Modal.Body>
            {error && <p className="p-2 bg-warning text-danger">{error}</p>}
            {(loan.guaranteeRequest-sumGuaranteed > 0) && 
                <div className="bg-success text-white text-center p-2">
                   Unguaranteed sum: &#8358;{moneyFormatter(loan.guaranteeRequest-sumGuaranteed)}
                </div>}
            {
                addGuarantor &&
                <GuarantorLabel 
                    setError={setError}
                    setAllRequests={setAllRequests}
                    setRequest={setCurrentLoan}
                    requiredAmount={loan.guaranteeRequest}
                    applicant={applicant}
                    allGuarantor={allGuarantor}
                    setCurrentGuarantor={setCurrentGuarantor}
                    isUpdate={true}
                />
            }
           {
            loan.guarantors.map((guarantor, index)=>{
                return (
                    <GuarantorInfoLabel 
                        loan={currentLoan} 
                        key={index} 
                        index={index} 
                        guarantor={guarantor} 
                        loanSetter={setCurrentLoan}
                    />
                )
            })
           }
        </Modal.Body>
        <Modal.Footer>
            
        </Modal.Footer>
        </Modal>
           
)
}