import { 
    collection, 
    addDoc, 
    setDoc, 
    doc, 
    updateDoc, 
    deleteDoc, 
    getDocs, 
    DocumentData, 
    QueryDocumentSnapshot, 
    getDoc, 
    DocumentSnapshot, 
    onSnapshot, 
    query,
    where,
    limit,
    orderBy,
    startAfter,
    QueryConstraint,
    DocumentReference,
    CollectionReference,
    increment,
    getCountFromServer,
    writeBatch,
} from "firebase/firestore";
import { firestorDB } from "../configurations/firebase.configuration";

import { whereClause } from "../dataTypes/firebasequery.types";
import { Model} from "../dataTypes/model.interface";
import { dbItems } from "../dataTypes/customTypes/varTypes";


export class BaseModel implements Model {

    // Get a new write batch
    private batch = writeBatch(firestorDB);

    /**
     * save multiple documents
     * @param param0 
     */
    saveBatch({ data, callBack, errorHandler }: { data: object[], callBack: () => void, errorHandler: (error?: any) => void }): void {
        const obj = data as dbItems[]
        obj.forEach((document)=>{
            const docRef = document.reference? 
            doc(firestorDB, this.table, document.reference): 
            doc(collection(firestorDB, this.table))
            if(document.reference){
                delete document.reference
            }
            this.batch.set(docRef, document)
        })
        this.batch.commit().then(callBack).catch(errorHandler)
    }

    /**
     * update multiple documents
     * @param param0 
     */
    updateBatch({ data, callBack, errorHandler }: { data: object[]; callBack: () => void; errorHandler: (error?: any) => void; }): void {
        const obj = data as dbItems[]
        obj.forEach((document)=>{
            const docRef = doc(firestorDB, this.table, document.reference!)
            delete document.reference
            this.batch.update(docRef, document as object)
        })
        this.batch.commit().then(callBack).catch(errorHandler)
    }

    /**
     * delete multiple documents
     * @param param0 
     */
    deleteBatch({ ids, callBack, errorHandler }: { ids: string[]; callBack: () => void; errorHandler: (error?: any) => void; }): void {
        ids.forEach((id)=>{
            const docRef = doc(firestorDB, this.table, id)
            this.batch.delete(docRef)
        })
        this.batch.commit().then(callBack).catch(errorHandler)
    }
    
    // Database table name
    protected readonly table: string = '';

    // offset data
    offset?: QueryDocumentSnapshot<DocumentData>;

    // all query
    // allQuery?: DocumentData[]

    // current query
    protected currentQuery?: DocumentSnapshot<DocumentData>

    /**
     * Get realtime update from the database
     * @param id 
     * @param callBack 
     * @param errorHander 
     */
    stream(callBack: (data: DocumentData | DocumentData[])=> void, errorHander: (error?: unknown)=>void, id?: string,   ): void {
       
        const ref:DocumentReference<DocumentData> | CollectionReference<DocumentData> = id? 
            doc(firestorDB, this.table, id):
            collection(firestorDB, this.table)
       try {
            if(id){
                onSnapshot(ref as DocumentReference, (doc) => {
                    callBack({...doc.data()!, reference: ref.id})
                })
            }else{
                onSnapshot(ref as CollectionReference, (snapShot) => {
                    callBack(snapShot.docs.map((value)=>{
                        const data = {...value.data(), reference: value.id}
                        return data
                    }))
                })
            }
       } catch (error) {
            errorHander(error)
       }
    }

    /**
     * Get realtime value from database with where clause
     * @param wh 
     * @param callBack 
     * @param errorHander 
     * @param lim 
     * @param order 
     */
    streamWhere(wh: whereClause[], callBack: (data: DocumentData[])=>void,  errorHander: (error?: unknown)=>void, lim?:number, order?: string): void {
        const whereParameter = wh.map(clause=> where(
            clause.key, 
            clause.operator, 
            clause.value))
        let constraint: QueryConstraint[] = []
        // add where parameter
        if(wh){
            constraint.push(...whereParameter)
        }
        // add order by
        if(order){
            constraint.push(orderBy(order))
        }
        // add offset
        if(this.offset){
            constraint.push(startAfter(this.offset))
        }
        // add limit
        if(lim){
            constraint.push(limit(lim))
        }
        const ref: CollectionReference<DocumentData> = collection(firestorDB, this.table, )
       try {
            onSnapshot(query(
                ref,  
                ...constraint
            ), (snapShot) => {
                callBack(snapShot.docs.map((value)=>{
                    const data = {...value.data(), reference: value.id}
                    return data
                }))
            })
       } catch (error) {
            errorHander(error)
       }
    }

    /**
     * Fetch a single item from database
     * @param id 
     * @param converter 
     * @param errorHandler 
     */
    async find(id: string, callBack: (data?:DocumentData)=> void, errorHandler: (error?: any)=>void) {
        const ref = doc(firestorDB, this.table, id)
        try {
            const docSnap = await getDoc(ref)
            if (docSnap.exists()) {
                callBack({...docSnap.data(), reference: ref.id})
            } else {
                callBack(undefined)
            }
        } catch (error) {
            if(errorHandler){
                errorHandler(error)
            }
        }
    }

    /**
     * Update part of a data
     * @param data 
     * @param id 
     * @returns 
     */
    async update(data: object, id: string, callBack:()=>void, errorHander:(error?: any)=>void) {
        const docRef = doc(firestorDB, this.table, id)
        await updateDoc(docRef, data)
        .then(callBack).catch(errorHander)
    }
    
    /**
     * Get all items from database
     * @returns void
     */
     findAll(callBack:(data: DocumentData [])=>void, errorHander:(error?: unknown)=>void) {
        getDocs(collection(firestorDB, this.table)).then((querySnapshot)=>{
            if(!querySnapshot.empty){
                const all = querySnapshot.docs.map((document)=>{
                    const d = {...document.data(), reference: document.id}
                    return d
                })
                callBack(all)
            }else{
                callBack([])
            }
        }).catch(error=>errorHander(error))
    }
    
    /**
     * perform complex query
     * @param param0 
     */
   async findWhere( {wh, lim, order, callBack, errorHandler}: 
        {wh?: whereClause[], lim?:number, order?:string, callBack:(data: DocumentData[])=>void, errorHandler:(error?: any)=>void}) {
        // get Collection reference
        const colRef = collection(firestorDB, this.table)
        // set where clause
        const whereParameter = wh?wh.map(clause=> where(
            clause.key, 
            clause.operator, 
            clause.value)):[]
        let constraint: QueryConstraint[] = []
        // add where parameter
        if(wh){
            constraint.push(...whereParameter)
        }
        // add order by
        if(order){
            constraint.push(orderBy(order))
        }
        // add offset
        if(this.offset){
            constraint.push(startAfter(this.offset))
        }
        // add limit
        if(lim){
            constraint.push(limit(lim))
        }
        // fetch data
        try {
            getDocs(
                query(
                    colRef,  
                    ...constraint
                )
            ).then(qry=>{
                if(!qry.empty){
                    const all = qry.docs.map(document=>{
                        const d = {...document.data(), reference: document.id}
                        return d
                    })
                    this.offset = qry.docs[all.length - 1]
                    callBack(all)
                }else{
                    callBack([])
                }
            }).catch(errorHandler)
            
        } catch (error) {
            errorHandler!(error)
        }
    }

    /**
     * create or update data
     * @param id 
     * @param callBack 
     * @param errorHander 
     */
    async save(data: object, {id, callBack, errorHander}:{id?: string, callBack?:(id?:string)=>void, errorHander?:(error?: any)=>void}) {
        if(typeof(id)==='undefined'){
            // add new data
            addDoc(collection(firestorDB, this.table), data).then((docRef)=>{
                // successfully saved data
                if(docRef.id){
                    callBack!(docRef.id)
                }else{
                    errorHander!()
                }
            }).catch(error=>errorHander!(error))
        }else{
            setDoc(doc(firestorDB, this.table, id), data).then(()=>callBack!()).catch(error=>errorHander!(error))
        }        
    }

    /**
     * Delete document from database
     * @param id 
     * @param callBack 
     * @param errorHander 
     */
    delete(id: string, callBack?:()=>void, errorHander?:(error?: unknown)=>void){
        deleteDoc(doc(firestorDB, this.table, id)).then(()=>callBack!()).catch(error=>errorHander!(error))
    }

    /**
     * Increment or decrement counters
     * @param param0 
     * @param errorHandler 
     * @param callBack 
     */
    incrementDecrement({dbReference, key, isIncrement = true, incrementalValue}: 
        {dbReference: string, key:string, isIncrement?:boolean, incrementalValue?: number}, 
        errorHandler:(error?: any)=>void,  callBack?:()=>void ){
        const docRef = doc(firestorDB, this.table, dbReference)
        const value = isIncrement?incrementalValue??1:(incrementalValue??1) * -1
        updateDoc(docRef, {[key]: increment(value)})
        .then(callBack!).catch(errorHandler)
    }

    /**
     * Count data in database
     * @param where 
     * @param callBack 
     * @param errorHandler 
     */
    countData(wh: whereClause[], callBack: (data: number)=>void, errorHandler?: (error?: any)=>void) {

        // set parameter
        const qryParameter = wh.map(clause=> where(
            clause.key, 
            clause.operator, 
            clause.value))

        const qry = query(
            collection(firestorDB, this.table),  
            ...qryParameter
        )
        getCountFromServer(qry).then((agg)=>{
            callBack(agg.data().count)
        }).catch(errorHandler)
    }

}