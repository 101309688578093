
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMoneyBill, faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import {  useContext, useEffect, useState } from "react"
import { Modal, FormGroup, FormLabel, ButtonGroup, Button, Form } from "react-bootstrap"
import { UserData } from "../../dataTypes/user.types"
import CustomSpinner from "../utility/CustomSpinner.component"
import { increaseBalances, updateCreditForm } from "../../screens/methods/cooperator"
import { DBMODELS } from "../../dataTypes/customTypes/varTypes"
import { useInitializePayment } from "../../constants/configuration.constants"
import { INFLOW_PURPOSE } from "../../constants/UTILITY.constants"
import { generateRandomString } from "../../utility/helpers"
import { FinanceData, REPAYMENT_SOURCE } from "../../dataTypes/financials"
import { userContext } from "../../providers/user.provider"

export const BalanceModal = ({show, onHide, user, title, isIncrement}:{
    show: boolean, 
    onHide: ()=>void, 
    user: UserData,
    title: DBMODELS,
    isIncrement: boolean
})=>{

    const {currentUser} = useContext(userContext)
    const d = new Date()
    const initialData: FinanceData = {
        date: d.getTime(),
        description: "Repayment",
        transactionRef: ``,
        amount: 0,
        purpose: INFLOW_PURPOSE.LOAN_REPAYMENT,
        memberName: `${user.firstName} ${user.lastName}`,
        day: d.getDate(),
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        memberReference: user.reference!,
        processor: `${currentUser?.firstName} ${currentUser?.lastName}`,
        processorRef: currentUser?.reference!,
        source: REPAYMENT_SOURCE.SOURCE
    }
    const [error, setError] = useState<string | null>(null)
    const [formData, setFormData] = useState<FinanceData>(initialData)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    const [receiveTransfer, setReceiveTransfer] = useState<boolean>(false)
    const paymentSource = ["cash", "salary", "savings"]

    const onRelease = ()=>{
        setPageIsBusy(false)
        onHide()
    }

    // make payment on paystack
    const initializePayment = useInitializePayment(user.email, formData.amount*100, formData.transactionRef)

    useEffect(()=>{
        // set user reference
        setFormData(prev=>({...prev, 
            purpose: title===DBMODELS.savings?INFLOW_PURPOSE.SAVINGS:
            (title===DBMODELS.shares?INFLOW_PURPOSE.SHARES:INFLOW_PURPOSE.DIVIDEND), 
            memberReference: user.reference!}))
    }, [title, user.reference])
    
    return (
        <Modal
          centered
          show={show}
          onHide={onHide}
        >
          <Modal.Header closeButton >
            <Modal.Title>
              {`Member ${DBMODELS[title]}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && <small className="text-center text-danger">{error}</small>}
            <div className="mt-2 mb-2">
                <Form.Check 
                    type="switch"
                    label={receiveTransfer?"Confirm payment":"Receive transfer"}
                    defaultChecked={receiveTransfer}
                    onChange={(e)=>{
                        setReceiveTransfer(e.target.checked)
                        const transactionRef = e.target.checked ? generateRandomString(4)+'_'+title+'_'+Date.now(): ''
                        setFormData(pre=>({...pre, transactionRef}))
                    }}
                />
            </div>
            <FormGroup className="mb-2 mt-2">
                <FormLabel>
                    Amount:
                </FormLabel>
                <input type="number" className="form-control" title={`enter amount to save to ${DBMODELS[title]}`} onChange={(e)=>{
                    updateCreditForm(e, user, setFormData, setError)
                }} defaultValue={ isNaN(formData.amount)?0:formData.amount } name='amount' />
            </FormGroup> 
            {
                receiveTransfer===false &&
                <>
                <FormGroup className="mb-2 mt-2">
                <FormLabel>
                    Date:
                </FormLabel>
                <input type="date" className="form-control" title={`date of payment`} onChange={(e)=>{
                    updateCreditForm(e, user, setFormData, setError)
                }} defaultValue={ new Date(formData.date).toISOString().slice(0, 10) } name="date" />
            </FormGroup>

            
                <FormGroup className="mb-2 mt-2">
                    <FormLabel>
                        Transaction reference:
                    </FormLabel>
                    <input className="form-control" title={`enter transaction reference number`} onChange={(e)=>{
                        updateCreditForm(e, user, setFormData, setError)
                    }} defaultValue={ formData.transactionRef } name='transactionRef' />
                </FormGroup> 
                </>
            }
            {
                (DBMODELS[title]==='savings'&& receiveTransfer===false) &&
                <FormGroup className="mb-2 mt-2">
                    <FormLabel className="me-3">
                        Source:
                    </FormLabel>
                    <select className="form-control" name="source" title="income source" defaultValue={formData.source} onChange={(e)=>{
                    updateCreditForm(e, user, setFormData, setError)
                }} >
                        <option>Choose payment source</option>
                        {
                            paymentSource.map((source, indx)=>{
                                return <option key={indx} value={source}>{source}</option>
                            })
                        }
                    </select>
                   
                </FormGroup> 
            }
          </Modal.Body>
          <Modal.Footer>
            {pageIsBusy && <CustomSpinner />}
                <ButtonGroup>
                    <Button disabled={pageIsBusy} onClick={()=>{

                        setPageIsBusy(true)
                        if(formData.amount <= 0){
                            setError("Please, enter a valid amount")
                            setPageIsBusy(false)
                        }else if(formData.transactionRef===''){
                            setError("Enter transaction reference")
                            setPageIsBusy(false)
                        }else{
                            setError(null)
                            if(receiveTransfer){
                                initializePayment(()=>{
                                    increaseBalances({
                                        title,
                                        formData,
                                        reference: user.reference!,
                                        callBack: onRelease,
                                        isIncrement,
                                    })
                                }, onRelease)
                            }else{
                                increaseBalances({
                                    title,
                                    formData,
                                    reference: user.reference!,
                                    callBack: onRelease,
                                    isIncrement,
                                })
                            }
                        }
                    }} className="btn-sm" >
                        <FontAwesomeIcon icon={
                           receiveTransfer? faMoneyBill :  faCircleCheck
                            } className="me-1" /> {
                                receiveTransfer ? "Receive transfer": "Confirm payment"
                            }
                    </Button>
                </ButtonGroup>
          </Modal.Footer>
        </Modal>
    )
}