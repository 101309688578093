import { ChangeEvent, Dispatch, SetStateAction } from "react"
import { Users } from "../../models/Users"
import { AccountBalance, Balances, FinanceData, Loan } from "../../dataTypes/financials"
import { Savings } from "../../models/Savings.model"
import { Shares } from "../../models/Shares.model"
import { Dividends } from "../../models/Dividends.model"
import { DBMODELS, getModel } from "../../dataTypes/customTypes/varTypes"
import { FunctionReturn, UserData } from "../../dataTypes/user.types"
import { Model } from "../../dataTypes/model.interface"
import { Receipts } from "../../models/Receipts.model"
import { LOANSTATUS, MEMBERSHIP_STATUS } from "../../constants/UTILITY.constants"
import { Loans } from "../../models/Loans.model"
import { WHEREOPERATOR } from "../../dataTypes/firebasequery.types"
import { toast } from "react-toastify"


type BalanceData = {
    key: string,
    model: Model
}
export const resetUserPassword = (uid: string, callBack: (data: FunctionReturn)=>void, onError: (e?: unknown)=>void)=>{
    if(window.confirm("Are you sure you want to reset Member password?")){
        const model = new Users()
        model.resetPassword(uid, (data)=>{
            // update profile to require password reset
            model.update({needResetPassword: true}, uid, ()=>{
                callBack(data)
            }, (error)=>{
                onError(error)
            })
            
        }, (error)=>{
            onError(error)
            console.log('error resetting password: ', error)
        })
    }
}

export const getCreditBalances = (setCreditBalances: Dispatch<SetStateAction<Balances | null>>, memberId: string)=>{
   
    const models: BalanceData[] = [
        {
            key: 'savings',
            model: new Savings()
        },
        {
            key: 'shares',
            model: new Shares()
        },
        {
            key: 'unclaimedDividend',
            model: new Dividends()
        }
    ]

    // Fetch balances
       models.forEach(item=>{
            item.model.stream((data)=>{
                if(data){
                    setCreditBalances((previous)=>({...previous, [item.key]: data as AccountBalance} as Balances))
                }
            }, (error)=>{console.log('fetching balance: ', error)}, memberId)
       })
}

export const fetchActiveLoans = (userId: string, setCurrentLoans: Dispatch<SetStateAction<Loan[]>>)=>{
    const model = new Loans()
    model.streamWhere([
        {
            key: 'applicantRef',
            operator: WHEREOPERATOR.EQUAL_TO,
            value: userId
        },
        {
            key: 'status',
            operator: WHEREOPERATOR.NOT_IN,
            value: [LOANSTATUS.COMPLETED, LOANSTATUS.CANCELLED]
        },
    ], (data)=>{
        setCurrentLoans(data as Loan[])
        // console.log('found loans: ', data)
    }, (error)=>{
        console.log('something went wrong: ', error)
    })
}

export const fetchGuaranteedLoans = (userId: string, setter: Dispatch<SetStateAction<Loan[]>>)=>{
    const model = new Loans()
    model.streamWhere([
        {
            key: 'guarantorList',
            operator: WHEREOPERATOR.ARRAY_CONTAINS,
            value: userId
        },
        {
            key: 'status',
            operator: WHEREOPERATOR.IN,
            value: [
                LOANSTATUS.PARTIALLY_GUARANTEED, 
                LOANSTATUS.ACTIVE, 
                LOANSTATUS.APPROVED, 
                LOANSTATUS.BAD, 
                LOANSTATUS.NON_PERFORMING,
                LOANSTATUS.DOUBTFUL,
                LOANSTATUS.GUARANTEED,
            ]
        },
    ], (data)=>{
        setter(data as Loan[])
    }, (error)=>{
        console.log('something went wrong: ', error)
    })
}

export const changeMemberStatus = (newStatus: MEMBERSHIP_STATUS, uid: string, successMsg: string, errorMsg?: string)=>{
    const model = new Users()
    model.update({status: newStatus}, uid, ()=>{
        toast(successMsg, {type: 'success'})
    }, (error)=>{
        if(errorMsg){
            toast(errorMsg, {type: 'error'})
        }
        console.log('unable to change user status: ', error)
    })
}
    
/**
 * increase or decrease savings, shares and dividends
 * @param param0 
 */
export const increaseBalances = ({title, formData, reference, callBack, isIncrement}:
    {
        title: DBMODELS, 
        formData: FinanceData, 
        reference: string, 
        callBack: ()=>void,
        isIncrement: boolean,
    })=>{
    // get model
    const model = getModel(title)
    // no account exists
    const accountBalance: AccountBalance = {
        amount: formData.amount,
        name: formData.memberName,
        lastUpdated: Date.now(),
        processor: formData.processor
    }
    model.incrementDecrement({
        dbReference: reference,
        key: 'amount',
        isIncrement,
        incrementalValue: formData.amount,
    }, (error)=>{
            model.save(accountBalance, {id: reference, errorHander() {
                callBack()
            }, callBack() {
                saveReceipt(formData)
                callBack()
            },})
        console.log('error increasing balance: ', error)
    }, ()=>{
        model.update({lastUpdated: Date.now(), processor: formData.processor}, reference, ()=>{}, ()=>{})
        saveReceipt(formData)
        callBack()
    })
}

export const saveReceipt = (data: FinanceData)=>{
    // save to receipt table
    const recModel = new Receipts()
    recModel.save(data, {errorHander: (_)=>{}})
}


/**
 * update form data for savings and shares
 * @param e 
 * @param member 
 * @param setData 
 * @param setMsg 
 */
export const updateCreditForm = (e:ChangeEvent<HTMLInputElement | HTMLSelectElement>, member: UserData, setData: Dispatch<SetStateAction<FinanceData>>, setMsg: Dispatch<SetStateAction<string | null>>)=>{
    const val = e.target.type==='number'?parseInt(e.target.value): e.target.value
    const name = e.target.name

    setMsg(null)
    let canUpdate = false
    if(e.target.type==='number'){
        if(name==='savings'){
            const minSavings = member.employmentDetail.currentSalary * 0.035
            if(val as number < minSavings){
                setMsg(`${member.firstName}'s savings cannot be less than ${Math.round(minSavings)}`)
            }else{
                canUpdate = true
            }
        }else {
            canUpdate = val as number > 0
        }
    } else{
        canUpdate = true
    }

    // update form
    if(canUpdate){
        if(name === 'date'){
            const d = new Date(val)
            setData(prev=>({...prev, date: d.getTime(), day: d.getDate(), month: d.getMonth()+1, year: d.getFullYear()}))
        }else{
            setData(prev=>({...prev, [name]: val}))
        }
    }
}

/**
 * set initial data for payment receipt
 * @param uid 
 * @param description 
 * @param purpose 
 * @returns 
 */
// export const setInitalRecieptData = (uid: string, description: string, purpose: INFLOW_PURPOSE): ReceiptData=>{

//     const d = new Date()
//     const initialData = initialReceiptData
//     initialData.memberReference = uid
//     initialData.description = description
//     initialData.source = REPAYMENT_SOURCE.CASH
//     initialData.purpose = purpose
//     initialData.date = d.getTime()
//     initialData.day = d. getDate()
//     initialData.month = d.getMonth()+1
//     initialData.year = d.getFullYear()
//     return initialData
// }

