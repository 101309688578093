import { faUserXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, Button, Card, Form } from "react-bootstrap"
import { WithdrawalData } from "../../dataTypes/financials"
import { useContext, useEffect, useState } from "react"
import { moneyFormatter } from "../../utility/helpers"
import { LOANSTATUS } from "../../constants/UTILITY.constants"
import { withdrawMembership } from "./methods"
import { UserData } from "../../dataTypes/user.types"
import { userContext } from "../../providers/user.provider"

export const WithdrawalModal = ({show, onHide, data, member}: {show: boolean, onHide:()=>void, data: WithdrawalData, member: UserData})=>{
    const [guaLiablity, setGuaLiablity] = useState<number>(0)
    const [totalLoans, setTotalLoans] = useState<number>(0)
    const [isExpress, setIsExpress] = useState<boolean>(false)
    const [balance, setBalance] = useState<number>(0)
    const [formData, setFormData] = useState<WithdrawalData>(data)
    const {currentUser} = useContext(userContext)

    useEffect(()=>{
        // set withdrawal charges
        const charges = (formData.credits.savings.amount??0)*(isExpress?0.06:0.035)
        setFormData(prev=>({...prev, withdrawalCharges: Math.ceil(charges)}))

        // compute total guarantor liablity
        const {guaranteedLoans, debits, credits} = data
        let liab = 0
        if(guaranteedLoans.length > 0){
            guaranteedLoans.forEach((loanLiability)=>{
                const gu = loanLiability.guarantors.find((g)=>g.reference===data.uid)
                if(gu){
                    liab+= ((gu?.sumRequest??0) - (gu?.requestOffset??0))
                }
            })
        }
        setGuaLiablity(liab)

        // compute total loan liablity for recovery
        let loanLiab = 0
        if(debits.length > 0){
            debits.forEach((loan)=>{
                // recover loan
                const exemptedStatus = [LOANSTATUS.APPROVED, LOANSTATUS.PARTIALLY_GUARANTEED, LOANSTATUS.UNGUARANTEED]
                if(!exemptedStatus.includes(loan.status)){
                    const capitalInt = (1 + (loan.interestRate/100)) * loan.capital
                    const repayments = loan.repayments.length > 0?loan.repayments.reduce((prev, curr)=>({...curr, amount: curr.amount+prev.amount})).amount:0
                    if(repayments && capitalInt){
                        loanLiab+=(+capitalInt-repayments)
                    }
                }
            })
        }
        setTotalLoans(loanLiab)
        // compute the balance
        setBalance(
            ((isNaN(credits.savings.amount)?0:credits.savings.amount)+
            (isNaN(credits.shares.amount)?0:credits.shares.amount)+
            (isNaN(credits.unclaimedDividend.amount)?0:credits.unclaimedDividend.amount))-
            (+liab+loanLiab+charges)
        )
    }, [isExpress, formData])

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
            >
            <Modal.Header closeButton>
                Membership Withdrawal

                <Form.Check 
                type="switch"
                label="Express withdrawal"
                name="isExpress"
                className="ms-4"
                defaultChecked={isExpress}
                onChange={(e)=>{setIsExpress(e.target.checked)}}
            />
            </Modal.Header>
            <Modal.Body>
                <section>
                    <Card>
                        <Card.Header>
                            Member's Credit Balance
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-between mt-2">
                                <div> Savings: </div>
                                <div>{moneyFormatter(data.credits.savings.amount??0)}</div>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                                <div> Shares: </div>
                                <div>{moneyFormatter(data.credits.shares.amount??0)}</div>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                                <div> Unclaimed Dividend: </div>
                                <div>{moneyFormatter(data.credits.unclaimedDividend.amount??0)}</div>
                            </div>
                            <Card.Footer>
                                <div className="d-flex justify-content-between mt-2">
                                    <div> Total: </div>
                                    <h4 className="text-success">&#x20A6;{moneyFormatter((data.credits.unclaimedDividend.amount??0)+(data.credits.savings.amount??0)+(data.credits.shares.amount??0))}</h4>
                                </div>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            Member's Debit Balance
                        </Card.Header>
                        <Card.Body>
                            {data.debits.map((loan, indx)=>{
                                const exemptedStatus = [LOANSTATUS.APPROVED, LOANSTATUS.PARTIALLY_GUARANTEED, LOANSTATUS.UNGUARANTEED]
                                const loanLiability = exemptedStatus.includes(loan.status)?0:(1 + (loan.interestRate/100)) * loan.capital
                                let repayments = 0
                                if(!exemptedStatus.includes(loan.status)){
                                    repayments = loan.repayments.length > 0?loan.repayments.reduce((prev, curr)=>({...curr, amount: (+curr.amount+prev.amount)})).amount:0
                                }
                                
                                return (
                                    <div key={indx} className="d-flex justify-content-between mt-2">
                                        <div> {loan.category}: </div>
                                        <div>{moneyFormatter(+loanLiability-repayments)}</div>
                                    </div>
                                )
                            })}
                            {
                                data.guaranteedLoans.length > 0 && 
                                <div className="d-flex justify-content-between mt-2">
                                    <div> Guarantee Liability: </div>
                                    <div>{moneyFormatter(isNaN(guaLiablity)?0:guaLiablity)}</div>
                                </div>
                            }
                        </Card.Body>
                        <Card.Footer>
                                <div className="d-flex justify-content-between mt-2">
                                    <div> Total: </div>
                                    <h4 className="text-warning">&#x20A6;{moneyFormatter(+totalLoans+(isNaN(guaLiablity)?0:guaLiablity))}</h4>
                                </div>
                            </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Body>
                        <div className="d-flex justify-content-between mt-2">
                                <div> Withdral charges @ {isExpress?"6":"3.5"}%: </div>
                                <div>{moneyFormatter(formData.withdrawalCharges)}</div>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                                <div className="d-flex justify-content-between mt-2">
                                    <div> Total {balance>=0? "Credit":"Liability"}: </div>
                                    <h4 className={balance>=0?"text-success":"text-danger"}>&#x20A6;{moneyFormatter(
                                        Math.floor(balance>=0?balance:(-1*balance))
                                    )}</h4>
                                </div>
                            </Card.Footer>
                    </Card>
                </section>

                
                <Button className="btn-sm" variant="danger" 
                    disabled={balance < 0}
                onClick={()=>{
                    withdrawMembership(formData, member, balance, totalLoans, guaLiablity, isExpress, currentUser!)
                }} 
                >
                    <FontAwesomeIcon icon={faUserXmark} className="me-2" /> Withdraw membership
                </Button>
            </Modal.Body>
            
            </Modal>
    )
}