import { useContext, useEffect, useState } from "react"
import BreadCrumbComponent from "../components/main/body/pageTitle/BreadCrumb.component"
import { CustomTable, loanColumn } from "../components/utility/CustomTable.component"
import { generalMessage } from "../dataTypes/notificationMessages.types"
import { Loan } from "../dataTypes/financials"
import { Loans } from "../models/Loans.model"
import { LOANSTATUS } from "../constants/UTILITY.constants"
import { WHEREOPERATOR, whereClause } from "../dataTypes/firebasequery.types"
import { useNavigate } from "react-router-dom"
import { userContext } from "../providers/user.provider"
import { monitorPasswordResetter } from "./methods/registration"

type TabItem = {
    id: string,
    label: string
  }
  
  const tabs: TabItem[] = [
    {
      id: 'pending-loans',
      label: 'Pending'
    },
    {
      id: 'non-performing-loans',
      label: 'Non performing'
    },
    
  ]

const PendingLoans = ()=>{

    const {currentUser} = useContext(userContext)
    const [tableData, setTableData] = useState<Loan[]>([])
    // const [message, setMessage] = useState<generalMessage | null>(null)
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
    const navigate = useNavigate()

    useEffect(()=>{
        // monitor password resetter
        if(currentUser){
            monitorPasswordResetter(currentUser!, navigate)
        }
        let where: whereClause[] = []
        if(activeTabIndex===0){
            // fetch pending loans
            where = [
                {
                    key: 'status',
                    operator: WHEREOPERATOR.IN,
                    value: [LOANSTATUS.APPROVED, LOANSTATUS.GUARANTEED, LOANSTATUS.PARTIALLY_GUARANTEED, LOANSTATUS.UNGUARANTEED]
                }
            ]
            
        }else{
            // fetch non-performing loans: TODO
        }

        const loanModel = new Loans()
            loanModel.streamWhere(where, (data)=>{
                if(data.length > 0){
                    setTableData(data as Loan[])
                }else{
                    setTableData([])
                }
            }, (error)=>{
                console.log(error)
            })
    }, [activeTabIndex, currentUser, navigate])

    return (
        <section>
            <BreadCrumbComponent 
                pageTitle={activeTabIndex===0?"Pending Loans":"Non-performing Loans"}
                currentCrumb="Loans"
                parentPages={[]}
                // message={message}
            />

            <section className="mt-5">
                 <div className="card">
                    <div className="card-body pt-3">
                        <ul className="nav nav-tabs nav-tabs-bordered">
                        {
                            tabs.map((tab, index)=>{
                            const activeTab = tabs[activeTabIndex]
                            return <li key={index} className="nav-item">
                            <button onClick={()=>setActiveTabIndex(index)} type="button" className={`nav-link ${tab.id===activeTab.id?"active":""}`} >{tab.label}</button>
                            </li>
                            })
                        }

                        </ul>
                        <div className="tab-content pt-2">
                        {
                            tabs.map((tab, index)=>{
                            const activeTab = tabs[activeTabIndex]
                            return <div key={index} className={`tab-pane fade show profile-overview ${tab.id===activeTab.id?"active":""}`} id={tab.id}>
                                {
                                tab.id===activeTab.id &&
                                <CustomTable
                                    columns={loanColumn}
                                    data={tableData}
                                    rowKey='reference'
                                    onClick={(row: Loan)=>{
                                      navigate('/cooperator', {state: row.applicantRef})
                                    }}
                                />
                                }
                            </div>
                            })
                        }
                        </div>

                    </div>
                </div>
            </section>
        </section>
    )
}

export default PendingLoans