import { Spinner } from "react-bootstrap";

export default function CustomSpinner({message = '...please wait'}: {message?: string}){
    return (
        <div className="text-center">
            <div>
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="info" />
            </div>
            <div className="text-warning">
                {message}
            </div>
      </div>
    )
}