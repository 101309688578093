import { Dispatch,  ReactNode, SetStateAction, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import OtpInput from 'react-otp-input';
import { Users } from "../../models/Users";
import { MultiFactorResolver, User } from "firebase/auth";
import { UserData } from "../../dataTypes/user.types";
import { RouterProvider } from "react-router-dom";
import { adminRouter } from "../../navigation/AdminRoute";


const otpLength = 6

export const OTP = ({show, onHide, verifier, user, setPageIsLoading, setCurrentPage, setValidationMessage}: {show: boolean, onHide:()=>void, verifier: {
    verificationId: string,
    resolver?: MultiFactorResolver
}, user?: {user: User, userData: UserData}, setPageIsLoading: Dispatch<SetStateAction<boolean>>, 
setCurrentPage: Dispatch<SetStateAction<ReactNode>>, setValidationMessage: Dispatch<SetStateAction<string | null>> })=>{

    const [otp, setOtp] = useState('');
    const [isClicked, setIsClicked] = useState<boolean>(false)
   
    return (

        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
            >
            <Modal.Body className="d-flex justify-content-center">
                <section>
                    <div>
                        <p className="text-center text-danger">Please enter your OTP below:</p>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={otpLength}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{minWidth: '60px', minHeight: '60px', fontSize: '30px', fontWeight: 'bold', }}
                        />
                        
                        <Button 
                            onClick={()=>{
                                setIsClicked(true)
                                const model = new Users()
                                model.enrollWithSecondFactor(verifier, otp, (error)=>{
                                    setPageIsLoading(false)
                                    onHide()
                                    setValidationMessage(error!)
                                }, user?.user, ({userData})=>{
                                    setPageIsLoading(false)
                                    sessionStorage.setItem('currentUser', JSON.stringify(user?user.user: userData))
                                    setCurrentPage(<RouterProvider router={adminRouter} />)
                                })
                            }}
                            disabled={otp.length !== otpLength || isClicked}
                            className="mt-3 btn-sm form-control btn-success">
                                Confirm
                        </Button>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    )
}