import { faGear, faCirclePlus, faCheck, faCancel } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card } from "react-bootstrap"
import { LoanConfiguration, fetchLoanConfigIcon, initialLoanConfig } from "../../dataTypes/financials"
import { ConfigurationForm } from "../utility/ConfigurationForm.component"
import { useState, useEffect, useCallback } from "react"
import { LoanConfigurations } from "../../models/LoanConfigurations.model"
import { LoanConfigCard } from "./LoanConfigCard.component"

export const LoanConfig = ()=>{

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formData, setFormData] = useState<LoanConfiguration|null>(null)
    const [existingConfigs, setExistingConfigs] = useState<LoanConfiguration[]>([])

    const fetChConfigData = useCallback(()=>{
        const model = new LoanConfigurations()
        model.stream((data)=>{
            if(data){
                setExistingConfigs(data as LoanConfiguration[])
            }
        }, (e)=>{console.log(e)})
    }, [])

    
    useEffect(()=>{
        fetChConfigData()
    }, [])

    return (
        <>
            <div className="mt-5">
                <h6 className="text-grey">
                    <FontAwesomeIcon icon={faGear} className="me-2" />
                     Loan Configurations
                    <FontAwesomeIcon icon={faCirclePlus} onClick={ ()=>{
                        setFormData({...initialLoanConfig, plans: []})
                        setShowForm(true)
                        }} className="btn text-success" />
                </h6>
                <Card>
                    <Card.Body className="p-4">
                        <div className="row">
                            {
                                existingConfigs.map((config, indx)=>{
                                    return (
                                        <div onClick={()=>{
                                            setFormData({...config})
                                            setShowForm(true)
                                        }} key={indx} className="col-lg-4 col-md-6 p-4 btn">
                                            <div className="configuration-card row">
                                                <div className="col-4 bg-green d-flex align-items-center justify-content-center configuration-card-left">
                                                    <FontAwesomeIcon className="h1 text-white" icon={fetchLoanConfigIcon(config.title)} />
                                                </div>
                                                <LoanConfigCard config={config} />
                                                <div className={`configruation-check-box ${config.isAvailable? 'active-checkbox': 'inactive-checkbox'} d-flex justify-content-center align-items-center `}>
                                                    <FontAwesomeIcon icon={config.isAvailable ? faCheck : faCancel} className="text-white" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        
                    </Card.Body>
                </Card>
            </div>
            {
                showForm && 
                <ConfigurationForm show={showForm} handleClose={()=>{ setShowForm(false)
                }} item={formData} existingConfigs={existingConfigs} />
            }
        </>
    )
}