import { faInfo, faCheckCircle, faCreditCard, faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"
import { moneyFormatter } from "../../utility/helpers"
import { CustomEditableCard } from "../utility/CustomEditableCard.component"
import { Loan } from "../../dataTypes/financials"
import { LOANSTATUS } from "../../constants/UTILITY.constants"
import { useContext, useState } from "react"
import { userContext } from "../../providers/user.provider"
import { approveLoan, canApproveLoan } from "./methods"
import { UserData } from "../../dataTypes/user.types"
import { LoanPayoutModal } from "./LoanPayoutModal.component"
import { LoanRepaymentModal } from "./LoanRepaymentModal.component"
import { LoanGuarantorInfo } from "./LoanGuarantorInfo.component"
import { StaffsAndCoreExco, Treasurer, staffs } from "../../constants/AdminDefinitions.constant"

export const LoanCard = ({loan, isCurrent, className, onClick, applicant}: {loan: Loan, isCurrent: boolean, className?:string[], onClick: ()=>void, applicant: UserData})=>{
    const {capital, repayments, instalments, interestRate, startDate, status, applicationDate, fines} = loan
    const loanFine = fines.length > 0 ? fines.reduce((prev, curr)=> ({...curr, cost: +curr.cost+prev.cost})).cost : 0
    const monthlyInstalments = (capital * (1 + (interestRate/100)))/instalments
    const totalRepayments = repayments.length > 0?repayments.reduce((prev, curr)=>({...curr, amount: curr.amount+prev.amount})).amount:0
    const loanBalance = (capital * (1 + (interestRate/100))) - (totalRepayments ?? 0) + loanFine
    const {currentUser} = useContext(userContext)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    const payableLoans = [LOANSTATUS.ACTIVE, LOANSTATUS.BAD, LOANSTATUS.DOUBTFUL, LOANSTATUS.NON_PERFORMING]
    const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false)
    const [openRepaymentModal, setRepaymentModal] = useState<boolean>(false)
    const [showGuarantors, setShowGurantors] = useState<boolean>(false)
    
    
    return (
        <div className={`col-lg-6 col-md-6 ${className?.toString()}`} onClick={()=>{onClick()}}>
            <CustomEditableCard isCurrent={isCurrent} pageTitle={loan.category} titleBadge={
                <Button className="btn-sm btn-light" >
                    {status}
                </Button>
            } cardBody={<>
            <div className="d-flex justify-content-between">
                <div className="d-flex flex-column justify-content-between">
                    <div className="loan-instalments-card d-flex flex-column justify-content-center align-items-center">
                        <div className="display-3 pt-2 text-warning">{Math.ceil(loanBalance/monthlyInstalments)}</div>
                        <div className="bg-dark form-control text-white instalment-text">Instalments</div>
                    </div>
                </div>
                <div className="text-center">
                    <h3><u>&#x20A6;{moneyFormatter(capital)}</u></h3>
                    <p>Balance: <span><u>&#x20A6;{moneyFormatter(Math.ceil(loanBalance))}</u></span></p>
                    <div className="text-danger">{status===LOANSTATUS.ACTIVE?'Collection date:':'Application date:'}</div>
                    <div>{new Date(LOANSTATUS.ACTIVE?startDate:applicationDate).toLocaleDateString()}</div>
                </div>
                <div>
                    {
                        (loan.guarantorList.length>0 && StaffsAndCoreExco.includes(currentUser?.adminRole!)) &&
                        <div className="mt-1 mb-2">
                            <Button onClick={()=>{
                                setShowGurantors(true)
                            }} title="Loan Guarantee information" 
                                className="text-white btn-sm btn-info icon-btn">
                                <FontAwesomeIcon icon={faInfo} /></Button>
                        </div>
                    }
                    {
                        (canApproveLoan(currentUser!, loan) && loan.status===LOANSTATUS.GUARANTEED ) && 
                        <div className="mt-2 mb-1">
                            <Button disabled={pageIsBusy} onClick={()=>{
                                if(window.confirm("Are you sure you want to approve this loan?")){
                                    approveLoan(currentUser!, loan, setPageIsBusy)
                                }
                            }} title="Loan approval" className="text-white  btn-sm btn-warning icon-btn">
                                <FontAwesomeIcon icon={faCheckCircle} />
                            </Button>
                        </div>
                    }
                    {
                        (Treasurer.includes(currentUser?.adminRole!) && loan.status===LOANSTATUS.APPROVED ) &&
                        <div className="mt-2 mb-1">
                            <Button onClick={()=>{
                                // open payment modal
                                setOpenPaymentModal(true)
                            }} title="Loan payment" className="text-white  btn-sm btn-primary icon-btn" >
                                <FontAwesomeIcon className="me-2" icon={faHandHoldingDollar} /> 
                            </Button>
                        </div>
                    }
                    {
                        (staffs.includes(currentUser?.adminRole!) && payableLoans.includes(loan.status) && currentUser?.reference!==loan.applicantRef ) &&
                        <div className="mt-2 mb-1">
                            <Button 
                                onClick={()=>{
                                    // open repayment modal
                                    setRepaymentModal(true)
                                }}
                                title="Instalments repayment" 
                                className="text-white btn-sm btn-success icon-btn" >
                                <FontAwesomeIcon className="me-2" icon={faCreditCard} /> 
                            </Button>
                        </div>
                    }
                </div>
                {openPaymentModal && <LoanPayoutModal show={openPaymentModal} onHide={()=>setOpenPaymentModal(false)} loan={loan} />}
                {openRepaymentModal && <LoanRepaymentModal show={openRepaymentModal} onHide={()=>setRepaymentModal(false)} loan={loan} />}
                {showGuarantors && <LoanGuarantorInfo onHide={()=>setShowGurantors(false)} show={showGuarantors} loan={loan} applicant={applicant} />}
            </div>
            </>} isEdit={false}  />
        </div>
    )
}

