import { Button, FormGroup, FormLabel } from "react-bootstrap"
import { UserData } from "../../dataTypes/user.types"
import { updateFormData } from "../../screens/methods/registration"
import { useContext, useState } from "react"
import { generalMessage } from "../../dataTypes/notificationMessages.types"
import CustomSpinner from "../utility/CustomSpinner.component"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { userContext } from "../../providers/user.provider"
import { updateUserData } from "./methods"
import { StaffsAndTrustees } from "../../constants/AdminDefinitions.constant"

export const CooperatorForm = ({user}: {user: UserData})=>{


    const {currentUser} = useContext(userContext)
    const canUpdate: boolean = ( StaffsAndTrustees.includes(currentUser?.adminRole!))
    const [formData, setFormData] = useState<UserData>(user)
    const [validationError, setValidationError] = useState<generalMessage | null>(null)
    const [pageIsBusy, setPageIsBusy] = useState<boolean>(false)
    return (
        <div>
                {
                    validationError &&
                    <div className="mb-2">
                        <small className={validationError.type}>{validationError.message}</small>
                    </div>
                }
            <FormGroup className="">
                <FormLabel>
                    Monthly savings:
                </FormLabel>
                <input className="form-control" placeholder="Monthly savings" onChange={(e)=>{
                        updateFormData(e, setFormData, setValidationError, formData)
                    }} defaultValue={formData?.KISCMSInfo?.monthlySavings!} name="kismonthlySavings" />
            </FormGroup>  

            <div className="mt-3">
                {pageIsBusy && <CustomSpinner />}
                <div className="d-flex justify-content-center mt-1">
                    <Button onClick={
                        ()=>updateUserData({setPageIsBusy, formData})
                    } disabled={pageIsBusy || !canUpdate} className="btn-sm" variant="success">
                        <FontAwesomeIcon icon={faSave} className="me-2" /> Save
                    </Button>
                </div>
            </div> 
        </div>
    )
}