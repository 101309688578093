import { Fragment, memo } from "react"
import { Link } from "react-router-dom"
import { Message } from "../../dataTypes/customTypes/varTypes"

const NoficationBell = ({messages, icon}:{messages?:Array<Message>, icon?: string})=>{
    return (
        <div className="nav-item dropdown">

        <div className="nav-link nav-icon" data-bs-toggle="dropdown">
            <i className={`text-light ${icon??"bi bi-bell"}`}></i>
            <span className="badge bg-danger badge-number">{messages?.length}</span>
        </div>

        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
            <li className="dropdown-header">
                {`You have ${messages?.length??"no"} message(s)`}
                {messages?.length?
                <Link to="/messages"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></Link>
                    :''}
            </li>
            <li>
                <hr className="dropdown-divider" />
            </li>
                {
                    messages?.map((message, key) => {
                        return (
                            <Fragment key={key}>
                                <li className="notification-item">
                                    <i className="bi bi-exclamation-circle text-warning"></i>
                                    <div>
                                        <h4>{message.title}</h4>
                                        <p>{message.subject}</p>
                                        <p>{`${message.time} ago`}</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                            </Fragment>
                        )
                    })
                }            
            {
                messages?.length?
                <li className="dropdown-footer">
                <Link to="/notifications">Show all notifications</Link>
            </li>:''
            }

        </ul>

    </div>
    )
}

export default memo(NoficationBell)