import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "react-bootstrap";
import {  NavLink } from "react-router-dom";
import { ListItem } from "../../dataTypes/customTypes/varTypes";

export const SingleListItem = ({item, counter}: {item: ListItem, counter?:number})=>{
    
    return <li className="nav-item mb-4" >
        <NavLink className="" 
            style={({ isActive }) =>
            isActive ? {
                backgroundColor: '#114903',
                borderRadius: 50,
                paddingLeft: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingRight: '15px',
                color: 'white'
            } : {
                color: '#505a64'
            }
            }
            to={item.path}><FontAwesomeIcon icon={item.icon!} className="me-3" />
            <span>{item.label} </span> {counter && <Badge bg="danger">{counter}</Badge>}
        </NavLink>
    </li>
}