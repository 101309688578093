import { ChangeEvent, useState, memo } from "react"
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Users } from "../../models/Users";
import { WHEREOPERATOR } from "../../dataTypes/firebasequery.types";
import { useNavigate } from "react-router-dom";

 const SearchBar = ({title}: {title: string})=>{
    const [searchParam, setSearchParam] = useState<string>("")
    const navigate = useNavigate()

    const searchData = ()=>{
        // check if number is valid
        if(searchParam.length===10){
            const model = new Users()
            model.findWhere({
                wh: [
                    {
                        key: 'accountNumber',
                        operator: WHEREOPERATOR.EQUAL_TO,
                        value: searchParam
                    }
                ],
                callBack(data) {
                    if(data.length > 0){
                        // setSearchParam("")
                        navigate('/cooperator', {state: data[0].reference})
                    }else{
                        toast("Account does not exist", {type: 'error'})
                    }
                },
                errorHandler(_) {
                    toast("Check internet connection", {type: 'warning'})
                },
            })
        } else {
            toast("Enter a valid account number", {type: 'warning'})
        }
    }

    const updateSearchData = (event:ChangeEvent<HTMLInputElement>)=>{
        const value:string = event.target.value
        setSearchParam(value)
    }

    return (
        <div className="search-bar mb-5">
        <div className="search-form d-flex align-items-center form-control">
            <input value={searchParam} className="form-control no-border" onChange={updateSearchData}  type="number" placeholder="Account number" title={title} />
            <Button className="btn-sm btn-light no-background" onClick={searchData}  title="Search"><i className="bi bi-search"></i></Button>
        </div>
    </div>
    )
}

export default memo(SearchBar)