import { httpsCallable } from "firebase/functions";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";
import { cloudFunctions } from "../../configurations/firebase.configuration";
import { StatesAndLGAs } from "../../constants/STATES.LOCALGOVERNMENT.constant";
import { NEXTOFKIN_RELATIONSHIP } from "../../constants/UTILITY.constants";
import { BankDetail } from "../../dataTypes/customTypes/varTypes";
import { generalMessage, MESSAGE_TYPE } from "../../dataTypes/notificationMessages.types";
import { AccountVerificationRequest, UserAccount, UserData } from "../../dataTypes/user.types";
import { emailTester, phoneNumberTester } from "../../utility/regex";
import { Users } from "../../models/Users";
import { NavigateFunction } from "react-router-dom";

/**
 * set inputs for upload
 * @param event 
 * @param setter 
 * @param setErrorMsg 
 */
export const updateFormData = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement >, 
    setter: Dispatch<SetStateAction<UserData>>,
    setErrorMsg: Dispatch<SetStateAction<generalMessage | null>>,
    formData: UserData,
    phoneIndex?: number
    )=>{
    
        try {
            const type = event.target.type
            // process all pone numbers
            if(type==='tel'){
                phoneInputs(event as ChangeEvent<HTMLInputElement>, setter, setErrorMsg, phoneIndex!)
            }
            
            // process all checkboxes
            if(type==='checkbox'){
                updateCheckBoxes(event as ChangeEvent<HTMLInputElement>, setter)
            }

            // process other text inputs
            if(type==='text'){
                updateTextInputs(event as ChangeEvent<HTMLInputElement>, setter)
            }

            // process select dropdowns
            if(type==='select-one'){
                updateSelectDropdowns(event as ChangeEvent<HTMLSelectElement>, setter)
            }

            // process radios
            if(type==='radio'){
                updateRadios(event as ChangeEvent<HTMLInputElement>, setter)
            }

            // process emails
            if(type==='email'){
                updateEmails(event as ChangeEvent<HTMLInputElement>, setter, setErrorMsg)
            }

            // process emails
            if(type==='date'){
                updateDate(event as ChangeEvent<HTMLInputElement>, setter, setErrorMsg)
            }

            // process account numbers
            if(type==='number'){
                updateAccountNumber(event as ChangeEvent<HTMLInputElement>, setter, setErrorMsg, formData)
            }
        } catch (error) {
            console.log(error)
        }
}

/**
 * Deactivate/activate user
 * set user as admin or revoke admin right
 * @param event 
 * @param setter 
 */
const updateCheckBoxes = (event: ChangeEvent<HTMLInputElement>, setter: Dispatch<SetStateAction<UserData>>)=>{
    const name = event.target.name
    const checked = event.target.checked

    // confirm action before proceeding
    if(window.confirm("Are you sure about this action?")){
        setter((prev)=>({...prev!, [name]: checked}))
    }
}

/**
 * update other information
 * @param event 
 * @param setter 
 */
const updateTextInputs = (event: ChangeEvent<HTMLInputElement>, setter: Dispatch<SetStateAction<UserData>>)=>{
   const name = event.target.name
   const value = event.target.value
   // get header code and key for items
   const hCode = name.slice(0, 3)
   const key = name.slice(3)
   if(hCode==='nok'){
    // next of kin information
    setter((prev)=>({...prev, nextOfKin: {...prev.nextOfKin, [key]: value}}))
   } else if(hCode==='con'){
    // contact address info
    setter((prev)=>({...prev, contactAddress: {...prev.contactAddress, [key]: value}}))
   } else if(hCode==='wok'){
    // employment information
    setter((prev)=>({...prev, employmentDetail: {...prev.employmentDetail, [key]: value}}))
   }else if(hCode==='kis'){
    // cooperative information
    setter((prev)=>({...prev, KISCMSInfo: {...prev.KISCMSInfo, [key]: value}}))
   }else{
    // other information
    setter((prev)=>({...prev, [name]: value}))
   }
}

/**
 * Update information from dropdown select option
 * e.g contact state and lga, relationship with nok,
 * business employment unit and more
 * @param event 
 * @param setter 
 */
const updateSelectDropdowns = (event: ChangeEvent<HTMLSelectElement>, setter: Dispatch<SetStateAction<UserData>>)=>{
    const name = event.target.name
    const value = event.target.value

    if(name === 'stateOfResidence' || name === 'lgaOfResidence'){
        // contact address
        const key = name==='stateOfResidence'?'state':'localGovernmentArea'
        let val = value
        if(name==='stateOfResidence'){
            const stateOfR = JSON.parse(value) as StatesAndLGAs
            val = stateOfR.state
        }
        // const val = ?JSON.parse(value):
        // value
        setter((prev)=>({...prev, contactAddress: {...prev.contactAddress, [key]: val}}))
    } else if(name === 'nokRelationship'){
        setter((prev)=>({...prev, nextOfKin: {...prev.nextOfKin, relationship: value as NEXTOFKIN_RELATIONSHIP } }))
    } else if(name === 'businessUnit'){
        setter((prev)=>({...prev, employmentDetail: { ...prev.employmentDetail, businessUnit: value} }))
    } else if(name === 'bankName'){
        const bank: BankDetail = JSON.parse(value)
        setter((prev)=>({...prev, bankAccoundDetail: {...prev.bankAccoundDetail, bankName: bank.name, bankCode: bank.code, accountName: undefined} }))
    } else if(name==='stateOfOrigin'){
        const stateData = JSON.parse(value) as StatesAndLGAs
        setter((prev)=>({...prev, stateOfOrigin: stateData.state }))
    } else {
        setter((prev)=>({...prev, [name]: value }))
    }

}

/**
 * update nationality, gender and marital status
 * @param event 
 * @param setter 
 */
const updateRadios = (event: ChangeEvent<HTMLInputElement>, setter: Dispatch<SetStateAction<UserData>>)=>{
    const name = event.target.name
    const value = event.target.value
    
    setter((prev)=>({...prev, [name]: value}))
}

/**
 * update user phone number
 * @param event 
 * @param setter 
 * @param setErrorMsg 
 */
const phoneInputs = (event: ChangeEvent<HTMLInputElement>, setter: Dispatch<SetStateAction<UserData>>, setErrorMsg: Dispatch<SetStateAction<generalMessage | null>>, phoneIndex: number)=>{
    // validate phone numbers
    const name = event.target.name
    const value = event.target.value
    if(!phoneNumberTester.test(value)){
        // invalid phone number
        setErrorMsg({
            message: "Enter a valid phone number",
            type: MESSAGE_TYPE.ERROR
        })
    }else{
        setErrorMsg(null)
        if(name==='phoneNumber'){
            // set user phone number and cooperative account number
            setter((prev)=>{
                const phones = prev?.phoneNumbers??[value]
                phones[phoneIndex] = value
                return ({
                    ...prev, 
                    phoneNumbers: phones, 
                    accountNumber: prev?.phoneNumbers.length > 0 ?
                        prev?.phoneNumbers![0].slice(1): value.slice(1)
                })
            })
        }else{
            // set next of kin phone number
            setter((prev)=>({...prev, nextOfKin: {...prev?.nextOfKin, phoneNumber: value}}))
        }
    }
}

/**
 * set email for user and next of kins
 * @param event 
 * @param setter 
 * @param setErrorMsg 
 */
const updateEmails = (event: ChangeEvent<HTMLInputElement>, setter: Dispatch<SetStateAction<UserData>>, setErrorMsg: Dispatch<SetStateAction<generalMessage | null>>)=>{
    const name = event.target.name
    const value = event.target.value
    if(!emailTester.test(value)){
        // invalid email address
        setErrorMsg({
            message: "Enter a valid email address",
            type: MESSAGE_TYPE.ERROR
        })
    }else{
        // remove validation error
        setErrorMsg(null)
        // save to form data (user data or next of kin data)
        if(name==='email'){
            setter((prev)=>({...prev, email: value}))
        }else{
            setter((prev)=>({...prev, nextOfKin: {...prev?.nextOfKin, email: value}}))
        }
    }
}

/**
 * set phone numbers for users
 * @param event 
 * @param setter 
 * @param setErrorMsg 
 */
const updateDate = (event: ChangeEvent<HTMLInputElement>, setter: Dispatch<SetStateAction<UserData>>, setErrorMsg: Dispatch<SetStateAction<generalMessage | null>>)=>{
    const name = event.target.name
    const value = event.target.value

    if(name==='dateOfBirth'){
        // anyone younger than 18 years is not eligible to join
        const minimumYear = Date.now() - (1000*60*60*24*366*18)
        if(Date.parse(value) > minimumYear){
            setErrorMsg({
                message: "You must be 18 years and above to be a member!",
                type: MESSAGE_TYPE.ERROR
            })
        }else{
            setErrorMsg(null)
            setter((prev)=>({...prev, dateOfBirth: value}))
        }
    }else{
        // Date must not be after today
        if(Date.parse(value) > Date.now()){
            setErrorMsg({
                message: "Enter an authentic registration date",
                type: MESSAGE_TYPE.ERROR
            })
        }else{
            setErrorMsg(null)
            setter((prev)=>({...prev, KISCMSInfo: {...prev.KISCMSInfo, dateJoined: Date.parse(value)}}))
        }
    }
}

/**
 * Update user bank account number
 * @param event 
 * @param setter 
 * @param setErrorMsg 
 */
const updateAccountNumber = (event: ChangeEvent<HTMLInputElement>, setter: Dispatch<SetStateAction<UserData>>, setErrorMsg: Dispatch<SetStateAction<generalMessage | null>>, formData: UserData)=>{
    const name = event.target.name
    const value = event.target.value
    // get header code and key for items
    const hCode = name.slice(0, 3)
    const key = name.slice(3)

    if(hCode==='wok'){
        // employment information
        setter((prev)=>({...prev, employmentDetail: {...prev.employmentDetail, [key]: value}}))
    }else{
        if(value.length < 10){
            setErrorMsg({
                message: "Enter a valid bank account number",
                type: MESSAGE_TYPE.ERROR
            })
        }else{
            // if number does not exist, show error account number not existing with bank
            confirmAccountNumber({bankCode: formData.bankAccoundDetail.bankCode!, accountNumber: value}, (data)=>{
               if(typeof(data.success)==='undefined'){
                    setter((prev)=>({...prev, bankAccoundDetail: {
                        ...prev.bankAccoundDetail, 
                        accountNumber: data.accountNumber, 
                        accountName: data.accountName
                    }}))
               }else{
                setter((prev)=>({...prev, bankAccoundDetail: {
                    ...prev.bankAccoundDetail, 
                    accountNumber: '', 
                    accountName: undefined
                }}))
                toast("unable to verify account number", {type: 'error'})
               }
            }, ()=>{
                setter((prev)=>({...prev, bankAccoundDetail: {
                    ...prev.bankAccoundDetail, 
                    accountNumber: '', 
                    accountName: undefined
                }}))
                toast("unable to verify account number", {type: 'error'})
            })
            // else update account number
            setErrorMsg(null)
        }
    }
    
    
}

/**
 * check if a user can save
 * @param formData 
 * @param errorMessage 
 * @returns 
 */
export const checkCanSave = (formData: UserData, errorMessage: generalMessage | null): boolean=>{
    return errorMessage===null && !isEmpty(formData) && formData.photoUrl!==''
}

/**
 * test to see if data is empty
 * @param user 
 * @returns 
 */
const isEmpty = (user: UserData): boolean =>{
    const found =  Object.values(user).find((value)=>{
        if(typeof(value)==='object'){
           return Object.values(value).find(val=>val==='')
        }else{
            if(value===''){
                return value
            }
        }
    })
    return found === ''
}

export const fetchBankList = (callBack: (data: BankDetail[])=>void, errorHandler?: (error?: any)=>void)=>{
    const getter = httpsCallable(cloudFunctions, 'fetchBankLists');
    getter({}).then((data)=>{
        callBack(data.data as BankDetail[])
    }).catch(error=>errorHandler!(error))
}

export const confirmAccountNumber = (data: AccountVerificationRequest, callBack: (result: UserAccount)=>void, errorHandler: (error?: any)=>void)=>{
    const func = httpsCallable(cloudFunctions, 'confirmAccountNumber');
    func(data).then((info)=>{
        callBack(info.data as UserAccount)
    }).catch(errorHandler)
}

/**
 * logout current user
 * @param setCurrentUser 
 */
export const logout = (setCurrentUser: Dispatch<SetStateAction<UserData | null>>)=>{
    const model = new Users()
    model.signout(()=>{
        window.sessionStorage.clear()
        window.localStorage.clear()
        setCurrentUser(null)
        window.location.reload()
    }, (error)=>{
        console.log(error)
        toast('Check internet connetions!', {type: 'error'})
    })
}

/**
 * check if user must reset password
 * @param currentUser 
 * @param navigate 
 */
export const monitorPasswordResetter = (currentUser: UserData, navigate: NavigateFunction)=>{
    if(currentUser.needResetPassword){
        toast("You must reset password to continue", {type: 'info'})
        navigate('/profile')
    }
}

