import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


export const AnaysisCardItem = ({label, percentage, isIncrease, icon}:{label:string, percentage?: number, isIncrease?:boolean, icon?:Icon})=>{
    return (
        <div className="d-flex align-items-center">
        <div style={{
            backgroundColor: icon?.backgroundColor??'#e0f8e9',
            color: icon?.color??'#2eca6a'
        }} className="card-icon rounded-circle d-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={icon?.name!} />
        </div>
        <div className="ps-3">
          <h6 className="text-success">{label}</h6>
          {
            percentage && <>
              <span className={`${isIncrease?"text-success":"text-danger"} small pt-1 fw-bold`}>{`${percentage}%`}</span> 
              <span className="text-muted small pt-2 ps-1">{isIncrease?"increase":"decrease"}</span>
            </>
          }

        </div>
      </div>
    )
}

type Icon = {
    backgroundColor:string,
    color:string,
    name:IconProp
}