import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { moneyFormatter } from "../../utility/helpers"
import { faCreditCard } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import { BalanceModal } from "../cooperator/BalanceModal.component"
import { UserData } from "../../dataTypes/user.types"
import { DBMODELS } from "../../dataTypes/customTypes/varTypes"

export const CrediBalance = ({amount, title, isCalculated, user}:{amount:number, title: DBMODELS, isCalculated?: boolean, user: UserData})=>{

    const [showModal, setShowModal] = useState<boolean>(false)

    return (
        <div>
            <h1 className="text-success text-center">&#8358;{moneyFormatter(amount)}</h1>
            {
                !isCalculated &&
                <div onClick={()=>setShowModal(true)} className="moneyCard btn card" title={`make payment for ${title}`} >
                    <FontAwesomeIcon icon={faCreditCard} />
                </div>
            }
            {
                showModal &&
                <BalanceModal 
                    show={showModal} 
                    onHide={()=>setShowModal(false)}
                    user={user}
                    title={title}
                    isIncrement={true}
                />
            }
        </div>
    )
}