import { useNavigate } from "react-router-dom"
import { LoanConfig } from "../components/configurations/LoanConfiguration.component"
import { PenaltyAndFine } from "../components/configurations/OtherConfiguration.component"
import BreadCrumbComponent from "../components/main/body/pageTitle/BreadCrumb.component"
import { useContext, useEffect } from "react"
import { monitorPasswordResetter } from "./methods/registration"
import { userContext } from "../providers/user.provider"


const Configurations = ()=>{

    const navigate = useNavigate()
    const {currentUser} = useContext(userContext)

   useEffect(()=>{
    // monitor password resetter
    if(currentUser){
        monitorPasswordResetter(currentUser!, navigate)
    }
   }, [currentUser, navigate])
    
    return (
        <section>
            <BreadCrumbComponent 
                pageTitle="Configuration"
                currentCrumb="settings"
                parentPages={[]}
            />

            <LoanConfig />
            <PenaltyAndFine />
        </section>
    )
}

export default Configurations