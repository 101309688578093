import {  memo, ReactNode, useState,  useEffect, Dispatch, SetStateAction } from "react"
import { MonthsOfTheYear } from "../../constants/CALENDER.constant"
import { AnalyticItem } from "../../dataTypes/customTypes/varTypes"
import { whereClause, WHEREOPERATOR } from "../../dataTypes/firebasequery.types"
import { ADMIN_ROLE } from "../../constants/UTILITY.constants"
import { FinanceData } from "../../dataTypes/financials"
import { toast } from "react-toastify"

type filterType = {
  key: string,
  filter: string
}
const cardFilters: Array<filterType> = [
   {
    key: 'day',
    filter: 'Today'
   },
   {
    key: 'month',
    filter: 'This month'
   },
   {
    key: 'year',
    filter: 'This year'
   }
]

const AnalysisCard = ({item, component, onFilter, index}: {item: AnalyticItem, component: ReactNode, index:number, onFilter:Dispatch<SetStateAction<AnalyticItem[]>>})=>{

    const {isMoney, model, title} = item
    const [cardFilterBy, setCardFilterBy] = useState<filterType>(cardFilters[0])

    const searchByFilter = (item: filterType)=>{
        setCardFilterBy(item)
    }

    useEffect(()=>{
      
      const where:whereClause[] = []
      if(isMoney){
        const d = new Date()
        let filter: string | number = d.getDate()
        if(cardFilterBy.filter===cardFilters[1].filter){
          filter = d.getMonth()+1
        }else if(cardFilterBy.filter===cardFilters[2].filter){
          filter = d.getFullYear()
        }
        where.push({
          key: cardFilterBy.key,
          operator: WHEREOPERATOR.EQUAL_TO,
          value: filter
        },
        {
          key: 'purpose',
          operator: WHEREOPERATOR.EQUAL_TO,
          value: item.purpose
        }
      )
      }else{
        where.push(

        {
          key: 'isActive',
          operator: WHEREOPERATOR.EQUAL_TO,
          value: true
        },
        {
          key: 'adminRole',
          operator: WHEREOPERATOR.NOT_EQUAL_TO,
          value: ADMIN_ROLE.SUPER
        },
        )
      }

      // Count data
      if(isMoney){
        // console.log(where)
        model.streamWhere(where, (data)=>{
              if(data.length > 0){
                const finItems = data as FinanceData[]
                item.amount = finItems.reduce((prev, curr)=>({...curr, amount: (+curr.amount+prev.amount)})).amount
                onFilter((previousFilter)=>{
                  previousFilter[index] = item
                  return [...previousFilter]
                })
              }else{
                item.amount = 0
                onFilter((previousFilter)=>{
                  previousFilter[index] = item
                  return [...previousFilter]
                })
              }
        }, (_)=>{
          toast("Check connections!", {type: 'error'})
        })
       
      }else{
        model.countData(where, (count)=>{
          item.amount = count??0
          onFilter((previousFilter)=>{
            previousFilter[index] = item
            return [...previousFilter]
          })
        }) 
      }
    }, [cardFilterBy.filter, cardFilterBy.key, index, model, isMoney, item, onFilter])

return (
  <div className="col-xxl-3 col-md-6">
    <div className="card info-card sales-card">
      {(isMoney) && <div className="filter">
        <span className="icon" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></span>
        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
          <li className="dropdown-header text-start">
            <h6>Filter</h6>
          </li>
          {
            (isMoney ) && cardFilters.map((item, key)=>{
                return <li onClick={()=>{searchByFilter(item)}}  key={key}><span className="dropdown-item">{item.filter}</span></li>
            })
          }
        </ul>
      </div>}

      <div className="card-body">
        <h5 className="card-title">{title} {(isMoney) && <span>| {cardFilterBy.filter}</span>} </h5>
          {component}
      </div>

    </div>
    </div>
)
}

export default memo(AnalysisCard)