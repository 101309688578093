import { memo } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import {  ParentPage } from "../../../../dataTypes/customTypes/varTypes";
import { generalMessage } from "../../../../dataTypes/notificationMessages.types";




 const BreadCrumb = ({pageTitle, currentCrumb, parentPages, message}:
  {
    pageTitle:string, 
    currentCrumb:string, 
    parentPages:ParentPage[],
    message?: generalMessage | null
  }) => {

    return (
        <div className="pagetitle">
        <h1 className="text-success">{pageTitle}</h1>
        <nav>
          <ol className="breadcrumb">
            {
                parentPages.map((page, inx)=>{
                    return <li key={inx} className="breadcrumb-item text-success"><Link to={page.link} >{page.title}</Link></li>
                })
            }
            <li className="breadcrumb-item active">{currentCrumb}</li>
          </ol>
        </nav>

        <div className="text-center">
            {message && <Alert variant={message.type} >{message.message}</Alert>}
        </div>
      </div>
    );
}

export default memo(BreadCrumb)