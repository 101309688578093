import { FormGroup, FormLabel } from "react-bootstrap"
import { Loan, LoanConfiguration } from "../../dataTypes/financials"
import { setLoanApplicationFormData, updateSavings } from "./methods"
import { Dispatch, SetStateAction, useCallback, useEffect } from "react"
import { UserData } from "../../dataTypes/user.types"

export const LoanApplicationForm = ({
    formData,
    userLoans,
    setError,
    user,
    setFormData,
    loanCategories,
    // creditBalances,
    setCurrentSavings,
    currentSavings,
    loanConfig,
    setLoanConfig,
    loanBasis,
    setFullyGuranteed,
    initialData,
    maxLoan
}: {
    formData: Loan,
    userLoans: Loan[],
    setError: Dispatch<SetStateAction<string | null>>,
    user: UserData,
    setFormData: Dispatch<SetStateAction<Loan>>,
    loanCategories: LoanConfiguration[],
    // creditBalances: Balances,
    setCurrentSavings: Dispatch<SetStateAction<number>>,
    currentSavings: number,
    loanConfig: LoanConfiguration,
    setLoanConfig: Dispatch<SetStateAction<LoanConfiguration | null>>,
    loanBasis: number,
    setFullyGuranteed: Dispatch<SetStateAction<boolean>>
    initialData: Loan,
    maxLoan: number
}) =>{
    const checkFullyGuaranteed = useCallback(()=>{
        if(loanBasis > 0){
          setFullyGuranteed(loanBasis >= (formData.capital * (1+(formData.interestRate/100))))
        }
    }, [
        formData, 
        loanBasis, 
        setFullyGuranteed
    ])

    useEffect(()=>{
         // check loan guarantee level
        checkFullyGuaranteed()
    }, [checkFullyGuaranteed])

    
    return (
        <div>
          <FormGroup className="mb-2">
              <FormLabel>
                  Loan category:
              </FormLabel>
              <select title="Loan category" className="form-control" defaultValue={formData.category} onChange={(e)=>{
                    setLoanApplicationFormData(e, setFormData, userLoans, setError, initialData, setLoanConfig, maxLoan)
                  }}  name="category"> 
                  <option value="default">Select loan category</option>
                  {
                    loanCategories.map((locanCategory, indx)=>{
                      return <option key={indx} value={JSON.stringify(locanCategory)}>{locanCategory.title}</option>
                    })
                  }
              </select>
          </FormGroup>

          { (formData.category !=='' && (loanConfig && loanConfig.plans?.length > 0)) && 
            <FormGroup className="mb-2">
                  <FormLabel>
                      Loan amount:
                  </FormLabel>
                  <input title="Laon amount" type="number" min={100} className="form-control" onChange={(e)=>{
                    setLoanApplicationFormData(e, setFormData, userLoans, setError, initialData, setLoanConfig, maxLoan)
                      }} defaultValue={formData.capital} name="capital" />
              </FormGroup> 
            }

          {
            (formData.capital > 0 && loanConfig) &&
            <FormGroup className="mb-2">
              <FormLabel>
                  Loan duration:
              </FormLabel>
              <select title="Laon duration" className="form-control" defaultValue={formData.instalments} onChange={(e)=>{
                setLoanApplicationFormData(e, setFormData, userLoans, setError, initialData, setLoanConfig, maxLoan)
                  }}  name="instalments">
                  <option value="default">Select duration</option>
                  {
                    loanConfig.plans?.map((loanPlan, indx)=>{
                      return <option key={indx} value={JSON.stringify(loanPlan)}>{loanPlan.instalments}</option>
                    })
                  }
              </select>
          </FormGroup>
          }              

              <FormGroup className="mb-2">
                  <FormLabel>
                      Monthly savings:
                  </FormLabel>
                  <input title="Laon amount" type="number" min={100} className="form-control" onChange={(e)=>{
                          updateSavings(e, setError, setCurrentSavings, user)
                      }} defaultValue={currentSavings} name="savings" />
              </FormGroup> 
        </div>
    )
}