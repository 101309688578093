import { faBank, faCoins, faDashboard, faGear } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { ListItem } from "../../../dataTypes/customTypes/varTypes"
import SearchBarComponent from "../../utility/SearchBar.component"
import { SingleListItem } from "../../utility/SingleListItem.component"
import { Loans } from "../../../models/Loans.model"
import { WHEREOPERATOR } from "../../../dataTypes/firebasequery.types"
import { LOANSTATUS } from "../../../constants/UTILITY.constants"

export const SINGLELISTS: ListItem[] = [
    {
        label: 'Dashboard',
        path: '/',
        icon: faDashboard
    },
    {
        label: 'Finance',
        path: '/finance',
        icon: faCoins
    },
    {
        label: 'Loans',
        path: '/loan',
        icon: faBank
    },
    {
        label: 'Configurations',
        path: '/config',
        icon: faGear
    },

]

const Sidebar = ()=>{

    const [loanCount, setLoanCount] = useState<number>()

    useEffect(()=>{
        // monitor loan applications
        const loanModel = new Loans()
        loanModel.streamWhere([
            {
                key: 'status',
                operator: WHEREOPERATOR.IN,
                value: [LOANSTATUS.APPROVED, LOANSTATUS.GUARANTEED, LOANSTATUS.PARTIALLY_GUARANTEED, LOANSTATUS.UNGUARANTEED]
            }
        ], (data)=>{
            if(data.length > 0){
                setLoanCount(data.length)
            }else{
                setLoanCount(undefined)
            }
        }, (error)=>{
            console.log(error)
        })
    }, [])

    return (
        <aside id="sidebar" className="sidebar primary-background">

            <div className="sidebar-nav" id="sidebar-nav">
            <SearchBarComponent title="Enter search keyword" />
                
                {
                    SINGLELISTS.map((listItem, key)=><SingleListItem counter={listItem.label===SINGLELISTS[2].label?loanCount:undefined} item={listItem} key={key}  />)
                }

            </div>

        </aside>
    )
}

export default Sidebar