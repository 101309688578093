import { Fragment } from "react"
import { UserData, membershipStatusColor } from "../../dataTypes/user.types"
import { faScrewdriverWrench, faMarsAndVenus, faRing, faEarthAfrica, faMap, faMapLocationDot, faAt, faThermometer, faPhone, faCalendar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Badge } from "react-bootstrap"

export const BiodataDisplay = ({user}: {user:UserData})=>{
    return (
        <Fragment>
             <div className="d-flex justify-content-center">
                    <div className="circular-profile">
                        <img src={user.photoUrl===''?'/assets/images/logo512.png': user.photoUrl} alt="Profile" className="circular-profile-img"/>
                    </div>
                </div>
                <div className="mt-2 text-center">
                    <h5 className="text-grey">{`${user.firstName} ${user.middleName} ${user.lastName}`}</h5>
                    {   user.isAdmin &&
                        <small className="text-info"><FontAwesomeIcon icon={faScrewdriverWrench} className="me-2" /> {user.adminRole?.toLowerCase()}</small>}
                    
                    <hr />
                </div>
                <div>
                    
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <FontAwesomeIcon icon={faMarsAndVenus} className="me-2" /> {user.gender.toLocaleLowerCase()}
                        </div>
                        <div className="">
                            <FontAwesomeIcon icon={faCalendar} className="me-2" /> {user.dateOfBirth}
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faRing} className="me-2" /> {user.maritalStatus.toLocaleLowerCase()}
                        </div>
                    </div> 
                    
                    <div className="d-flex justify-content-between mt-3">
                        <div className="">
                            <FontAwesomeIcon icon={faEarthAfrica} className="me-2" /> {user.nationality}
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faMap} className="me-2" /> {user.stateOfOrigin}
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faMapLocationDot} className="me-2" /> {user.lgaOfOrigin}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mt-3">
                        
                        <div>
                            <FontAwesomeIcon icon={faPhone} className="me-2" /> {user.phoneNumbers.map((phone)=>phone+ ', ')}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mt-3">
                        <div className="">
                            <FontAwesomeIcon icon={faAt} className="me-2" /> {user.email}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mt-3">
                        <Badge className={membershipStatusColor(user.status)} >
                            <FontAwesomeIcon icon={faThermometer} className={`me-2`} /> {user.status.toLowerCase()}
                        </Badge>
                    </div> 

                </div>
        </Fragment>
    )
}