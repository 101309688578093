import { UserData } from "../dataTypes/user.types";

export enum ADMIN_ROLE {
    SUPER = "SUPER",
    PRESIDENT = "PRESIDENT",
    STAFF = "STAFF",
    TREASURER = "TREASURER",
    GENERAL_SECRETARY = "GENERAL SECRETARY",
    OFFICER = "OFFICER",
    FINANCIAL_SECRETARY = "FINANCIAL SECRETARY",
    NOT_ADMIN = 'NOT ADMIN'
}

export enum MARITAL_STATUS {
    SINGLE = "SINGLE",
    MARRIED = "MARRIED"
}

export enum GENDER {
    MALE = "MALE",
    FEMALE = "FEMALE"
}

export enum NEXTOFKIN_RELATIONSHIP {
    PARENT = "PARENT",
    SIBLING = "SIBLING",
    UNCLE = "UNCLE",
    AUNT = "AUNT",
    NEPHEW = "NEPHEW",
    COUSIN = "COUSIN",
    SPOUSE = "SPOUSE",
    NIECE = "NIECE",
    GUARDIAN = "GUARDIAN"
}

export enum MEMBERSHIP_STATUS {
    ACTIVE = "ACTIVE",
    PENDING_WITHDRAWAL = "PENDING WITHDRAWAL",
    WITHDRAWN = "WITHDRAWN",
    FROZEN = "FROZEN"
}

export enum INFLOW_PURPOSE {
    REGISTRATION = "REGISTRATION",
    RAFFLE = "RAFFLE",
    LOAN_REPAYMENT = "LOAN REPAYMENT",
    WITHDRAWAL_SETTLEMENT = "WITHDRAWAL SETTLEMENT",
    SAVINGS = "SAVINGS",
    SHARES = "SHARES",
    LOAN_APPLICATION = "LOAN APPLICATION",
    DIVIDEND = "DIVIDEND",
    PENALTY = "PENALTY",
    WITHDRAWAL_CHARGE = "WITHDRAWAL CHARGE",
    LOAN_INSURANCE = "LOAN INSURANCE",
    OTHER_INCOME = "OTHER INCOME",
}

export enum OUTFLOW_PURPOSE {
    WITHDRAWAL_SETTLEMENT = "WITHDRAWAL SETTLEMENT",
    OFFICE_EXPENSES = "OFFICE EXPENSES",
    LOAN_PAYOUT = "LOAN PAYOUT",
    SALARY = "SALARY",
    GUARANTOR_BONUS = "GUARANTOR BONUS",
    STOCK = "STOCK",
    OTHER_EXPENSES = "OTHER EXPENSES",
    SELLING_EXPENSES = "SELLING EXPENSES",
    ADMIN_EXPENSES = "ADMINISTRATIVE EXPENSES"
}

export enum VOUCHER_PURPOSE {
    SALARY = "SALARY",
    STOCK = "STOCK",
    OTHER_EXPENSES = "OTHER EXPENSES",
    OFFICE_EXPENSES = "OFFICE EXPENSES",
    SELLING_EXPENSES = "SELLING EXPENSES",
    ADMIN_EXPENSES = "ADMINISTRATIVE EXPENSES"
}

export enum VOUCHER_STATUS {
    RAISED = 'RAISED',
    APPROVED = 'APPROVED',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED'
}

export enum MEMBER_BALANCES {
    DIVIDEND,
    SHARES,
    SAVINGS,
    LOANS,
    CREDITORS,
    DEBTORS
}

export enum LOANSTATUS {
    UNGUARANTEED = 'unguaranteed',
    GUARANTEED = 'guaranteed',
    PARTIALLY_GUARANTEED = 'partially guaranteed',
    CANCELLED = 'cancelled',
    ACTIVE = 'active',
    BAD = 'bad',
    DOUBTFUL = 'doubtful',
    APPROVED = 'approved',
    COMPLETED = 'completed',
    NON_PERFORMING = 'non performing'
}

export const intialFormData: UserData = {
    firstName: '',
    lastName: '',
    middleName: '',
    phoneNumbers: [],
    email: '',
    isActive: true,
    isAdmin: false,
    photoUrl: '',
    maritalStatus: MARITAL_STATUS.SINGLE,
    gender: GENDER.MALE,
    nationality: 'Nigerian',
    dateOfBirth: '',
    status: MEMBERSHIP_STATUS.ACTIVE,
    accountNumber: '',
    stateOfOrigin: '',
    lgaOfOrigin: '',
    contactAddress: {
        street: '',
        nearestBusStop: '',
        state: '',
        localGovernmentArea: ''
    },
    nextOfKin: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        relationship: '' as NEXTOFKIN_RELATIONSHIP
    },
    employmentDetail: {
        department: '',
        businessUnit: '',
        currentSalary: 0
    },
    bankAccoundDetail: {
        bankName: '',
        accountNumber: '',        
    },
    KISCMSInfo: {
        dateJoined: Date.now(),
        monthlySavings: 0,
        KISCMSNumber: ''
    },
    adminRole: ADMIN_ROLE.NOT_ADMIN,
    createdBy: '',
    needResetPassword: true
}