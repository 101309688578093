import { useContext, useEffect, useState } from "react"
import { Button, FormGroup, FormLabel, Modal } from "react-bootstrap"
import { FinanceData, Penalty, REPAYMENT_SOURCE, UserPenalty } from "../../dataTypes/financials"
// import { Penalties } from "../../models/OtherConfig.model"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGavel } from "@fortawesome/free-solid-svg-icons"
import { UserPenalties } from "../../models/UserPenalties.model"
import { Savings } from "../../models/Savings.model"
import { Receipts } from "../../models/Receipts.model"
import { INFLOW_PURPOSE } from "../../constants/UTILITY.constants"
import { toast } from "react-toastify"
import { userContext } from "../../providers/user.provider"
import { OtherConfig } from "../../models/OtherConfig.model"

export const PenaltyModal = ({show, onHide, uid, offender}:{show: boolean, onHide: ()=>void, uid: string, offender: string})=>{
    const {currentUser} = useContext(userContext)
    const d = new Date()
    const [penaltyList, setPenaltyList] = useState<Penalty[]>([])
    const [error, setError] = useState<string | null>(null)
    const [formData, setFormData] = useState<UserPenalty>({
        date: d.getTime(),
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        offense: {
            title: '',
            code: '',
            cost: 0
        }
    })

    useEffect(()=>{
        // fetch all penalties
        const model = new OtherConfig()
        model.findAll((data)=>{
            setPenaltyList(data as Penalty[])
        }, (error)=>{
            console.log('could not get penalties: ', error)
        })
    }, [])
    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
            >
            <Modal.Header closeButton>
                Member Fine & Penalty
            </Modal.Header>
            <Modal.Body>
                {error && <p className="text-center text-danger">{error}</p>}
                <FormGroup className="mb-4">
                    <FormLabel>
                        State of origin:
                    </FormLabel>
                    <select className="form-control"  name="penalty" title="Member fine & penalty" defaultValue={formData.offense.title} onChange={(e)=>{
                        const val = e.target.value
                        if(val==='default'){
                            setError("Select a valid penalty!")
                        }else{
                            setError(null)
                            const pnt: Penalty = JSON.parse(val)
                            setFormData(prev=>({...prev, offense: pnt}))
                        }
                    }} >
                        <option value="default">Select Penalty</option>
                    {
                        penaltyList.map((penalty, index)=>{
                            return <option key={index} value={JSON.stringify(penalty)}>{penalty.title}</option>
                        })
                    }
                    </select>
                    
                </FormGroup>

                <Button onClick={()=>{
                    // save to fines and penalties account
                    const fineModel = new UserPenalties()
                    fineModel.save(formData, {callBack(id) {
                        // reduce from user's savings account
                        const savingsModel = new Savings()
                        savingsModel.incrementDecrement({
                            dbReference: uid,
                            key: 'amount',
                            isIncrement: false,
                            incrementalValue: formData.offense.cost
                        }, (error)=>{
                            console.log('unable to deduct from savings: ', error)
                        }, ()=>{})
                        // save to Receipts account
                        const receiptModel = new Receipts()
                        const recData: FinanceData = {
                            date: d.getTime(),
                            day: d.getDate(),
                            month: d.getMonth() + 1,
                            year: d.getFullYear(),
                            description: `Penalty for ${formData.offense.title}`,
                            memberReference: uid,
                            amount: formData.offense.cost,
                            source: REPAYMENT_SOURCE.SAVINGS,
                            purpose: INFLOW_PURPOSE.PENALTY,
                            transactionRef: `REF_${d.getTime()}_${id}_PENALTY`,
                            processor: `${currentUser?.firstName} ${currentUser?.middleName} ${currentUser?.lastName}`,
                            processorRef: currentUser?.reference!,
                            memberName: offender
                        }
                        receiptModel.save(recData, {callBack() {
                            toast(`${formData.offense.cost} deducted from member's savings for ${formData.offense.title}`, {type: 'success'})
                        }, errorHander(error) {
                            console.log('unable to add to receipts: ', error)
                        },})
                    }, errorHander(error) {
                        console.log('unable to penalize user: ', error)
                    }, })
                }} disabled={formData.offense.cost===0} className="btn-sm" variant="danger">
                    <FontAwesomeIcon icon={faGavel} className="me-2" /> Penalize
                </Button>
            </Modal.Body>
            
            </Modal>
    )
}