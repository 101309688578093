import { faUser, IconDefinition } from "@fortawesome/free-regular-svg-icons"
import { faBank, faBirthdayCake, faCartShopping } from "@fortawesome/free-solid-svg-icons"

export type NotificationData = {
    message: string,
    date: string,
    readBy: string[],
    subject: string,
    icon: {
        image: NotificationIcons,
        background: NotificationIconBackground,
    }
}

export enum NotificationIconBackground {
    PRIMARY = 'green',
    INFO = '#6060ea',
    DANGER = 'red',
    WARNING = 'orange'
}

export enum NotificationIcons {
    REGISTRATION = 'faUser',
    ORDER = 'faCartShopping',
    SUBSCRIPTION = 'faBank',
    BIRTHDAY = 'faBirthdayCake'
}


export type MessageData = {
    sender: {
        fullName: string,
        ref: string,
    },
    date: string,
    subject: string,
    reciever: string,
    readBy: string[]
    content: string,
    forAllAdmin: boolean
}

export const getNotificationIcon = (iconType: NotificationIcons):IconDefinition  | null => {
    let icon: IconDefinition | null = null

    switch (iconType) {
        case NotificationIcons.BIRTHDAY:
            icon = faBirthdayCake
            break;
        case NotificationIcons.ORDER:
            icon = faCartShopping
            break;
        case NotificationIcons.REGISTRATION:
            icon = faUser
            break;
        case NotificationIcons.SUBSCRIPTION:
            icon = faBank
            break;
    }
    return icon
}

export type generalMessage = {
    message: string,
    type: MESSAGE_TYPE,
}

export enum MESSAGE_TYPE {
    SUCCESS = 'success',
    ERROR = 'danger'
}