import { useState, ChangeEvent, useEffect, Dispatch, SetStateAction, ReactNode, useCallback, LegacyRef, useRef } from "react"
import { emailValidator } from "../utility/validation"
import logo from '../assets/images/logo.png'
import { Users } from "../models/Users"
import CustomSpinner from "../components/utility/CustomSpinner.component"
import { adminDomainTester, superDomainTester } from "../utility/regex"
import { RouterProvider } from "react-router-dom"
import { adminRouter } from "../navigation/AdminRoute"
import { ConfirmationResult, MultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, User, multiFactor, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../configurations/firebase.configuration"
import { Button } from "react-bootstrap"
import { OTP } from "../components/utility/OTP.component"
import { UserData } from "../dataTypes/user.types"

const Login =({setCurrentPage}:{setCurrentPage?: Dispatch<SetStateAction<ReactNode>>})=>{
    
    const initialFormData = {
        email: '',
        password: '',
    }

    const [formData, setFormData] = useState(initialFormData)
    const [validationMessage, setValidationMessage] = useState<string | null>(null)
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [pageIsLoading, setPageIsLoading] = useState<boolean>(false)
    const [recaptchaverifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>()
    const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(null)
    const recapRef: LegacyRef<HTMLDivElement> = useRef(null)
    const [elementId, setElementId] = useState<number| null>(null)
    const [otpVerifier, setOTPVerifier] = useState<{
        verificationId: string,
        resolver?: MultiFactorResolver
    } | null>(null)
    const [fbUser, setUser] = useState<{user: User, userData: UserData}>()

    const updateFormData = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.type;
        const isValid = input==='email'?emailValidator(event.target.value):true

        if(isValid){
            setFormData((prev)=>{
                return {...prev, [input]: event.target.value}
                
            })
            setValidationMessage(null)
        }else{
            // store error
            setValidationMessage("Enter a valid email address")
        }
       
        
    }

    const submitForm =() => {
        setPageIsLoading(true)
        if(adminDomainTester.test(formData.email) || superDomainTester.test(formData.email)){
            const model = new Users()
            model.login({
                email: formData.email,
                password: formData.password,
                onSuccess: (data)=>{
                    setUser(data)
                },
                onFail: (error)=>{
                    setPageIsLoading(false)
                    setValidationMessage(error)
                },
                recaptcha: recaptchaverifier!,
                setter: setOTPVerifier
            })
        }else{
            setPageIsLoading(false)
            setValidationMessage('Email/password combinations do not match')
        }
        
    }

    const setRecaptcha = useCallback(()=>{
        // set recaptcher
        if(recapRef.current){
            try {
                const verifier = new RecaptchaVerifier(recapRef.current.id, {
                    'size': 'normal',
                    'callback': async (response: string) => {
                        setRecaptchaResponse(response)
                    },
                    'expired-callback': () => {
                        setRecaptchaResponse(null)
                    }
                }, auth)

                  if(!recaptchaverifier){
                    setRecaptchaVerifier(verifier)
                  }
            } catch (error) {
                console.log('recaptcha error: ', error)
            }

        }           
    }, [recaptchaverifier])

    useEffect(()=>{
        // recaptcha
        if(recaptchaverifier && elementId===null){
            recaptchaverifier.render().then(value=>setElementId(value))
        } 

        if(recaptchaverifier===undefined){
            setRecaptcha()
        }
         // check button disability
       if((validationMessage===null && formData.password!=='') || !pageIsLoading){
            setBtnDisabled(false)
       }else{
            setBtnDisabled(true)
       }
    }, [validationMessage, formData.password, recaptchaverifier, setRecaptcha, elementId]);

    return (
        <main className="login-bg-container">
            <div className="login-bg">
                <section className="login-bg-content section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <div className=" d-flex align-items-center w-auto">
                                            <img src={logo} alt="login logo" className="login-logo" />
                                    </div>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">
                                     
                                        <div className="pb-2">
                                        {validationMessage && <div className="text-danger text-center mt-2">{validationMessage}</div>}
                                            <h5 className="card-title text-center pb-0 fs-4">Admin</h5>
                                            <p className="text-center text-success small">{`Enter your email & password to login`}</p>
                                        </div>

                                        <div className="row g-3" >

                                            <div className="col-12">
                                                <label htmlFor="email" className="form-label text-success">Email address</label>
                                                <div className="input-group">
                                                    <span className="input-group-text bg-success text-white" >@</span>
                                                    <input placeholder="enter your email address" onChange={updateFormData} type="email" name="email" className="form-control" required />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <label htmlFor="password" className="form-label">Password</label>
                                                <input placeholder="enter your password" onChange={updateFormData} type="password" name="password" className="form-control"  required />
                                            </div>
                                            
                                            <div className="col-12">
                                                <Button className="btn btn-success w-100" disabled={btnDisabled || recaptchaResponse===null } onClick={submitForm}>Login</Button>
                                            </div>
                                            {pageIsLoading && <CustomSpinner />}
                                        </div>

                                        <div className={`d-flex mt-2 justify-content-center ${otpVerifier===null?"":"d-none"}`}>
                                            <div ref={recapRef} id='recaptcha-container'></div>
                                        </div>

                                    </div>
                                </div>

                                {
                                    (otpVerifier) &&
                                    <OTP 
                                        show={otpVerifier!==null}
                                        onHide={()=>{
                                            setOTPVerifier(null)
                                            setRecaptchaResponse(null)
                                        }}
                                        verifier={otpVerifier}
                                        user={fbUser}
                                        setPageIsLoading={setPageIsLoading}
                                        setCurrentPage={setCurrentPage!}
                                        setValidationMessage={setValidationMessage}
                                    />
                                }

                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </main>
    )
}

export default Login