import { createBrowserRouter } from "react-router-dom"
import Configurations from "../screens/Configurations.screen"
import Cooperator from "../screens/Cooperator.screen"
import { Dashboard } from "../screens/Dashboard.screen"
import Finance from "../screens/Finance.screen"
// import PaymentSchedule from "../screens/PaymentSchedule.screen"
import Profile from "../screens/Profile.screen"
import RegistrationForm from "../screens/RegistrationForm.screen"
import UnapprovedLoans from "../screens/PendingLoans.screen"
import Admin from "../wrapper/Admin.wrapper"
import ErrorRoute from "./ErrorRoute"
// import { SandBox } from "../screens/Sandbox.screen"


export const adminRouter = createBrowserRouter([
    {
      path: "/",
      element: <Admin />,
      errorElement: <ErrorRoute />,
      children: [
        {
            path: '',
            element: <Dashboard />
        },
        {
            path: 'cooperator',
            element: <Cooperator />
        },
        {
          path: 'finance',
          element: <Finance />
        },
        {
          path: 'config',
          element: <Configurations />
        },
        // {
        //   path: 'loan/:id',
        //   element: <PaymentSchedule />
        // },
        {
          path: 'profile',
          element: <Profile />
        },
        {
          path: 'registration',
          element: <RegistrationForm />
        },
        {
          path: 'loan',
          element: <UnapprovedLoans />
        },
        // {
        //   path: 'sandbox',
        //   element: <SandBox />
        // },
      ]
    },
  ]);