import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LoanConfiguration } from "../../dataTypes/financials"
import { faClock } from "@fortawesome/free-solid-svg-icons"

export const LoanConfigCard = ({config}: {config: LoanConfiguration})=>{
    return (
        <div className="col-8 bg-grey d-flex align-items-between flex-column config-holder configuration-card-right">
            <div className="p-2">
                <div className="configuration-heading">{config.title}</div>
                <div className="config-description">
                    <small className="text-success">{config.description}</small>
                </div>
            </div>
            <hr />
            <div className=" d-flex justify-content-between config-duration">
                {
                config.plans.map((plan, index)=>{
                    return (
                        <div key={index} className="text-dark p-2">
                            <FontAwesomeIcon icon={faClock} className="me-1" /> {plan.instalments}
                        </div>
                    )
                })
                } 
            </div>
        </div>
    )
}